import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../ui/core'
import * as S from '../../../ui'
import { IHowItWorksStep } from '../../../types'
import { StyledMarkdownParser } from '../../../components/StyledParsedMarkdown'

const Container = styled.div`
  margin-bottom: 100px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 120px;
  }
`

const StyledH1 = styled(S.H1)`
  margin-bottom: 16px;
`

const StyledH3 = styled(S.H3)`
  margin-bottom: 16px;
`

const StyledH5 = styled(S.H5)`
  margin-bottom: 16px;
`

const RowContainer = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${colors.black};

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const RowHeading = styled.div`
  margin-bottom: 8px;

  @media screen and (min-width: 1200px) {
    flex: 1;
  }
`

const StyledParagraph = styled(S.Paragraph)`
  margin-bottom: 8px;

  @media screen and (min-width: 1200px) {
    flex: 2;
  }
`

const renderContentRow = (step: IHowItWorksStep, idx: number) => {
  return (
    <RowContainer key={idx}>
      <RowHeading>
        <S.H6>0{idx + 1}</S.H6>
        <S.H6>{step.heading}</S.H6>
      </RowHeading>
      <StyledParagraph>{step.text}</StyledParagraph>
    </RowContainer>
  )
}

interface IProps {
  intro: string
  heading: string
  text: string
  steps: IHowItWorksStep[]
}

export const Advantages: React.FC<IProps> = (props) => {
  return (
    <Container>
      <S.LargeSpacer />
      <StyledH5>{props.intro}</StyledH5>
      <StyledH3>{props.heading}</StyledH3>
      <StyledMarkdownParser text={props.text} />
      <S.LargeSpacer />
      {props.steps.map((step, idx) => {
        return renderContentRow(step, idx)
      })}
    </Container>
  )
}
