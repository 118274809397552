import dayjs from 'dayjs'

export const dateWithMonthName = (input: string, short?: boolean) => {
  const date = new Date(input)
  const monthNames = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ]
  const shortMonthNames = [
    'Jan',
    'Feb',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dec',
  ]

  let day = date.getDate()
  let monthIndex = date.getMonth()
  let monthName = short ? shortMonthNames[monthIndex] : monthNames[monthIndex]
  let year = date.getFullYear()
  return `${day} ${monthName} ${year}`
}

export const formatTime = (input: string) => {
  return dayjs(input).format('HH:mm')
}
