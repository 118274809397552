import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import { logIn } from '../../auth/requests'
import { FieldValues, useForm } from 'react-hook-form'
import { FormInput } from '../FormInput'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '../../store/userReducer/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { ResetPassword } from './ResetPassword'
import { AuthRoutes, NonAuthRoutes } from '../../types'
import { emailPattern } from '../../utils/regex'
import { FormErrorMessage } from '../FormErrorMessage'
import { useIsVerified } from '../../utils/useIsVerified'

interface IProps {
  closeModal: () => void
  path?: string
}

interface ILoginInfo {
  email: string
  password: string
  resetEmail?: string
}

export const SignInContent: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<ILoginInfo>()
  const [forgot, setForgot] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState<string>()

  const CheckIfVerified = async (id: number) => {
    const verified = await useIsVerified(id)
    return verified
  }

  const login = (data: FieldValues) => {
    logIn(data.email, data.password)
      .then(async (res) => {
        const verified = await CheckIfVerified(res.data.user.id)
        res.data.user.verified = verified
        dispatch(loginAction({ ...res.data.user }))
        localStorage.setItem('token', res.data.jwt)
        const userType = res.data.user.type
        const isCompleted = res.data.user.name
        const accountPath =
          userType === 'company' && isCompleted
            ? AuthRoutes.account
            : userType === 'customer' && isCompleted
            ? AuthRoutes.customerAccount
            : NonAuthRoutes.signUp

        const locationPath =
          userType === 'company' && isCompleted
            ? AuthRoutes.locations
            : userType === 'customer' && isCompleted
            ? AuthRoutes.customerLocations
            : NonAuthRoutes.signUp

        props.closeModal()
        props.path === 'locations'
          ? history.push(locationPath)
          : history.push(accountPath)
      })
      .catch((err) => {
        setError(err.response.data.error.message)
      })
  }

  return (
    <S.FlexContainerColumnJustifyCenter>
      {!forgot ? (
        <form onSubmit={handleSubmit(login)}>
          <S.H3>Sign in</S.H3>
          <S.ExtraSmallSpacer />
          <S.ParagraphSmall>
            You need to sign in to access the platform
          </S.ParagraphSmall>
          <S.SmallSpacer />
          <FormInput label="Email*">
            <S.InputField
              {...register('email', {
                required: { value: true, message: 'Enter email' },
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email',
                },
              })}
              type="email"
              placeholder="EMAIL"
            />
          </FormInput>
          {errors.email?.message && (
            <FormErrorMessage message={errors.email?.message} />
          )}
          <S.ExtraSmallSpacer />
          <FormInput label="Password*">
            <S.InputField
              {...register('password', {
                required: { value: true, message: 'Enter password' },
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters',
                },
              })}
              type="password"
              placeholder="PASSWORD"
            />
          </FormInput>
          {errors.password?.message && (
            <FormErrorMessage message={errors.password?.message} />
          )}
          <S.FlexContainerRowJustifyEnd>
            <S.TextButton
              margin="12"
              type="button"
              onClick={() => setForgot(true)}
              color={colors.gray}
            >
              Forgot password
            </S.TextButton>
          </S.FlexContainerRowJustifyEnd>
          <S.SmallSpacer />
          <S.ModalButtonPrimary type="submit">Sign in</S.ModalButtonPrimary>
          {error && (
            <>
              <S.ExtraSmallSpacer />
              <FormErrorMessage message={error} />
            </>
          )}
          <S.MediumSpacer />
          {location.pathname !== '/sign-up' && (
            <>
              <S.FlexContainerRowCenterCenter>
                <S.ParagraphSmall>Don't have an acount?</S.ParagraphSmall>
                <S.ExtraSmallSpacerRow />
                <S.TextButton
                  onClick={() => {
                    props.closeModal()
                    history.push(NonAuthRoutes.signUp)
                  }}
                >
                  Sign up
                </S.TextButton>
              </S.FlexContainerRowCenterCenter>
            </>
          )}
        </form>
      ) : (
        <ResetPassword sent={() => setForgot(false)} />
      )}
    </S.FlexContainerColumnJustifyCenter>
  )
}
