import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { AuthRoutes, ILocationPage } from '../../../types'
import { Icon, IconType } from '../../../ui/assets/icons'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { BASE_URL } from '../../../utils/config'
import { toast } from 'react-toastify'

const ImageContainer = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 16px;
    padding-bottom: 20px;
    gap: 32px;
    overflow-x: unset;
    white-space: unset;
    flex-wrap: wrap;
  }
`

const Card = styled(S.FlexContainerColumn)`
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    width: 304px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    word-wrap: break-word;
    align-items: flex-start;
    width: calc(25% - 24px);
    &.large {
      :nth-of-type(3) {
        width: calc(50% - 16px);
      }
    }
    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

const Image = styled.img`
  @media screen and (max-width: 1199px) {
    height: 40vw;
  }
  height: 456px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Text = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    white-space: normal;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: rgba(61, 37, 35, 0.9);
    padding: 12px 32px;
    h5,
    p {
      color: ${colors.white};
      text-align: center;
    }
    h5 {
      color: ${colors.white};
      text-align: center;
      padding: 0 12px;
    }
    .details {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const MobileText = styled.div`
  display: block;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const HeaderContainer = styled.div`
  width: min-content;
  margin: auto;
`

const Underline = styled.div`
  margin-top: 8px;
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
`

export const PreviewLocations: React.FC = () => {
  const history = useHistory()
  const [locations, setLocations] = useState<ILocationPage[]>([])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify(
      {
        pagination: {
          pageSize: 7,
        },
        filters: {
          reviewed: true,
        },
        populate: {
          images: {
            populate: '*',
          },
          locationTags: {
            populate: 1,
          },
          facilities: {
            populate: 1,
          },
        },
        sort: 'publishedAt:desc',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(`${BASE_URL}/api/locations?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setLocations(res.data.data)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  return (
    <>
      <ScrollToTopOnMount />
      <ImageContainer className="imageContainer">
        {locations.map((l) => {
          return (
            <Card
              key={l.id}
              className={'card large'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => history.push(`${AuthRoutes.locations}/${l.id}`)}
            >
              {l.attributes.exclusive && (
                <S.ExclusiveButton
                  margin="8px"
                  width="max-content"
                  position="absolute"
                >
                  <Icon icon={IconType.Tag} />
                  <S.ExtraSmallSpacerRow />
                  exklusivt objekt
                </S.ExclusiveButton>
              )}
              {l.attributes.images && (
                <Image
                  src={
                    l.attributes.images.data &&
                    l.attributes.images.data[0].attributes.url
                  }
                  alt={l.attributes.images?.data[0].attributes.alternativeText}
                />
              )}
              <Text className="text">
                <S.ExtraSmallSpacer />
                <HeaderContainer>
                  <S.H5>{l.attributes.county}</S.H5>
                  <Underline />
                </HeaderContainer>
                <S.MediumSpacer />
                <S.Paragraph>{l.attributes.title}</S.Paragraph>
                <S.LargeSpacer />

                <S.FlexContainerRowCenterCenter>
                  <S.DetailsSmall>
                    {l.attributes.locationTags?.data[0] &&
                      l.attributes.locationTags.data[0].attributes.tag}
                  </S.DetailsSmall>
                  <S.SmallSpacerRow />
                  <S.DetailsSmall>
                    {l.attributes.locationTags?.data[1] &&
                      l.attributes.locationTags.data[1].attributes.tag}
                  </S.DetailsSmall>
                </S.FlexContainerRowCenterCenter>
              </Text>
              <MobileText>
                <S.SmallSpacer />
                <S.FlexContainerRowAlignCeter>
                  <S.H5>{l.attributes.county}</S.H5>
                </S.FlexContainerRowAlignCeter>
                <S.ExtraSmallSpacer />
                <S.Paragraph>{l.attributes.title}</S.Paragraph>
                <S.ExtraSmallSpacer />
                <S.FlexContainerRowAlignCeter>
                  <S.DetailsSmall>
                    {l.attributes.locationTags?.data[0] &&
                      l.attributes.locationTags.data[0].attributes.tag}
                  </S.DetailsSmall>
                  <S.SmallSpacerRow />
                  <S.DetailsSmall>
                    {l.attributes.locationTags?.data[1] &&
                      l.attributes.locationTags.data[1].attributes.tag}
                  </S.DetailsSmall>
                </S.FlexContainerRowAlignCeter>
                <S.MediumSpacer />
              </MobileText>
            </Card>
          )
        })}
      </ImageContainer>
    </>
  )
}
