import React, { useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Images } from '../ui/assets/images'
import { Icon, IconType } from '../ui/assets/icons'
import { SubscribeCTA } from './SubscribeCTA'
import { ContactModal } from './modals/ContactModal'
import { NonAuthRoutes } from '../types'

const FooterContainer = styled.footer`
  background-color: ${colors.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
`

const FooterContent = styled(S.FlexContainerColumnCenterCenter)`
  max-width: 1440px;
  width: 100%;
  padding: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 64px;
  }
`

const LogoContainer = styled(S.FlexContainerRowJustifyCenter)`
  width: 100%;
  padding-top: 52px;

  img {
    height: 79px;
  }

  @media screen and (min-width: 768px) {
    width: 45%;
    height: 30vh;
    justify-content: end;
    align-items: flex-end;

    img {
      height: 177px;
    }
  }
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 55%;
    flex-direction: column-reverse;
  }
`

const InfoSection = styled.div`
  padding: 16px;
  width: 100%;

  &.desktop {
    display: none;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 52px;
    &.desktop {
      display: unset;
    }
    &.mobile {
      display: none;
    }
  }
`

const Socials = styled.div`
  display: flex;
  align-items: center;

  svg {
    padding: 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
`

export const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const renderSocialMediaLinks = () => {
    return (
      <>
        <S.H5 color={colors.white}>SOCIALS</S.H5>
        <S.SmallSpacer />
        <Socials>
          <a
            target="_blank"
            href="https://www.instagram.com/bonanzalocationab/"
          >
            <Icon color={colors.white} icon={IconType.Instagram} />
          </a>
          <S.SmallSpacerRow />
          <a target="_blank" href="https://www.facebook.com/bonanzalocation/">
            <Icon color={colors.white} icon={IconType.Facebook} />
          </a>
          <S.SmallSpacerRow />
          <a
            target="_blank"
            href="https://www.linkedin.com/company/bonanza-location/"
          >
            <Icon color={colors.white} icon={IconType.Linkedin} />
          </a>
          <S.SmallSpacerRow />
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCnarmcu2VqFYYE7qP1QKJMw"
          >
            <Icon color={colors.white} icon={IconType.Youtube} />
          </a>
        </Socials>
      </>
    )
  }

  return (
    <>
      <FooterContainer id="footer">
        <FooterContent>
          <LogoContainer>
            <img src={Images.LogoWhite} alt="Bonanza logo" />
          </LogoContainer>
          <InfoContainer>
            <InfoSection className="mobile">
              {renderSocialMediaLinks()}
            </InfoSection>
            <InfoSection>
              <SubscribeCTA />
            </InfoSection>
            <S.FlexContainerRow>
              <InfoSection>
                <S.H5 color={colors.white}>COMPANY</S.H5>
                <S.MediumSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.terms}
                  color={colors.white}
                >
                  TERMS OF USE
                </S.DetailsSmallLink>
                <S.SmallSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.privacy}
                  color={colors.white}
                >
                  PRIVACY
                </S.DetailsSmallLink>
                <S.SmallSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.faq}
                  color={colors.white}
                >
                  FAQ
                </S.DetailsSmallLink>
                <S.SmallSpacer />
                <S.DetailsSmallLink
                  onClick={() => {
                    setShowModal(true)
                  }}
                  color={colors.white}
                >
                  CONTACT
                </S.DetailsSmallLink>
              </InfoSection>
              <InfoSection>
                <S.H5 color={colors.white}>DISCOVER</S.H5>
                <S.MediumSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.about}
                  color={colors.white}
                >
                  ABOUT US
                </S.DetailsSmallLink>
                <S.SmallSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.updates}
                  color={colors.white}
                >
                  UPDATES
                </S.DetailsSmallLink>
                <S.SmallSpacer />
                <S.DetailsSmallLink
                  href={NonAuthRoutes.howItWorks}
                  color={colors.white}
                >
                  HOW IT WORKS
                </S.DetailsSmallLink>
              </InfoSection>
              <InfoSection className="desktop">
                {renderSocialMediaLinks()}
              </InfoSection>
            </S.FlexContainerRow>
            <S.ExtraLargeSpacer />
          </InfoContainer>
        </FooterContent>
        {showModal && <ContactModal closeModal={() => setShowModal(false)} />}
      </FooterContainer>
    </>
  )
}
