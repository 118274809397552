import React, { useState } from 'react'
import * as S from '../../ui'
import styled from 'styled-components'
import { colors } from '../../ui'
import { Modal } from './Modal'
import { Input } from '../Input'
import { logIn } from '../../auth/requests'
import { FieldValues, useForm } from 'react-hook-form'
import { FormInput } from '../FormInput'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '../../store/userReducer/actions'
import { useHistory } from 'react-router-dom'
import { SignInContent } from './SignInContent'

interface IProps {
  closeModal: () => void
  path?: string
}

export const SignInModal: React.FC<IProps> = (props) => {
  return (
    <Modal closeModal={props.closeModal}>
      <SignInContent closeModal={props.closeModal} path={props.path} />
    </Modal>
  )
}
