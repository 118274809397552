export const counties = [
  { value: 'blekinge', label: 'Blekinge' },
  { value: 'dalarna', label: 'Dalarna' },
  { value: 'gotland', label: 'Gotland' },
  { value: 'gävleborg', label: 'Gävleborg' },
  { value: 'halland', label: 'Halland' },
  { value: 'jämtland', label: 'Jämtland' },
  { value: 'jönköping', label: 'Jönköping' },
  { value: 'kalmar', label: 'Kalmar' },
  { value: 'kronoberg', label: 'Kronoberg' },
  { value: 'norrbotten', label: 'Norrbotten' },
  { value: 'skåne', label: 'Skåne' },
  { value: 'stockholm', label: 'Stockholm' },
  { value: 'södermanland', label: 'Södermanland' },
  { value: 'uppsala', label: 'Uppsala' },
  { value: 'varmland', label: 'Värmland' },
  { value: 'värsterbotten', label: 'Västerbotten' },
  { value: 'västernorrland', label: 'Västernorrland' },
  { value: 'västmanland', label: 'Västmanland' },
  { value: 'västra götaland', label: 'Västra götaland' },
  { value: 'örebro', label: 'Örebro' },
  { value: 'östergörland', label: 'Östergötland' },
]
export const countiesWithType = [
  { type: 'county', value: 'blekinge', label: 'Blekinge' },
  { type: 'county', value: 'dalarna', label: 'Dalarna' },
  { type: 'county', value: 'gotland', label: 'Gotland' },
  { type: 'county', value: 'gävleborg', label: 'Gävleborg' },
  { type: 'county', value: 'halland', label: 'Halland' },
  { type: 'county', value: 'jämtland', label: 'Jämtland' },
  { type: 'county', value: 'jönköping', label: 'Jönköping' },
  { type: 'county', value: 'kalmar', label: 'Kalmar' },
  { type: 'county', value: 'kronoberg', label: 'Kronoberg' },
  { type: 'county', value: 'norrbotten', label: 'Norrbotten' },
  { type: 'county', value: 'skåne', label: 'Skåne' },
  { type: 'county', value: 'stockholm', label: 'Stockholm' },
  { type: 'county', value: 'södermanland', label: 'Södermanland' },
  { type: 'county', value: 'uppsala', label: 'Uppsala' },
  { type: 'county', value: 'varmland', label: 'Värmland' },
  { type: 'county', value: 'värsterbotten', label: 'Västerbotten' },
  { type: 'county', value: 'västernorrland', label: 'Västernorrland' },
  { type: 'county', value: 'västmanland', label: 'Västmanland' },
  { type: 'county', value: 'västra götaland', label: 'Västra götaland' },
  { type: 'county', value: 'örebro', label: 'Örebro' },
  { type: 'county', value: 'östergörland', label: 'Östergötland' },
]
