import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as S from '../../ui'
import { colors } from '../../ui'
import { Icon, IconType } from '../../ui/assets/icons'

interface IStyleProps {
  padding?: string
  height?: string
  scroll?: boolean
}

export const Background = styled(S.FlexContainerColumnCenterCenter)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000048;
  z-index: 1000000;
  cursor: pointer;
`

export const ModalContainer = styled(S.FlexContainerColumn)<IStyleProps>`
  background-color: ${colors.white};
  max-width: 90vw;
  max-height: 90vh;
  min-width: 40vw;
  min-height: 40vh;
  overflow-y: ${(props) => (props.scroll ? 'scroll' : 'auto')};
  padding: ${(props) => props.padding ?? '40px'};
  border-radius: 3px;
  cursor: default;
`
export const TopContainer = styled(S.FlexContainerRow)`
  background-color: ${colors.white};
  justify-content: end;
`
export const ModalContent = styled(S.FlexContainerColumn)<IStyleProps>`
  height: ${(props) => props.height ?? '100%'};
`
interface IProps {
  closeModal: () => void
  padding?: string
  height?: string
  scroll?: boolean
}

export const Modal: React.FC<IProps> = (props) => {
  return (
    <Background onClick={props.closeModal}>
      <ModalContainer
        onClick={(e) => e.stopPropagation()}
        padding={props.padding}
        scroll={props.scroll}
      >
        <TopContainer>
          <S.InvisibleButton onClick={props.closeModal}>
            <Icon icon={IconType.Close} color={colors.black} />
          </S.InvisibleButton>
        </TopContainer>
        <S.SmallSpacer />
        <ModalContent height={props.height}>{props.children}</ModalContent>
      </ModalContainer>
    </Background>
  )
}
