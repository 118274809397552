import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
// import { init as sentryInit, ErrorBoundary } from './sentry'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './store'

//sentryInit()

const store = configureStore({ reducer: rootReducer })

ReactDOM.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>,
  document.getElementById('root')
)
