import React, { useEffect, useState } from 'react'
import { IContent, ImageSection } from '../../../components/ImageSection'
import * as S from '../../../ui'
import styled from 'styled-components'
import { colors } from '../../../ui/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { AuthRoutes, IPartner, NonAuthRoutes } from '../../../types'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

const CTAContainer = styled(S.NoMarginContainer)`
  background-color: ${colors.pink};
  height: calc(100vh - 42px);
  flex-direction: column;

  @media screen and (min-width: 1440px) {
    flex-direction: row;
  }
`

export const CreativePartners: React.FC = () => {
  const history = useHistory()
  const [partners, setPartners] = useState<IContent[]>()

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        image: '*',
      },
      encodeValuesOnly: true, // prettify URL
    })
    axios
      .get(`${BASE_URL}/api/creative-partners?${query}`)
      .then((res) => {
        setPartners(
          res.data.data.map((d: IPartner) => {
            return {
              image: d.attributes.image.data[0].attributes?.url,
              header: d.attributes.partnerName,
              body: d.attributes.county,
              id: d.id,
            }
          })
        )
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  const redirect = (id: string) => {
    history.push(`${NonAuthRoutes.creativePartners}/${id}`)
  }

  return (
    <>
      {partners && (
        <ImageSection
          title={'CREATIVE PARTNERS'}
          link={'/creative-partners'}
          handleClick={redirect}
          content={partners
            .sort(() => (Math.random() > 0.5 ? 1 : -1))
            .slice(0, 4)}
        />
      )}
      <S.VerticalMargin />
      <CTAContainer>
        <S.H2 color={colors.blue}>LET'S DO THIS</S.H2>
        <S.LargeSpacer />
        <S.ExtraLargeSpacerRow />
        <S.ButtonSecondary
          onClick={() => history.push(NonAuthRoutes.signUp)}
          color={colors.blue}
        >
          CREATE ACCOUNT
        </S.ButtonSecondary>
      </CTAContainer>
      <S.VerticalMargin />
    </>
  )
}
