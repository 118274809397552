import axios from 'axios'
import { RSA_NO_PADDING } from 'constants'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AuthRoutes } from '../../../types'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { Icon, IconType } from '../../../ui/assets/icons'
import { AccountNavBar } from '../components/AccountNavBar'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'
import { Spinner } from '../../../components/Spinner'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { ContactModal } from '../../../components/modals/ContactModal'

const AccountContainer = styled.div`
  padding: 20px;
  margin-top: 30px;
  align-items: center;

  @media screen and (min-width: 1200px) {
    padding: 20px;
    margin-top: 30px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;
  padding-bottom: 20px;
  flex-wrap: wrap;
`

const Card = styled(S.FlexContainerColumn)`
  position: relative;
  overflow: hidden;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  word-wrap: break-word;
  width: 100%;

  @media screen and (max-width: 1000px) {
    .text {
      display: none;
    }
  }
`

const Image = styled.img`
  @media screen and (max-width: 1199px) {
    height: 40vw;
  }
  max-height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 75%;
  background: rgba(61, 37, 35, 0.9);
  padding: 12px 32px;

  h5,
  h6,
  p {
    height: 30px;
    width: 200px;
    color: ${colors.white};
  }

  button {
    margin-top: 25px;
    margin-left: 30px;
    height: 50px;
    width: 200px;
  }
`

const Review = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 186px;
  height: 32px;
  background: ${colors.white};
  border: 1px ${colors.brown} solid;
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    margin-left: auto;
    margin-right: auto;
  }
`

const MobileText = styled.div`
  padding: 20px;
  display: block;
  color: ${colors.white};
  background: ${colors.brown};
  @media screen and (min-width: 1000px) {
    display: none;
  }
  h5,
  h6,
  p {
    height: 30px;
    width: 200px;
    color: ${colors.white};
  }
  button {
    width: 100%;
    height: 50px;
  }
`

const HeaderContainer = styled.div`
  width: min-content;
  margin: auto;
`

const Underline = styled.div`
  margin-top: 0px;
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
`

const NoLocationsContainer = styled.div`
  width: 80%;
  padding-top: 42px;
  padding-bottom: 170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    padding-top: 169px;
  }
`

const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export interface ILocation {
  id: string
  title: string
  county: string
  createdAt: string
  publishedAt: string
  updatedAt: string
  images: any
  views: number
  numberOfBookings: number
  reviewed: boolean | null
}

export const MyLocations: React.FC = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [locations, setLocations] = useState<ILocation[]>()
  const [showContactModal, setShowContactModal] = useState(false)

  const qs = require('qs')
  const query = qs.stringify(
    {
      pagination: {
        pageSize: 7,
      },
      populate: {
        locations: {
          populate: '*',
        },
      },
      sort: 'publishedAt:desc',
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLocations(res.data.locations)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [query])

  return (
    <>
      <ScrollToTopOnMount />
      <AccountNavBar></AccountNavBar>
      <S.Wrapper>
        {isLoading ? (
          <Spinner />
        ) : locations && locations.length > 0 ? (
          <S.ContentContainer>
            <S.PaddingContainer>
              <S.LargeSpacer />
              <S.H1>MY ACCOUNT / MY LOCATIONS</S.H1>
              <S.LargeSpacer />
              <ImageContainer className="imageContainer">
                {locations.map((location) => {
                  return (
                    <Card
                      key={location.id}
                      className={'card large'}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {location.images ? (
                        <Image src={location.images[0].url} />
                      ) : (
                        <>Ingen bild hittades</>
                      )}
                      {!location.reviewed && (
                        <Review>
                          <S.H6>UNDER GRANSKNING</S.H6>
                        </Review>
                      )}

                      <Text className="text">
                        <S.ExtraSmallSpacer />
                        <div>
                          <S.H5>{location.title}</S.H5>
                          <Underline />
                          <S.ExtraSmallSpacer />
                          <S.H6>{location.county && location.county}</S.H6>
                        </div>
                        <S.ExtraSmallSpacer />
                        <S.WhiteButton
                          border={'none'}
                          onClick={() => {
                            history.push(
                              `${AuthRoutes.customeUpdateLocationWithId}/${location.id}`
                            )
                          }}
                        >
                          ÄNDRA
                        </S.WhiteButton>
                        <S.ExtraSmallSpacer />
                      </Text>
                      <MobileText>
                        <S.ExtraSmallSpacer />
                        <div>
                          <S.H5>{location.title}</S.H5>
                          <Underline />
                          <S.ExtraSmallSpacer />
                          <S.H6>{location.county && location.county}</S.H6>
                        </div>
                        <S.SmallSpacer />
                        <S.WhiteButton
                          border={'none'}
                          onClick={() => {
                            history.push(
                              `${AuthRoutes.customeUpdateLocationWithId}/${location.id}`
                            )
                          }}
                        >
                          ÄNDRA
                        </S.WhiteButton>
                        <S.ExtraSmallSpacer />
                      </MobileText>
                    </Card>
                  )
                })}
              </ImageContainer>
              <S.ExtraLargeSpacer />
              <BottomTextContainer>
                <S.ButtonPrimary
                  width="100"
                  onClick={() => {
                    history.push(AuthRoutes.createLocation)
                    window.scroll(0, 0)
                  }}
                >
                  + ADD LOCATION
                </S.ButtonPrimary>
              </BottomTextContainer>
              <S.ExtraLargeSpacer />
              <S.ExtraLargeSpacer />
            </S.PaddingContainer>
          </S.ContentContainer>
        ) : locations ? (
          <S.ContentContainer>
            <S.PaddingContainer>
              <S.LargeSpacer />
              <S.H1>MY ACCOUNT / MY LOCATIONS</S.H1>
              <S.LargeSpacer />
              <NoLocationsContainer>
                <Icon icon={IconType.Home} />
                <S.MediumSpacer />
                <S.H4 color={colors.gray}>
                  DU HAR INTE LISTAT NÅGON LOCATION ÄN
                </S.H4>
                <S.MediumSpacer />
                <S.H6 color={colors.gray}>
                  Lägg upp en location för att komma igång
                </S.H6>
                <S.ExtraLargeSpacer />
                <S.ButtonPrimary
                  width="100"
                  onClick={() => {
                    history.push(AuthRoutes.createLocation)
                  }}
                >
                  LÄGG TILL LOCATION
                </S.ButtonPrimary>
              </NoLocationsContainer>
            </S.PaddingContainer>
          </S.ContentContainer>
        ) : null}
      </S.Wrapper>

      {showContactModal && (
        <ContactModal closeModal={() => setShowContactModal(false)} />
      )}
    </>
  )
}
