import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '../core'

interface IProps {
  color?: string
  background?: string
  width?: string
  height?: string
  padding?: string
  margin?: string
  position?: string
  border?: string
  display?: string
}

export const Button = styled.button<IProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.padding ?? '8px'};
  border: unset;
  cursor: pointer;
  background-color: unset;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  width: ${(props) => props.width ?? 'auto'};

  @media screen and (min-width: 1200px) {
    width: 240px;
    padding: ${(props) => props.padding ?? '12px'};
  }
`

export const ButtonPrimary = styled(Button)<IProps>`
  color: ${(props) => props.color ?? colors.black};
  background: ${(props) => props.background ?? colors.pink};
  width: ${(props) => props.width ?? '184px'};

  &.active {
    background-color: #aa8377;
  }

  &.notActive {
    background-color: #cfada28f;
    color: #000000b2;
  }

  &.disabled {
    color: ${colors.gray};
    background: #afaaa3;
    cursor: default;
  }

  @media screen and (min-width: 1200px) {
    border-radius: 1px;

    :hover {
      background: ${(props) => (!props.background ? '#aa8377' : '')};
    }
  }
`

export const MediaButton = styled(Button)<IProps>`
  color: ${colors.white};
  background: ${(props) => props.background ?? colors.pink};
  width: ${(props) => props.width ?? '184px'};
  border-radius: 1px;
`

export const ButtonSecondary = styled(Button)<IProps>`
  border: 1px solid ${(props) => props.color ?? colors.blue};
  color: ${(props) => props.color ?? colors.blue};
  border-radius: 1px;
  padding: 7px 13px;
  min-width: max-content;

  @media screen and (min-width: 1200px) {
    :hover {
      color: ${colors.white};
      background: ${(props) => props.color ?? colors.blue};
    }
  }
`

export const WhiteButton = styled(Button)<IProps>`
  border: ${(props) => props.border ?? `1px solid ${colors.black}`};
  color: ${colors.black};
  background-color: ${colors.white};
  border-radius: 1px;
  padding: 7px 13px;
  min-width: max-content;
  width: ${(props) => props.width ?? 'auto'};
`

export const ButtonSmall = styled(Button)`
  background: ${colors.blue};
  color: ${colors.white};
  width: 99px;

  @media screen and (min-width: 1200px) {
    width: 138px;
    :hover {
      background: #0d1e60;
    }
  }
`

export const ButtonSmallLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: unset;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  text-transform: uppercase;
  background: ${colors.blue};
  color: ${colors.white};
  width: 99px;

  @media screen and (min-width: 1200px) {
    width: 138px;
    :hover {
      background: #0d1e60;
    }
  }
`

export const ModalButtonPrimary = styled(Button)`
  width: ${(props) => props.width ?? '100%'};
  height: 46px;
  background-color: ${colors.orange};
  color: ${colors.white};
  cursor: pointer;

  :hover {
    background: #813e05;
  }

  &.disabled {
    color: ${colors.gray};
    background: #afaaa3;
    cursor: default;
  }
`

export const ModalButtonSecondary = styled(ModalButtonPrimary)`
  background-color: ${colors.yellow};

  :hover {
    background: #937000;
  }

  &.disabled {
    cursor: default;
    background: #afaaa3;
    color: ${colors.gray};
  }
`

export const StyledLink = styled(Link)<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  display: inline;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};
  cursor: pointer;

  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`

export const StyledA = styled.a<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};
  cursor: pointer;

  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`

export const StyledLinkButton = styled.button<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};
  padding: ${(props) => props.padding ?? 'auto'};
  border: none;
  background-color: transparent;
  cursor: pointer;

  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
`

export const DetailsSmallLink = styled.a<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: ${(props) => props.color ?? colors.black};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const RadioButton = styled.input.attrs({
  type: 'radio',
})`
  height: 16px;
  width: 16px;
  cursor: pointer;
`

export const TextButton = styled.button<IProps>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color ?? colors.blue};
  width: ${(props) => props.width ?? 'auto'};
  padding: ${(props) => props.padding ?? '8px 0'};
  margin: ${(props) => props.margin ?? '8px 0'};
  cursor: pointer;
  display: ${(props) => props.display ?? 'static'};

  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    margin: ${(props) => props.margin ?? '32px 0'};
  }
`

export const TextButtonLink = styled(Link)<IProps>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color ?? colors.blue};
  width: ${(props) => props.width ?? 'auto'};
  padding: ${(props) => props.padding ?? '8px 0'};
  margin: ${(props) => props.margin ?? '8px 0'};
  cursor: pointer;
  display: ${(props) => props.display ?? 'static'};

  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    margin: ${(props) => props.margin ?? '32px 0'};
  }
`

export const InvisibleButton = styled.button<IProps>`
  background-color: transparent;
  border: none;
  padding: ${(props) => props.padding ?? ''};
  cursor: pointer;
`

export const ExclusiveButton = styled(Button)`
  background-color: ${colors.yellow};
  color: ${colors.white};
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? 'auto'};
  padding: ${(props) => props.padding ?? '8px'};
  margin: ${(props) => props.margin ?? '0'};
  position: ${(props) => props.position ?? 'static'};

  @media screen and (min-width: 1200px) {
    width: max-content;
  }
`

export const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  position: fixed;
  background-color: ${colors.orange};
  border-radius: 50%;
  height: 48px;
  width: 48px;
  bottom: 76px;
  right: 16px;
  z-index: 10;
`

export const LargeOutlineButton = styled.button<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.black};
  border-radius: 1px;
  padding: 18px 34px;
  background-color: transparent;
  border-radius: 1px;
  width: calc(100% - 32px);
  cursor: pointer;

  :hover {
    border-width: 2px;
    padding: 17px 33px;
  }

  @media screen and (min-width: 1200px) {
    width: max-content;
    padding: 18px 40px;
    flex-direction: row;

    :hover {
      padding: 17px 39px;
    }
  }
`

export const AnchorLink = styled.section`
  &::before {
    content: '';
    display: block;
    height: 42px;
    margin-top: -42px;
    visibility: hidden;

    @media screen and (min-width: 1200px) {
      height: 192px;
      margin-top: -192px;
    }
  }
`

interface INavProps {
  padding?: string
  margin?: string
}

export const NavLink = styled(HashLink)<INavProps>`
  padding: ${(props) => props.padding ?? '16px'};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: ${colors.black};

  &.active {
    color: ${colors.yellow};
  }
  :hover {
    color: ${(props) => props.color ?? colors.yellow};
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }

  &.aboutButton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: ${(props) => props.color ?? colors.blue};
    cursor: pointer;

    :hover {
      color: ${(props) => props.color ?? colors.yellow};
    }

    @media screen and (min-width: 1200px) {
      margin-left: 16px;
      position: relative;
      left: calc(50% - 24px);
      font-size: 16px;
    }
  }
`
