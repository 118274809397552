import React, { useState } from 'react'
import * as S from '../../ui'
import styled from 'styled-components'
import { Modal } from '../../components/modals/Modal'
import { Icon, IconType } from '../../ui/assets/icons'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'

const Content = styled(S.FlexContainerColumnJustifyCenter)`
  width: 60vw;

  @media screen and (min-width: 1200px) {
    width: unset;
  }
`

interface IProps {
  closeModal: () => void
  signIn?: () => void
  url?: string
  title?: string
}

export const ShareModal: React.FC<IProps> = (props) => {
  const [copied, setCopied] = useState(false)

  const url = props.url
    ? `https://www.${props.url}`
    : 'https://www.bonanzalocation.com'
  const message =
    'Bonanza Location är den nya plattformen för företag att hyra locations för filminspelning eller bildproduktion. Kanske har du en location att hyra ut? Eller är på jakt efter en plats till ditt projekt? Anmäl dig idag!'

  return (
    <Modal closeModal={props.closeModal}>
      <Content>
        <S.H3>Share</S.H3>
        <S.ExtraSmallSpacer />
        <S.ParagraphSmall>
          {props.title ?? 'bonanzalocation.com'}
        </S.ParagraphSmall>
        <S.LargeSpacer />
        <S.WhiteButton
          width="100%"
          onClick={() => {
            navigator.clipboard.writeText(url)
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 2000)
          }}
        >
          {copied ? (
            'copied'
          ) : (
            <>
              <Icon icon={IconType.Copy} />
              <S.SmallSpacerRow />
              copy link
            </>
          )}
        </S.WhiteButton>
        <S.ExtraSmallSpacer />
        <FacebookShareButton quote={message} url={url}>
          <S.MediaButton background="#1737B3" width="100%">
            <Icon icon={IconType.Facebook} />
            <S.SmallSpacerRow />
            FACEBOOK
          </S.MediaButton>
        </FacebookShareButton>
        <S.ExtraSmallSpacer />
        <LinkedinShareButton title="Bonanza" source={message} url={url}>
          <S.MediaButton background="#170B61" width="100%">
            <Icon icon={IconType.Linkedin} />
            <S.SmallSpacerRow />
            LINKEDIN
          </S.MediaButton>
        </LinkedinShareButton>
        <S.ExtraSmallSpacer />
        <TwitterShareButton url={url}>
          <S.MediaButton background="#00A3E9" width="100%">
            <Icon icon={IconType.Twitter} />
            <S.SmallSpacerRow />
            TWITTER
          </S.MediaButton>
        </TwitterShareButton>
        <S.ExtraSmallSpacer />

        <EmailShareButton
          subject="Kolla in Bonanza location!"
          body={message}
          url={url}
        >
          <S.WhiteButton width="100%">
            <S.FlexContainerRow>
              <Icon icon={IconType.Email} />
              <S.SmallSpacerRow />
              email
            </S.FlexContainerRow>
          </S.WhiteButton>
        </EmailShareButton>
        <S.ExtraLargeSpacer />
      </Content>
    </Modal>
  )
}
