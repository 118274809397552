import React, { useEffect, useState } from 'react'
import { IContent, ImageSection } from '../../../components/ImageSection'
import * as S from '../../../ui'
import { useHistory } from 'react-router-dom'
import { colors } from '../../../ui/core'
import axios from 'axios'
import { ILocationInfo } from '../../Client/CreateLocation'
import { ContactModal } from '../../../components/modals/ContactModal'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

export interface IContentSafe {
  image: any
  id?: string
  header?: string
  body?: string
  keyWords?: string[]
  date?: string
  category?: string
  locationTags?: {
    id: number
    createdAt: string
    publishedAt: string
    tag: string
    updatedAt: string
  }
}

export const Locations: React.FC = () => {
  const [locations, setLocations] = useState<IContent[]>()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/location/clean-location`)
      .then((res) => {
        setLocations(
          res.data.map(
            (d: {
              images: any
              county: string
              title: string
              id: string
              locationTags: any
            }) => {
              return {
                image: d.images && d.images[0].url,
                header: d.county,
                body: d.title,
                id: d.id,
                locationTags: d.locationTags,
              }
            }
          )
        )
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  return (
    <>
      {locations && (
        <ImageSection
          title={'LOCATIONS'}
          link={'/locations'}
          authRequired
          location
          content={locations
            .sort(() => (Math.random() > 0.5 ? 1 : -1))
            .slice(0, 7)}
        />
      )}
      <S.VerticalMargin />
      <S.Wrapper>
        <S.LargeOutlineButton
          onClick={() => {
            setShowModal(true)
          }}
        >
          <S.DetailsBig>DON'T FIND WHAT YOU'RE LOOKING FOR?</S.DetailsBig>
          <S.ExtraSmallSpacer />
          <S.ExtraSmallSpacerRow />
          <S.DetailsBig color={colors.yellow}>LET US KNOW</S.DetailsBig>
        </S.LargeOutlineButton>
      </S.Wrapper>
      <S.VerticalMargin />

      {showModal && <ContactModal closeModal={() => setShowModal(false)} />}
    </>
  )
}
