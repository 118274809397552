import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getUser } from '../auth/requests'
import { userState } from '../store/userReducer/selectors'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'
import { useSelector } from 'react-redux'

interface IStyleProps {
  position?: string
}

const Button = styled(S.Button)<IStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;
  position: ${(props) => props.position ?? 'absolute'};
  width: ${(props) => props.width ?? 'max-content'};
  height: 40px;
  left: ${(props) => (props.position ? '0' : '12px')};
  top: ${(props) => (props.position ? '0' : '12px')};
  background: #f6f3f1;
  border-radius: 1px;
  color: ${colors.black};
`

interface IProps {
  label?: string
  type?: string
  position?: string
  border?: boolean
  width?: string
  creativePartner?: boolean
  isFavorite?: boolean
  onClick?: () => void
}

export const FavoritesButton: React.FC<IProps> = (props) => {
  return (
    <>
      <Button
        position={props.position}
        width={props.width}
        className={props.border ? 'border' : ''}
        onClick={(e) => {
          e.stopPropagation()
          props.onClick && props.onClick()
        }}
      >
        <Icon
          icon={IconType.Bookmark}
          stroke={'black'}
          fill={props.isFavorite ? 'black' : 'none'}
        />
        {props.creativePartner
          ? props.isFavorite
            ? 'Ta bort creative partner'
            : 'Spara creative partner'
          : props.isFavorite
          ? 'Ta bort location'
          : 'Spara location'}
      </Button>
    </>
  )
}
