import React from 'react'
import axios from 'axios'
import { BASE_URL } from '../utils/config'
import { FieldValues } from 'react-hook-form'

export const logIn = (identifier: string, password: string) => {
  return axios.post(`${BASE_URL}/api/auth/local`, {
    identifier: identifier,
    password: password,
  })
}

export const signUp = (data: FieldValues) => {
  return axios.post(`${BASE_URL}/api/auth/local/register`, {
    name: data.name,
    password: data.password,
    username: data.email,
    email: data.email,
    phoneNumber: data.phoneNumber,
    invoiceAddress: data.invoiceAddress,
    type: data.type,
    zipCode: data.zipCode,
    postalArea: data.postalArea,
    company: data.company,
    orgNumber: data.orgNumber,
  })
}

export const getUser = async () => {
  const qs = require('qs')
  const query = qs.stringify(
    {
      populate: {
        favoriteLocations: {
          populate: '*',
        },
        favoriteCreativePartners: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )

  const token = localStorage.getItem('token')
  if (!token) {
    throw new Error('No token in local storage')
  }
  return await axios.get(`${BASE_URL}/api/users/me?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const useToken = () => {
  const token = localStorage.getItem('token')
  return token
}
