import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { AuthRoutes, ILocationPage, NonAuthRoutes } from '../../../types'
import { Icon, IconType } from '../../../ui/assets/icons'
import { FullscreenImages } from '../../../components/FullscreenImages'
import { IContent, ImageSection } from '../../../components/ImageSection'
import { EnquiryModal } from '../../../components/modals/EnquiryModal'
import { FavoritesButton } from '../../../components/FavoritesButton'
import { useSelector } from 'react-redux'
import { userState } from '../../../store/userReducer/selectors'
import { State } from '../../../store/userReducer'
import { TextButtonCTA } from '../../../components/TextButtonCTA'
import { IFavorite, ILocation } from '../../../types'
import { removeFavorite, addFavorite } from '../../../utils/helperFunctions'
import { BASE_URL } from '../../../utils/config'
import {
  Image,
  Image1,
  Image2,
  ImageButtons,
} from '../../CreativePartners/components/CreativePartner'
import { toast } from 'react-toastify'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'

const Container = styled.div`
  @media screen and (min-width: 800px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 50px;
  }
  @media screen and (min-width: 1200px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 0 150px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 295px;
  position: relative;

  &.unVerified {
    margin-top: 98px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: 1200px) {
    height: 808px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;
  }
`

const BackLink = styled.div`
  display: none;
  @media screen and (min-width: 800px) {
    display: unset;
    position: absolute;
    bottom: -40px;
    left: 0;
  }
`

const TagContainer = styled(S.FlexContainerRowAlignCeter)`
  align-items: flex-start;
  height: min-content;
  flex-wrap: wrap;
`

const Tag = styled.div`
  background-color: ${colors.black};
  width: max-content;
  padding: 8px;
  margin: 4px;
`

const InformationContainer = styled(S.FlexContainerColumnCenterCenter)`
  background-color: ${colors.orange};
  padding: 50px 0;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 150px;
  }
`

const InfoBlock = styled(S.FlexContainerColumnCenterCenter)`
  @media screen and (min-width: 1200px) {
    align-items: unset;
    justify-content: unset;
  }
`

const Underline = styled.div`
  margin-top: 8px;
  background-color: ${colors.white};
  width: calc(100% + 32px);
  height: 1px;
`

interface IParams {
  id: string
}

export const Location: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<IParams>()
  const state: State = useSelector(userState)
  const [data, setData] = useState<IFavorite>()
  const [favoriteLocations, setFavoriteLocations] = useState<ILocation[]>()
  const [location, setLocation] = useState<ILocationPage>()
  const [suggestedLocations, setSuggestedLocations] = useState<IContent[]>()
  const [suggestedPartners, setSuggestedPartners] = useState<IContent[]>()
  const [showImages, setShowImages] = useState(false)
  const [isFavoriteLocation, setIsFavoriteLocation] = useState<boolean>(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const qs = require('qs')
  const query = qs.stringify({
    populate: {
      favoriteLocations: {
        populate: '*',
      },
      favoriteCreativePartners: {
        populate: '*',
      },
    },
  })

  const getMe = () => {
    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data)
        setFavoriteLocations(res.data.favoriteLocations)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    getMe()
  }, [id])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        images: '*',
        locationTags: '*',
        facilities: {
          populate: {
            icon: '*',
          },
        },
        directions: '*',
      },
      filters: {
        reviewed: true,
      },
      encodeValuesOnly: true, // prettify URL
    })

    axios
      .get(`${BASE_URL}/api/locations/${id}?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setLocation(res.data.data)
        axios.put(`${BASE_URL}/api/locations/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: {
            views: parseInt(res.data.data.attributes.views) + 1,
          },
        })
      })
      .catch((err) => {
        history.push(NonAuthRoutes.notFound)
        throw Error(err)
      })
  }, [id])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      pagination: {
        pageSize: 2,
      },
      populate: {
        image: '*',
        images: '*',
      },
      filters: {
        county: {
          $eq: location?.attributes.county,
        },
        id: {
          $ne: location?.id,
        },
      },
      encodeValuesOnly: true, // prettify URL
    })

    axios
      .get(`${BASE_URL}/api/creative-partners?${query}`)
      .then((res) => {
        setSuggestedPartners(
          res.data.data.map(
            (d: {
              attributes: {
                image: { data: { attributes: { url: any } }[] }
                partnerName: any
                county: any
              }
              id: any
            }) => {
              return {
                image: d?.attributes.image.data[0]?.attributes?.url,
                header: d?.attributes.partnerName,
                body: d?.attributes.county,
                id: d?.id,
              }
            }
          )
        )
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })

    axios
      .get(`${BASE_URL}/api/locations?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setSuggestedLocations(
          res.data.data.map(
            (d: {
              attributes: {
                images: { data: { attributes: { url: string } }[] }
                title: string
                county: any
              }
              id: any
            }) => {
              return {
                image: d?.attributes.images.data[0]?.attributes?.url,
                header: d?.attributes.title,
                body: d?.attributes.county,
                id: d?.id,
              }
            }
          )
        )
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [location])

  const sendLocationEnquiry = (budget: string, message: string) => {
    setSuccess(false)
    setError(false)

    axios
      .post(`${BASE_URL}/api/location/email`, {
        budget: budget,
        message: message,
        userEmail: data?.email,
        locationId: location?.id,
        locationTitle: location?.attributes.title,
      })
      .then(() => {
        setSuccess(true)
        axios.put(`${BASE_URL}/api/locations/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: {
            declaredInterest:
              parseInt(location?.attributes.declaredInterest ?? '0') + 1,
          },
        })
      })
      .catch(() => setError(true))
  }

  const updateFavorite = (locations: any) => {
    const favorites = {
      favoriteLocations: locations,
    }
    axios
      .put(`${BASE_URL}/api/users/${data?.id}`, favorites, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        getMe()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    if (showImages) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = 'static'
    }
  }, [showImages])

  useEffect(() => {
    if (favoriteLocations) {
      const locId = Number(id)
      setIsFavoriteLocation(favoriteLocations?.some((l) => l.id === locId))
    }
  }, [favoriteLocations, id])

  const toggleFavorite = (locationId: number) => {
    if (isFavoriteLocation) {
      const data = removeFavorite(locationId, favoriteLocations)
      updateFavorite(data)
    } else {
      const data = addFavorite(locationId, favoriteLocations)
      updateFavorite(data)
    }
  }

  const redirect = (id: string) => {
    history.push(`${NonAuthRoutes.creativePartners}/${id}`)
  }

  const redirectLocation = (id: string) => {
    history.push(`${AuthRoutes.locations}/${id}`)
  }

  if (!location || !favoriteLocations) return <></>

  const renderInfoContainer = (
    top: { title?: string; value?: string },
    bottom: { title?: string; value?: string }
  ) => {
    return (
      <InfoBlock>
        <S.FlexContainerRowAlignCeter>
          {top.title && (
            <>
              <S.H5 color={colors.white}>{top.title}</S.H5>
              {top.value && <S.SmallSpacerRow />}
            </>
          )}
          <S.ParagraphSmall color={colors.white}>{top.value}</S.ParagraphSmall>
        </S.FlexContainerRowAlignCeter>
        <S.ExtraSmallSpacer />
        <Underline />
        <S.SmallSpacer />
        <S.FlexContainerRowAlignCeter>
          {bottom.title && (
            <>
              <S.H5 color={colors.white}>{bottom.title}</S.H5>
              <S.SmallSpacerRow />
            </>
          )}
          <S.ParagraphSmall color={colors.white}>
            {bottom.value}
          </S.ParagraphSmall>
        </S.FlexContainerRowAlignCeter>
      </InfoBlock>
    )
  }

  if (!location) return <></>

  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <S.ContentContainer>
          <S.PaddingContainer>
            <S.VerticalMargin />
            <ImageContainer
              className={state.userInfo?.verified ? '' : 'unVerified'}
            >
              <Image1>
                <Image
                  src={`${location.attributes.images.data[0]?.attributes.url}`}
                  alt={
                    location.attributes.images.data[0]?.attributes
                      .alternativeText
                  }
                />
              </Image1>
              <S.FlexContainerColumn>
                <Image2>
                  <Image
                    src={`${location.attributes.images.data[1]?.attributes.url}`}
                    alt={
                      location.attributes.images.data[1]?.attributes
                        .alternativeText
                    }
                  />
                </Image2>
                <S.ExtraSmallSpacer />
                <Image2>
                  <Image
                    src={`${location.attributes.images.data[2]?.attributes.url}`}
                    alt={
                      location.attributes.images.data[2]?.attributes
                        .alternativeText
                    }
                  />
                </Image2>
              </S.FlexContainerColumn>
              {favoriteLocations && (
                <ImageButtons>
                  <FavoritesButton
                    position="static"
                    border
                    isFavorite={favoriteLocations?.some(
                      (l) => l.id === Number(id)
                    )}
                    onClick={() => {
                      toggleFavorite(Number(id))
                    }}
                  />
                  <S.ExtraSmallSpacerRow />
                  <S.WhiteButton
                    onClick={() => setShowImages(true)}
                    border="none"
                  >
                    Se alla bilder
                  </S.WhiteButton>
                </ImageButtons>
              )}

              <BackLink>
                <S.FlexContainerRowAlignCeter>
                  <Icon icon={IconType.ChevronLeft} />
                  <S.TextButton
                    margin="0"
                    onClick={() => history.goBack()}
                    color={colors.black}
                  >
                    TILLBAKA
                  </S.TextButton>
                </S.FlexContainerRowAlignCeter>
              </BackLink>
            </ImageContainer>

            <Container>
              <S.FlexContainerColumn width="100%">
                <S.MediumSpacer />
                <S.H1>{location.attributes.title}</S.H1>
                <S.ExtraSmallSpacer />
                <S.DetailsBig>{location.attributes.county}</S.DetailsBig>
                <S.MediumSpacer />
                <S.Paragraph>{location.attributes.description}</S.Paragraph>
                <S.ExtraLargeSpacer />
              </S.FlexContainerColumn>

              <S.FlexContainerColumn>
                <TagContainer>
                  {location.attributes.locationTags.data.map((d, i) => {
                    return (
                      <Tag key={i}>
                        <S.DetailsBig color={colors.white}>
                          {d.attributes.tag}
                        </S.DetailsBig>
                      </Tag>
                    )
                  })}
                  {location.attributes.exclusive && (
                    <S.ExclusiveButton margin="4px 0 0 4px">
                      <Icon icon={IconType.Tag} />
                      <S.ExtraSmallSpacerRow />
                      exklusivt objekt
                    </S.ExclusiveButton>
                  )}
                </TagContainer>
                <S.ExtraLargeSpacer />

                <S.H4>Faciliteter</S.H4>
                <S.LargeSpacer />
                <S.FlexContainerColumn>
                  {location.attributes.facilities?.data.map((d, i) => {
                    return (
                      <div key={i}>
                        <S.FlexContainerRowAlignCeter>
                          <img
                            src={d.attributes.icon.data.attributes.url}
                            alt={
                              d.attributes.icon.data.attributes.alternativeText
                            }
                          />
                          <S.MediumSpacerRow />
                          <S.DetailsSmall>
                            {d.attributes.facility}
                          </S.DetailsSmall>
                        </S.FlexContainerRowAlignCeter>
                        <S.LargeSpacer />
                      </div>
                    )
                  })}
                </S.FlexContainerColumn>
              </S.FlexContainerColumn>
            </Container>
            <S.VerticalMargin />
            <InformationContainer>
              {renderInfoContainer(
                { title: location.attributes.title, value: undefined },
                { title: undefined, value: location.attributes.county }
              )}
              <S.ExtraLargeSpacer />
              {renderInfoContainer(
                {
                  title: 'KVM',
                  value: location.attributes.squareMeters + 'M2',
                },
                {
                  title: 'RUM',
                  value: location.attributes.rooms + ' RUM & KÖK',
                }
              )}
              <S.ExtraLargeSpacer />
              {renderInfoContainer(
                {
                  title: 'EXKLUSIVT OBJEKT',
                  value: location.attributes.exclusive ? 'JA' : 'NEJ',
                },
                {
                  title: 'VÄDERSTRECK',
                  value: location.attributes.directions?.data
                    .map((d) => {
                      return ' ' + d.attributes.direction.toUpperCase()
                    })
                    .toString(),
                }
              )}
            </InformationContainer>
            <S.VerticalMargin />
          </S.PaddingContainer>
          {suggestedLocations && suggestedLocations.length > 0 && (
            <ImageSection
              fullWidthImages
              handleClick={redirectLocation}
              title={`Fler locations i ${location.attributes.county}`}
              content={suggestedLocations}
            />
          )}
          {suggestedPartners && suggestedPartners.length > 0 && (
            <ImageSection
              fullWidthImages
              handleClick={redirect}
              title={`Fler creative partners i ${location.attributes.county}`}
              content={suggestedPartners}
            />
          )}
          <S.VerticalMargin />
        </S.ContentContainer>
        {showImages && location.attributes.images && (
          <>
            <S.VerticalMargin />
            {location.attributes.images.data && (
              <FullscreenImages
                close={() => setShowImages(false)}
                images={location.attributes.images.data}
              />
            )}
          </>
        )}
        <TextButtonCTA
          blue
          reverse
          text={'Hittar du inte det du söker?'}
          buttonText={'Skicka önskemål'}
          request={'location'}
        />
        <S.VerticalMargin />
        <EnquiryModal
          recipient={'HYRESVÄRD'}
          onSend={sendLocationEnquiry}
          success={success}
          error={error}
          setSuccess={setSuccess}
        />
      </S.Wrapper>
    </>
  )
}
