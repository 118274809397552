import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import * as S from '../../../ui'
import { Images } from '../../../ui/assets/images'
import { toast } from 'react-toastify'
import { StyledMarkdownParser } from '../../../components/StyledParsedMarkdown'
import { BASE_URL } from '../../../utils/config'
import { Seo } from '../../../components/Seo'
import { ISeo } from '../../../types'

const TopSection = styled(S.FlexContainerColumn)`
  margin-bottom: 48px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 72px;
    flex-direction: row;
  }
`

const TextSection = styled.div`
  @media screen and (min-width: 1200px) {
    width: calc(50% - 32px);
    display: flex;
  }
`

const ImageSection = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
  }
  @media screen and (min-width: 1200px) {
    width: calc(50% + 32px);
  }
`

const ImageContainer = styled(S.FlexContainerColumn)`
  white-space: normal;
  word-break: keep-all;
  position: relative;

  @media screen and (min-width: 800px) {
    width: calc(50% + 24px);
  }
`

const AboutImage = styled(S.FlexContainerColumn)`
  width: calc(100% - 25px); //image text width
  height: calc(100vw - 32px);
  max-height: 500px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: 1200px) {
  }
`

const ImageText = styled(S.DetailsSmall)`
  position: absolute;
  right: 0;
  writing-mode: vertical-rl;
  text-orientation: sideways-left;
  width: 25px;
`

const Spacer = styled.div`
  @media screen and (min-width: 800px) {
    height: 100%;
    width: 16px;
  }
  @media screen and (min-width: 1200px) {
    width: 24px;
  }
`

const BottomSection = styled(S.FlexContainerColumn)`
  width: 100%;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`

const BottomImageSection = styled(TextSection)`
  @media screen and (min-width: 1200px) {
    width: calc(50% - 32px);
  }
`
const QuoteSection = styled(S.FlexContainerColumn)`
  @media screen and (min-width: 1200px) {
    width: calc(50% + 32px);
  }
`

const BottomImageContainer = styled(ImageContainer)`
  white-space: normal;
  word-break: keep-all;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: 100%;
  }
`

export interface IAbout {
  header: string
  quote: string
  text1: string
  text2: string
  images: {
    data: {
      id: number
      attributes: {
        url: string
        caption: string
        alternativeText: string
      }
    }[]
  }
  seo: ISeo
}

export const TextImageBlocks: React.FC = () => {
  const [about, setAbout] = useState<IAbout | undefined>()
  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: '*',
    })
    axios
      .get(`${BASE_URL}/api/about?${query}`)
      .then((res) => {
        setAbout(res.data.data.attributes)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  return (
    <>
      {about?.seo && <Seo metaData={about.seo} />}
      <S.VerticalMargin />
      <S.H1>{about?.header}</S.H1>
      <S.LargeSpacer />
      {about?.images && about.images.data.length >= 2 && (
        <TopSection>
          <TextSection>
            <StyledMarkdownParser text={about?.text1 ?? ''} />
          </TextSection>
          <S.MediumSpacer />
          <ImageSection>
            <ImageContainer>
              <AboutImage>
                <img
                  src={about?.images.data[0].attributes.url}
                  alt={about?.images.data[0].attributes.alternativeText}
                />
              </AboutImage>
              <ImageText>{about?.images.data[0].attributes.caption}</ImageText>
            </ImageContainer>
            <S.SmallSpacer />
            <Spacer />
            <ImageContainer>
              <AboutImage>
                <img
                  src={about?.images.data[1].attributes.url}
                  alt={about?.images.data[1].attributes.alternativeText}
                />
              </AboutImage>
              <ImageText>{about?.images.data[1].attributes.caption}</ImageText>
            </ImageContainer>
          </ImageSection>
        </TopSection>
      )}
      {about?.images && about.images.data.length >= 3 && (
        <BottomSection>
          <BottomImageSection>
            <BottomImageContainer>
              <AboutImage>
                <img
                  src={about?.images.data[2].attributes.url}
                  alt={about?.images.data[2].attributes.alternativeText}
                />
              </AboutImage>
              <ImageText>{about?.images.data[2].attributes.caption}</ImageText>
            </BottomImageContainer>
          </BottomImageSection>
          <S.MediumSpacer />
          <QuoteSection>
            <S.H3 align="center">{about?.quote}</S.H3>
            <S.MediumSpacer />
            <StyledMarkdownParser text={about?.text2 ?? ''} />
          </QuoteSection>
        </BottomSection>
      )}
      <S.ExtraLargeSpacer />
    </>
  )
}
