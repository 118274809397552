import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { NonAuthRoutes, IUpdate } from '../../../types'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { dateWithMonthName } from '../../../utils/dateFormatting'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

const ImageContainer = styled(S.ContentContainer)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;
  padding-bottom: 20px;
  margin: 0;

  @media screen and (min-width: 1200px) {
    gap: 32px;
    overflow-x: unset;
    white-space: unset;
    flex-wrap: wrap;
    margin: auto;
  }
`

const Card = styled(S.FlexContainerColumn)`
  width: 304px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  word-wrap: break-word;
  align-items: flex-start;
  cursor: pointer;

  // mobile only
  @media screen and (max-width: 1199px) {
    :first-of-type {
      margin-left: 16px;
    }
    :last-of-type {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    width: calc(25% - 24px);

    &.large {
      :nth-of-type(3) {
        width: calc(50% - 16px);
      }
    }

    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

export const ReadMore = styled(S.FlexContainerColumnCenterCenter)`
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 456px;
    width: 100%;
    background: rgba(61, 37, 35, 0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

const Image = styled.img`
  height: 456px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

interface IProps {
  title: string
  link?: string
  date?: string
  updates?: boolean
  category: string
  exclude?: number
  hasContent: (hasContent: boolean) => void
  handleClick?: (id: string) => void
}

export interface IContent {
  image: any
  header: string
  body: string
  keyWords?: string[]
  date?: string
  category?: string
}

export const Suggested: React.FC<IProps> = (props) => {
  const history = useHistory()
  const [updates, setUpdates] = useState<IUpdate[]>([])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify(
      {
        pagination: {
          pageSize: 4,
        },
        populate: '*',
        filters: {
          id: {
            $ne: props.exclude,
          },
          updateCategory: {
            category: {
              $eq: props.category,
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(`${BASE_URL}/api/updates?${query}`)

      .then((res) => {
        setUpdates(res.data.data)
        props.hasContent(res.data.data.length > 1 ? true : false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [props.category, props.exclude])

  return (
    <div>
      <S.Wrapper>
        <S.TitleTextContainer>
          <S.H4>{props.title}</S.H4>
        </S.TitleTextContainer>
      </S.Wrapper>
      <S.SmallSpacer />
      <ImageContainer>
        {updates.map((u, i) => {
          return (
            <Card
              key={u.id}
              onClick={() => history.push(`${NonAuthRoutes.updates}/${u.id}`)}
            >
              <Image
                src={
                  u.attributes.thumbnail &&
                  u.attributes.thumbnail.data &&
                  u.attributes.thumbnail.data.attributes.url
                }
              />
              <ReadMore>
                <S.StyledLink
                  to={`${NonAuthRoutes.updates}/${u.id}`}
                  color={colors.white}
                >
                  Read more
                </S.StyledLink>
              </ReadMore>
              <div style={{ whiteSpace: 'normal' }}>
                <S.ExtraSmallSpacer />
                <S.H5>{u.attributes.title}</S.H5>
                <S.ExtraSmallSpacer />
                <S.FlexContainerRowAlignCeter>
                  <S.H5 color={colors.gray}>
                    <b>
                      {u.attributes.updateCategory.data.attributes.category}
                    </b>{' '}
                    {dateWithMonthName(u.attributes.createdAt)}
                  </S.H5>
                  <S.ExtraSmallSpacerRow />
                </S.FlexContainerRowAlignCeter>
                <S.ExtraSmallSpacer />
                <S.ParagraphSmall>
                  {u.attributes.text &&
                    u.attributes.text.substring(0, 80) + '...'}
                </S.ParagraphSmall>
              </div>
              <S.MediumSpacer />
            </Card>
          )
        })}
      </ImageContainer>
    </div>
  )
}
