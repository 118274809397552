import axios from 'axios'
import { BASE_URL } from '../utils/config'

export const useSendVerifiedContract = (
  id: number,
  type: string,
  email: string
) => {
  axios
    .post(`${BASE_URL}/api/verified/createContract`, {
      data: {
        id: id,
        type: type,
        email: email,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.error(err.response.data.error.message)
    })
}
