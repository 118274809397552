import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown } from '../../../components/Dropdown'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { FavoritesButton } from '../../../components/FavoritesButton'
import { AuthRoutes, IPartner, NonAuthRoutes } from '../../../types'
import { BASE_URL } from '../../../utils/config'
import { toast } from 'react-toastify'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { PaginationButton } from '../../Updates/components/AllUpdates'

const ImageContainer = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 16px;
    padding-bottom: 20px;
    gap: 32px;
    overflow-x: unset;
    white-space: unset;
    flex-wrap: wrap;
  }
`

const Card = styled(S.FlexContainerColumn)`
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1200px) {
    width: 304px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    word-wrap: break-word;
    align-items: flex-start;
    width: calc(25% - 24px);
    &.large {
      :nth-of-type(3) {
        width: calc(50% - 16px);
      }
    }
    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

const Image = styled.img`
  @media screen and (max-width: 1199px) {
    height: 40vw;
  }
  height: 456px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Text = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    white-space: normal;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: rgba(61, 37, 35, 0.9);
    padding: 12px 32px;
    h5,
    p {
      color: ${colors.white};
      text-align: center;
    }
    h5 {
      color: ${colors.white};
      text-align: center;
      padding: 0 12px;
    }
    .details {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const MobileText = styled.div`
  display: block;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const HeaderContainer = styled.div`
  width: min-content;
  margin: auto;
`

const Underline = styled.div`
  margin-top: 8px;
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
`

export const PreviewCreativePartners: React.FC = () => {
  const history = useHistory()
  const [partners, setPartners] = useState<IPartner[]>([])
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const maxPages = Math.ceil(total / 7)

  const qs = require('qs')
  const query = qs.stringify(
    {
      pagination: {
        page: page,
        pageSize: 7,
      },
      populate: {
        image: {
          populate: '*',
        },
        profession: {
          populate: '*',
        },
      },
      sort: 'publishedAt:desc',
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/creative-partners?${query}`)
      .then((res) => {
        setPartners(res.data.data)
        setTotal(res.data.meta.pagination.total)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [query])

  useEffect(() => {
    console.log('maxPages', maxPages)
    setPaginationButtons([])
    for (let i = 0; i < maxPages; i++) {
      setPaginationButtons((prev) => [
        ...prev,
        <PaginationButton
          onClick={() => {
            window.scroll(0, 0)
            setPage(i + 1)
          }}
          className={page === i + 1 ? 'active' : ''}
          disabled={page === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [maxPages, page])

  return (
    <>
      <ScrollToTopOnMount />
      <ImageContainer className="imageContainer">
        {partners.map((p, i) => {
          return (
            <Card
              key={p.id}
              className={'card large'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() =>
                history.push(`${NonAuthRoutes.creativePartners}/${p.id}`)
              }
            >
              {p.attributes.image && (
                <Image
                  src={
                    p.attributes.image.data &&
                    p.attributes.image.data[0].attributes.url
                  }
                  alt={p.attributes.image.data[0].attributes.alternativeText}
                />
              )}
              <Text className="text">
                <S.ExtraSmallSpacer />
                <HeaderContainer>
                  <S.H5>{p.attributes.county}</S.H5>
                  <Underline />
                </HeaderContainer>
                <S.LargeSpacer />
                <S.Paragraph>
                  {p.attributes.profession &&
                    p.attributes.profession.data &&
                    p.attributes.profession.data.attributes.profession}
                </S.Paragraph>
                <S.ExtraSmallSpacer />
              </Text>
              <MobileText>
                <S.SmallSpacer />
                <S.FlexContainerRowAlignCeter>
                  <S.H5>
                    {p.attributes.profession &&
                      p.attributes.profession.data &&
                      p.attributes.profession.data.attributes.profession}
                  </S.H5>
                </S.FlexContainerRowAlignCeter>
                <S.ExtraSmallSpacer />
                <S.Paragraph>{p.attributes.county}</S.Paragraph>
                <S.MediumSpacer />
              </MobileText>
            </Card>
          )
        })}
      </ImageContainer>
      {total > 7 && (
        <S.FlexContainerRowCenterCenter>
          {paginationButtons}
        </S.FlexContainerRowCenterCenter>
      )}
    </>
  )
}
