import * as actions from './actions'
import * as selectors from './selectors'
import { Actions } from './actions'
import { IUser } from '../../auth/types'

export type State = {
  loading: boolean
  userInfo: IUser | null // for user object
}

const initialState = {
  loading: false,
  userInfo: null,
}

const userReducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case actions.LOG_IN:
      return {
        ...state,
        userInfo: action.payload.userInfo,
      }

    default:
      return state
  }
}

export { actions, selectors }
export default userReducer
