import React from 'react'
import styled from 'styled-components'
import { colors } from '../ui'

interface IStyleProps {
  noBackground?: boolean
  height?: string
  width?: string
  color?: string
}

const StyledContainer = styled.div<IStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.noBackground ? 'auto' : '100vh')};
  width: ${(props) => (props.noBackground ? 'auto' : '100vw')};
  background-color: ${(props) =>
    props.noBackground ? 'transparent' : colors.white};
`

const StyledSpinner = styled.svg<IStyleProps>`
  animation: rotate 2s linear infinite;
  width: ${(props) => (props.width ? props.width : '50px;')};
  height: ${(props) => (props.height ? props.height : '50px;')};

  & .path {
    stroke: ${(props) =>
      props.color
        ? props.color
        : props.noBackground
        ? colors.gray
        : colors.white};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export interface IProps {
  noBackground?: boolean
  width?: string
  height?: string
  color?: string
}

export const Spinner: React.FC<IProps> = ({
  noBackground,
  width,
  height,
  color,
}) => (
  <StyledContainer noBackground={noBackground}>
    <StyledSpinner
      viewBox="0 0 50 50"
      width={width}
      height={height}
      color={color ? color : colors.gray}
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
  </StyledContainer>
)
