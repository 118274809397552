import React from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { Nav } from './Nav'
import { colors } from '../ui'
import { NonAuthRoutes } from '../types'

export const HeaderContainer = styled.header`
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 42px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media screen and (min-width: 1200px) {
    height: 192px;
    flex-direction: column;
  }
`

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  height: 42px;

  @media screen and (min-width: 1200px) {
    height: calc(192px - 42px);
  }
`

export const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <FlexBox>
        <S.HeaderText href={NonAuthRoutes.home}>Bonanza location</S.HeaderText>
      </FlexBox>

      <Nav />
    </HeaderContainer>
  )
}
