import React from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

const InputField = styled.input`
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  height: 38px;
  background-color: ${colors.white};
  &.icon {
    padding-left: 40px;
  }
  &.border {
    border: 1px solid ${colors.gray};
    height: 54px;
  }
  @media screen and (min-width: 1200px) {
    height: 46px;
    /* font-size: 12px; */
  }
`

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`

interface IProps {
  setValue?: (value: string) => void
  placeholder?: string
  button?: boolean
  submit?: () => void
  border?: boolean
  label?: string
  value?: string
  type?: string
  searchIcon?: boolean
}

export const Input: React.FC<IProps> = (props) => {
  return (
    <S.FlexContainerRowCenterCenter width="100%">
      <S.FlexContainerColumn position="relative" width="100%">
        {props.label && (
          <>
            <S.Paragraph>{props.label}</S.Paragraph>
            <S.ExtraSmallSpacer />
          </>
        )}
        {props.searchIcon && (
          <SearchIcon>
            <Icon icon={IconType.Search} />
          </SearchIcon>
        )}
        <InputField
          value={props.value}
          onChange={(e) => props.setValue && props.setValue(e.target.value)}
          onSubmit={() => props.submit && props.submit()}
          type={props.type ?? 'text'}
          placeholder={props.placeholder?.toUpperCase()}
          className={`${props.border ? 'border' : ''} ${
            props.searchIcon ? 'icon' : ''
          }`}
        />
      </S.FlexContainerColumn>
      {props.button && (
        <>
          <S.SmallSpacerRow />
          <S.ButtonPrimary
            color={colors.white}
            background={colors.blue}
            width="min-content"
          >
            SUBSCRIBE
          </S.ButtonPrimary>
        </>
      )}
    </S.FlexContainerRowCenterCenter>
  )
}
