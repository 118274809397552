import React, { useState, useEffect } from 'react'

import * as S from '../../ui'
import axios from 'axios'
import { Hero } from './components/Hero'
import { Locations } from './components/Locations'
import { CreativePartners } from './components/CreativePartners'
import { About } from './components/About'
import { Updates } from './components/Updates'
import { Icon, IconType } from '../../ui/assets/icons'
import styled from 'styled-components'
import { StatBanner } from './components/StatBanner'
import { ContactModal } from '../../components/modals/ContactModal'
import { Cookies } from './components/Cookies'
import { BASE_URL } from '../../utils/config'
import { Seo } from '../../components/Seo'
import { ISeo } from '../../types'

const HomeContainer = styled.div`
  padding-top: 42px;

  @media screen and (min-width: 1200px) {
    padding-top: 169px;
  }
`

const BottomContainer = styled(S.FlexContainerColumn)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`

export const Home: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [seo, setSeo] = useState<ISeo>()

  useEffect(() => {
    axios.get(`${BASE_URL}/api/home?populate=seo.metaImage`).then((res) => {
      setSeo(res.data.data.attributes.seo)
    })
  }, [])

  return (
    <>
      {seo && <Seo metaData={seo} />}
      <HomeContainer>
        <S.AnchorLink id="hero">
          <Hero />
        </S.AnchorLink>
        <S.AnchorLink id="locations">
          <Locations />
        </S.AnchorLink>
        <S.AnchorLink id="creativePartners">
          <CreativePartners />
        </S.AnchorLink>
        <S.AnchorLink id="about">
          <About />
        </S.AnchorLink>
        <S.AnchorLink id="updates">
          <Updates />
        </S.AnchorLink>
        <S.RoundButton onClick={() => setShowModal(true)}>
          <Icon icon={IconType.Message} />
        </S.RoundButton>
      </HomeContainer>
      <BottomContainer>
        <Cookies />
        <StatBanner />
      </BottomContainer>
      {showModal && <ContactModal closeModal={() => setShowModal(false)} />}
    </>
  )
}
