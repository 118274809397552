import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { getUser, useToken } from '../auth/requests'
import { IUser } from '../auth/types'
import { AuthRoutes, NonAuthRoutes } from '../types'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'
import { useGetUserType } from '../utils/useGetUserType'
import { useIsLoggedIn } from '../utils/useIsLoggedIn'
import { ContactModal } from './modals/ContactModal'
import { SmallNav } from './SmallNav'
import { BASE_URL } from '../utils/config'

interface IStyleProps {
  top?: string
  banner?: boolean
}

export const UnverifiedBanner = styled(S.FlexContainerColumnCenterCenter)`
  background-color: ${colors.blue};
  height: 80px;
  padding: 2px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  * {
    text-align: center;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    height: 59px;
    padding: 0;
  }
`

export const Container = styled.header<IStyleProps>`
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 42px;
  position: fixed;
  top: ${(props) => (props.banner ? '80px' : '0')};
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid ${colors.gray};

  &.unverified {
    top: 80px;
  }

  @media screen and (min-width: 1200px) {
    height: 114px;
    padding: 0 64px;
    top: ${(props) => (props.banner ? '50px' : '0')};

    &.unverified {
      top: 50px;
    }
  }
`
const BackLink = styled.div<IStyleProps>`
  display: none;

  @media screen and (min-width: 1200px) {
    display: unset;
    position: absolute;
    top: 138px;
    left: 64px;
  }
`

const StyledFlex = styled(S.FlexContainerRowJustifyCenter)`
  width: auto;
`

interface IProps {
  banner?: boolean
  hideBackLink?: boolean
  public?: boolean
}

interface ILink {
  title: string
  link: string
}

export const SmallHeader: React.FC<IProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const token = useToken()
  const isSignedIn = useIsLoggedIn()
  const userType = useGetUserType()
  const [isVerified, setIsVerified] = useState<boolean | undefined>()
  const [verifiedCheckComplete, setVerifiedCheckComplete] = useState(false)
  const [displayUnverifiedBanner, setDisplayUnverifiedBanner] =
    useState<boolean>(false)
  const [links, setLinks] = useState<ILink[]>([])
  const [accountLinks, setAccountLinks] = useState<ILink[]>([])
  const [showContactModal, setShowContactModal] = useState(false)

  useEffect(() => {
    //if (userType) return
    if (localStorage.getItem('token')) {
      getUser().then(async (res: { data: IUser }) => {
        setVerifiedCheckComplete(true)
        if (res.data.verified) {
          setIsVerified(res.data.verified)
        } else {
          await axios
            .post(`${BASE_URL}/api/verified/checkHasSigned`, {
              data: { ...res.data },
            })
            .then((res) => {
              setIsVerified(res.data)
            })
            .catch((err) => {
              throw Error('Could not upload the location:' + err)
            })
        }
      })
    }
  }, [userType])

  useEffect(() => {
    if (
      userType &&
      userType === 'company' &&
      location.pathname !== NonAuthRoutes.signUp
    ) {
      setLinks([
        {
          title: 'Find location',
          link: AuthRoutes.locations,
        },
        {
          title: 'Creative partners',
          link: NonAuthRoutes.creativePartners,
        },
      ])
    } else {
      setLinks([])
    }
  }, [userType])

  useEffect(() => {
    if (userType && userType === 'company') {
      setAccountLinks([
        {
          title: 'saved',
          link: AuthRoutes.favorites,
        },
        {
          title: 'profile information',
          link: AuthRoutes.account,
        },
      ])
    } else if (userType && userType === 'customer') {
      setAccountLinks([
        {
          title: 'my locations',
          link: AuthRoutes.customerLocations,
        },
        {
          title: 'profile information',
          link: AuthRoutes.customerAccount,
        },
      ])
    } else {
      setLinks([])
    }
  }, [userType])

  useEffect(() => {
    if (!token) return
    if (
      (location.pathname.includes('locations/') ||
        location.pathname.includes('create-location')) &&
      !isVerified
    ) {
      setDisplayUnverifiedBanner(true)
    } else {
      setDisplayUnverifiedBanner(false)
    }
  }, [isVerified, location, token, userType])

  return (
    <>
      {verifiedCheckComplete && displayUnverifiedBanner && (
        <UnverifiedBanner>
          <S.ParagraphSmall color={colors.white}>
            {location.pathname.includes('create-location')
              ? 'Vi har skickat ett mail till dig, där du verifierar ditt konto och signerar avtalet. Därefter kan du skapa locations.'
              : 'Verifiera ditt konto för att kunna skicka förfrågningar.'}
          </S.ParagraphSmall>
          <S.SmallSpacerRow />
          <StyledFlex>
            <S.StyledLink to={NonAuthRoutes.howItWorks} color={colors.white}>
              HOW IT WORKS
            </S.StyledLink>
            <S.SmallSpacerRow />
            <S.StyledLinkButton
              onClick={() => setShowContactModal(true)}
              color={colors.white}
            >
              Kontakta oss
            </S.StyledLinkButton>
          </StyledFlex>
        </UnverifiedBanner>
      )}
      <Container
        banner={props.banner}
        className={
          verifiedCheckComplete && displayUnverifiedBanner ? 'unverified' : ''
        }
      >
        <S.SmallHeaderText href="/">Bonanza location</S.SmallHeaderText>
        <SmallNav
          links={props.public ? undefined : links}
          accountLinks={accountLinks ?? undefined}
          userType={userType}
          isSignedIn={isSignedIn}
          hasVerifiedBanner={verifiedCheckComplete && displayUnverifiedBanner}
        />
      </Container>

      {props.public && (
        <BackLink>
          <S.FlexContainerRowAlignCeter>
            <Icon icon={IconType.ChevronLeft} />
            <S.TextButton
              margin="0"
              onClick={() => history.goBack()}
              color={colors.black}
            >
              TILLBAKA
            </S.TextButton>
          </S.FlexContainerRowAlignCeter>
        </BackLink>
      )}

      {showContactModal && (
        <ContactModal closeModal={() => setShowContactModal(false)} />
      )}
    </>
  )
}
