import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IOption } from '../../components/Dropdown'
import styled from 'styled-components'
import * as S from '../../ui'
import { colors } from '../../ui'
import { PreviewLocations } from './components/PreviewLocations'
import { Icon, IconType } from '../../ui/assets/icons'
import { AllLocations } from './components/AllLocations'
import { RelativeDropdown } from '../../components/RelativeDropdown'
import { useDebouncedCallback } from 'use-debounce'
import { PaginationButton } from '../Updates/components/AllUpdates'
import { TextButtonCTA } from '../../components/TextButtonCTA'
import { ImageSection } from '../../components/ImageSection'
import { IContent } from '../Updates/components/Suggested'
import { counties, countiesWithType } from '../../utils/counties'
import { IFavorite, ILocation, ILocationPage, NonAuthRoutes } from '../../types'
import { removeFavorite, addFavorite } from '../../utils/helperFunctions'
import { BASE_URL } from '../../utils/config'
import { toast } from 'react-toastify'
import { IPartner } from '../../types'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { SearchSelect } from '../../components/SearchSelect'
import { RequestModal } from '../../components/modals/RequestModal'
import { Spinner } from '../../components/Spinner'
import { NoResults, ResultContainer } from '../CreativePartners'

const SearchContainer = styled(S.FlexContainerRowCenterCenter)`
  width: 100%;
  border-bottom: 1px solid ${colors.gray};
  input {
    width: 100%;
  }
  .desktop {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    padding: 16px 0;
    input {
      width: 377px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: flex;
    }
  }
`

const FilterContainer = styled(S.Main)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.white};
  overflow-y: scroll;
  padding-bottom: 32px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const SearchButton = styled(S.Button)`
  background-color: ${colors.orange};
  color: ${colors.white};
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: max-content;
    height: 54px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LocationPage: React.FC = () => {
  const history = useHistory()

  const [locations, setLocations] = useState<ILocationPage[] | undefined>([])
  const [counties, setCounties] = useState<IOption[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const maxPages = Math.ceil(total / 10)
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openSort, setOpenSort] = useState(false)
  const [searchValue, setSearchValue] = useState<IOption[]>([])
  const [county, setCounty] = useState<IOption | undefined>()
  const [tag, setTag] = useState<IOption | undefined>()
  const [tags, setTags] = useState<IOption[]>([])
  const [titles, setTitles] = useState<IOption[]>([])
  const [showAll, setShowAll] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filterExclusive, setFilterExclusive] = useState(false)
  const [suggestedPartners, setSuggestedPartners] = useState<IContent[]>()
  const [data, setData] = useState<IFavorite>()
  const [favoriteLocations, setFavoriteLocations] = useState<ILocation[]>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    getLocations()
  }, [page, county, tag, filterExclusive])

  useEffect(() => {
    setIsLoading(true)
    const searchValueFromLS = sessionStorage.getItem('searchValue')
    const tagFromLS = sessionStorage.getItem('tag')
    const countyFromLS = sessionStorage.getItem('county')
    const filterExclusiveFromLS = sessionStorage.getItem('filterExclusive')

    searchValueFromLS && setSearchValue(JSON.parse(searchValueFromLS))
    tagFromLS && tagFromLS !== 'undefined' && setTag(JSON.parse(tagFromLS))
    countyFromLS &&
      countyFromLS !== 'undefined' &&
      setCounty(JSON.parse(countyFromLS))
    filterExclusiveFromLS &&
      setFilterExclusive(JSON.parse(filterExclusiveFromLS))

    axios
      .get(`${BASE_URL}/api/location/relevant-filters`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const tagArray = res.data.locationTags.map((tag: string) => {
          return {
            label: tag,
            value: tag,
            type: 'tag',
          }
        })
        setTags(tagArray)
        sessionStorage.setItem('tags', JSON.stringify(tagArray))

        const countyArray = res.data.counties.map(
          (county: { label: string; value: string }) => {
            return {
              label: county.label,
              value: county.value,
              type: 'county',
            }
          }
        )
        setCounties(countyArray)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })

    axios
      .get(`${BASE_URL}/api/locations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const titleArray = res.data.data.map(
          (t: { attributes: { title: string } }) => {
            return {
              label: t.attributes.title,
              value: t.attributes.title,
              type: 'title',
            }
          }
        )
        setTitles(titleArray)
      })
  }, [])

  const qs = require('qs')
  const query = qs.stringify({
    populate: {
      favoriteLocations: {
        populate: '*',
      },
    },
  })

  const getMe = () => {
    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data)
        setFavoriteLocations(res.data.favoriteLocations)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    getMe()
    const page = sessionStorage.getItem('locationPage')
    page && setPage(parseInt(page))
  }, [])

  const getLocations = useDebouncedCallback(() => {
    if (
      searchValue.length > 0 ||
      tag ||
      county ||
      filterExclusive ||
      page !== 1
    ) {
      setShowAll(true)
    }

    sessionStorage.setItem('searchValue', JSON.stringify(searchValue))
    sessionStorage.setItem('tag', JSON.stringify(tag))
    sessionStorage.setItem('county', JSON.stringify(county))
    sessionStorage.setItem('filterExclusive', JSON.stringify(filterExclusive))

    const tagFilters = searchValue.map((o) => {
      if (o.type === 'tag') return o.label
    })

    const countyFilters = searchValue.map((o) => {
      if (o.type === 'county') return o.label
    })

    const titleFilters = searchValue.map((o) => {
      if (o.type === 'title') return o.label
    })

    let tagFilterQuery: any = []

    tagFilters.forEach((tf) => {
      tagFilterQuery.push({
        locationTags: {
          tag: {
            $in: tf,
          },
        },
      })
    })

    const qs = require('qs')
    const query = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 10,
        },
        populate: {
          images: {
            populate: '*',
          },
          // Previously we hade the bolow as '*' but that became recursive, and we don't need to populate when we only want the fields
          // locationTags: {
          //   populate: 1,
          // },
          // facilities: {
          //   populate: 1,
          // },
          // directions: {
          //   populate: 1,
          // },
        },
        filters: {
          reviewed: true,
          $and: [
            {
              county: {
                $in: countyFilters,
              },
            },
            {
              title: {
                $in: titleFilters,
              },
            },
            ...tagFilterQuery,
          ],

          county: {
            $eq: county?.label,
          },
          locationTags: {
            tag: {
              $eq: tag?.label,
            },
          },
        },
        sort: 'publishedAt:desc',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    const queryWithExclusive = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 10,
        },
        populate: {
          images: {
            populate: '*',
          },
          // Previously we hade the bolow as '*' but that became recursive, and we don't need to populate when we only want the fields
          // locationTags: {
          //   populate: 1,
          // },
          // facilities: {
          //   populate: 1,
          // },
          // directions: {
          //   populate: 1,
          // },
        },
        filters: {
          reviewed: true,
          $and: [
            {
              county: {
                $in: countyFilters,
              },
            },
            {
              title: {
                $in: titleFilters,
              },
            },
            ...tagFilterQuery,
          ],

          county: {
            $eq: county?.label,
          },
          locationTags: {
            tag: {
              $eq: tag?.label,
            },
          },
          exclusive: {
            $eq: true,
          },
        },
        sort: 'publishedAt:desc',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(
        `${BASE_URL}/api/locations?${
          filterExclusive ? queryWithExclusive : query
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        setLocations(res.data.data)
        setTotal(res.data.meta.pagination.total)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setIsLoading(false)
      })
  }, 200)

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      pagination: {
        pageSize: county ? 4 : 2,
      },
      populate: {
        image: {
          populate: '*',
        },
      },
      filters: {
        county: {
          $eq: county?.label,
        },
      },
      encodeValuesOnly: true, // prettify URL
    })

    axios
      .get(`${BASE_URL}/api/creative-partners?${query}`)
      .then((res) => {
        setSuggestedPartners(
          res.data.data.map((d: IPartner) => {
            return {
              image: d.attributes.image.data[0].attributes?.url,
              header: d.attributes.partnerName,
              body: d.attributes.county,
              id: d.id,
            }
          })
        )
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [county])

  useEffect(() => {
    setPaginationButtons([])
    for (let i = 0; i < maxPages; i++) {
      setPaginationButtons((prev) => [
        ...prev,
        <PaginationButton
          key={i}
          onClick={() => {
            window.scroll(0, 0)
            setPage(i + 1)
            sessionStorage.setItem('locationPage', (i + 1).toString())
          }}
          className={page === i + 1 ? 'active' : ''}
          disabled={page === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [maxPages, page])

  const redirect = (id: string) => {
    history.push(`${NonAuthRoutes.creativePartners}/${id}`)
  }

  const updateFavorite = (locations: any) => {
    const favorites = {
      favoriteLocations: locations,
    }
    axios
      .put(`${BASE_URL}/api/users/${data?.id}`, favorites, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        getMe()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const toggleFavorite = (item: ILocationPage, isFavorite: boolean) => {
    if (isFavorite) {
      const data = removeFavorite(Number(item.id), favoriteLocations)
      updateFavorite(data)
    } else {
      const data = addFavorite(Number(item.id), favoriteLocations)
      updateFavorite(data)
    }
  }

  const NoOptions = () => {
    return (
      <NoResults>
        <S.FlexContainerColumn width="100%" style={{ alignItems: 'start' }}>
          <S.H5 color={colors.gray}>Hittade ingen match på din sökning</S.H5>
          <S.Paragraph color={colors.gray}>
            Skicka önskemål så hjälper vi dig leta
          </S.Paragraph>
        </S.FlexContainerColumn>
        <S.SmallSpacer />
        <S.SmallSpacerRow />
        <S.ButtonSecondary onClick={() => setOpen(true)}>
          Skicka önskemål
        </S.ButtonSecondary>
      </NoResults>
    )
  }

  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <SearchContainer>
          <S.ContentContainer>
            <S.FlexContainerRowAlignCeter width="100%">
              <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                  e.preventDefault()
                  getLocations()
                }}
              >
                <S.FlexContainerRowAlignCeter style={{ position: 'relative' }}>
                  <Icon
                    icon={IconType.Search}
                    style={{
                      position: 'absolute',
                      left: '8px',
                      width: '14px',
                      stroke: colors.gray,
                    }}
                  />
                  <SearchSelect
                    closeMenuOnSelect={false}
                    isMulti
                    noOptionsMessage={NoOptions}
                    placeholder="SÖK"
                    value={searchValue}
                    options={[
                      {
                        label: 'Sökord',
                        options: tags,
                      },
                      {
                        label: 'Län',
                        options: counties,
                      },
                      {
                        label: 'Titlar',
                        options: titles,
                      },
                    ]}
                    name="searchSelect"
                    onChange={(selectedTags) => {
                      const selectedTagsAsIOptions =
                        selectedTags as unknown as IOption[]
                      setSearchValue(selectedTagsAsIOptions)
                      getLocations()
                    }}
                  />
                </S.FlexContainerRowAlignCeter>
              </form>
              <S.InvisibleButton
                className="mobile"
                onClick={() => setFiltersOpen(true)}
              >
                <Icon icon={IconType.Filter} />
              </S.InvisibleButton>
              <S.FlexContainerRow className="desktop">
                <S.SmallSpacerRow />
                <SearchButton onClick={getLocations}>
                  <S.SmallSpacerRow />
                  Search
                  <S.SmallSpacerRow />
                </SearchButton>
                <S.ExtraLargeSpacerRow />
                <RelativeDropdown
                  noBorder
                  label={county?.label ?? 'LÄN'}
                  activeOption={county}
                  sortOptions={counties}
                  handleClick={setCounty}
                />
                <S.SmallSpacer />
                <S.SmallSpacerRow />
                <RelativeDropdown
                  noBorder
                  activeOption={tag}
                  label={tag?.label ?? 'Sökord'}
                  sortOptions={tags}
                  handleClick={setTag}
                />
                <S.SmallSpacerRow />
                <S.ExclusiveButton
                  onClick={() => setFilterExclusive(!filterExclusive)}
                  width="max-content"
                  height="54px"
                >
                  <input
                    style={{ width: '30px' }}
                    type="checkbox"
                    checked={filterExclusive}
                  />
                  <Icon icon={IconType.Tag} />
                  <S.ExtraSmallSpacerRow />
                  Exklusiva objekt
                  <S.ExtraSmallSpacerRow />
                </S.ExclusiveButton>
              </S.FlexContainerRow>
            </S.FlexContainerRowAlignCeter>
          </S.ContentContainer>
        </SearchContainer>
        <S.ContentContainer>
          {isLoading ? (
            <Spinner />
          ) : locations ? (
            <S.PaddingContainer>
              <S.SmallSpacer />
              <S.FlexContainerRowJustifyBetween>
                <S.H1>Locations</S.H1>
              </S.FlexContainerRowJustifyBetween>
              <S.MediumSpacer />

              {(searchValue.length > 0 || county || tag) && (
                <>
                  <S.FlexContainerRowAlignCeter style={{ flexWrap: 'wrap' }}>
                    <S.DetailsBig>Visar resultat för: </S.DetailsBig>{' '}
                    <S.SmallSpacerRow />
                    {searchValue.length > 0 && (
                      <>
                        {searchValue.map((v) => {
                          return (
                            <ResultContainer>
                              <S.DetailsSmall>{v.label}</S.DetailsSmall>
                            </ResultContainer>
                          )
                        })}
                      </>
                    )}
                    {county && (
                      <ResultContainer>
                        <S.DetailsSmall>{county.label}</S.DetailsSmall>
                      </ResultContainer>
                    )}
                    {tag && (
                      <ResultContainer>
                        <S.DetailsSmall>{tag.label}</S.DetailsSmall>
                      </ResultContainer>
                    )}
                  </S.FlexContainerRowAlignCeter>
                  <S.MediumSpacer />
                </>
              )}

              <S.MediumSpacer />
              {showAll && locations ? (
                <>
                  <ImageContainer className="imageContainer">
                    {locations.map((l, i) => {
                      const f = favoriteLocations?.some(
                        (f) => f.id === Number(l.id)
                      )
                      return (
                        <AllLocations
                          key={i}
                          l={l}
                          isFavorite={f ?? false}
                          toggleFavorite={toggleFavorite}
                        />
                      )
                    })}
                  </ImageContainer>
                </>
              ) : (
                <PreviewLocations />
              )}
              {filtersOpen && (
                <FilterContainer className="mobile">
                  <S.PaddingContainer>
                    <S.SmallSpacer />
                    <S.FlexContainerRowJustifyEnd>
                      <S.InvisibleButton onClick={() => setFiltersOpen(false)}>
                        <Icon icon={IconType.Close} />
                      </S.InvisibleButton>
                    </S.FlexContainerRowJustifyEnd>
                    <S.MediumSpacer />
                    <S.ExclusiveButton
                      onClick={() => setFilterExclusive(!filterExclusive)}
                    >
                      <input type="checkbox" checked={filterExclusive} />
                      <S.SmallSpacerRow />
                      <Icon icon={IconType.Tag} />
                      <S.ExtraSmallSpacerRow />
                      visa exklusiva objekt
                    </S.ExclusiveButton>
                    <S.SmallSpacer />
                    <RelativeDropdown
                      width="100%"
                      noBorder
                      label={county?.label ?? 'LÄN'}
                      sortOptions={counties}
                      activeOption={county}
                      handleClick={setCounty}
                    />
                    <S.SmallSpacer />
                    <RelativeDropdown
                      width="100%"
                      label={tag?.label ?? 'Sökord'}
                      sortOptions={tags}
                      activeOption={tag}
                      handleClick={setTag}
                    />
                  </S.PaddingContainer>
                </FilterContainer>
              )}
              {showAll && total > 10 && (
                <S.FlexContainerRowCenterCenter>
                  {paginationButtons}
                </S.FlexContainerRowCenterCenter>
              )}
              <S.VerticalMargin />
              <TextButtonCTA
                blue
                reverse
                text={'Hittar du inte det du söker?'}
                buttonText={'Skicka önskemål'}
                request={'location'}
              />
              <S.VerticalMargin />
              {suggestedPartners && suggestedPartners.length > 0 && (
                <ImageSection
                  fullWidthImages
                  handleClick={redirect}
                  title={
                    !county
                      ? 'Creative partners'
                      : `Fler creative partners i ${county.label}`
                  }
                  link={NonAuthRoutes.creativePartners}
                  content={suggestedPartners}
                />
              )}
              <S.VerticalMargin />
            </S.PaddingContainer>
          ) : null}
        </S.ContentContainer>
      </S.Wrapper>

      {open && (
        <RequestModal subject={'location'} closeModal={() => setOpen(false)} />
      )}
    </>
  )
}
