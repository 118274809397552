import styled from 'styled-components'
import { colors } from './core'

interface IProps {
  background?: string
  width?: string
  height?: string
  maxWidth?: string
}

export const Main = styled.div`
  padding-top: 42px;
  width: 100vw;
  min-height: 100vh;

  @media screen and (min-width: 1200px) {
    padding-top: 114px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`
export const ScreenSpacer = styled.div`
  height: 100vh;
`

export const ContentContainer = styled.div<IProps>`
  margin: 0 16px;
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? '1440px'};

  @media screen and (min-width: 1200px) {
    margin: 0;
    width: calc(100vw - 128px);
  }
`

export const ArticleContainer = styled.article<IProps>`
  margin: 0 16px;
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? '1440px'};

  @media screen and (min-width: 1200px) {
    margin: 0;
    width: calc(100vw - 128px);
  }
`

export const PaddingContainer = styled.div<IProps>`
  padding: 0 16px;
  width: ${(props) => props.width ?? '100%'};

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
`
export const NoMarginContainer = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
`

export const VerticalMargin = styled.div`
  width: 100%;
  margin: 48px 0;

  @media screen and (min-width: 1200px) {
    margin: 72px 0;
  }
`

export const Underline = styled.div<IProps>`
  width: ${(props) => props.width ?? '100%'};
  height: 0.5px;
  margin: 16px 0;
  background-color: ${(props) => props.color ?? colors.gray};

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
`

export const TitleTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 32px);
  max-width: 1440px;
  padding-bottom: 12px;

  @media screen and (min-width: 1200px) {
    width: calc(100vw - 128px);
    padding-bottom: 32px;
  }
`

export const Spacer = styled.div<IProps>`
  width: ${(props) => props.width ?? 0};
  min-width: ${(props) => props.width ?? 0};
  height: ${(props) => props.height ?? 0};
`

export const ExtraSmallSpacer = styled.div`
  height: 8px;
`
export const SmallSpacer = styled.div`
  height: 16px;
`
export const MediumSpacer = styled.div`
  height: 24px;
  width: 24px;
`
export const LargeSpacer = styled.div`
  height: 32px;
`
export const ExtraLargeSpacer = styled.div`
  height: 56px;
`
export const ExtraSmallSpacerRow = styled.div`
  width: 8px;
`
export const SmallSpacerRow = styled.div`
  width: 16px;
`
export const MediumSpacerRow = styled.div`
  width: 24px;
`
export const LargeSpacerRow = styled.div`
  width: 32px;
`
export const ExtraLargeSpacerRow = styled.div`
  width: 48px;
`
