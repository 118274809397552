import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { Nav } from './Nav'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

const SortButton = styled(S.Button)`
  border: 1px solid ${colors.gray};
  color: ${colors.gray};
  width: ${(props) => props.width ?? 'calc(50% - 4px)'};
  position: relative;
  z-index: 9;
  .dropDown {
    display: none;
  }
  &.open {
    svg  {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
    .dropDown {
      display: unset;
    }
  }
  @media screen and (min-width: 800px) {
    width: 195px;
    max-width: 50%;
    min-width: max-content;
    margin-left: 16px;
  }
`

export const SortBackground = styled(S.FlexContainerRowCenterCenter)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const SortOption = styled(S.TextButton)`
  margin: 8px 0;
  color: ${colors.gray};
  text-align: left;
  &.active {
    color: ${colors.yellow};
  }
`
export const SortDropDown = styled(S.FlexContainerColumn)`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  color: ${colors.gray};
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  width: ${(props) => props.width ?? 'max-content'};
  padding: 23px;
  z-index: 15;
  height: min-content;
  cursor: default;
  &.noBorder {
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      text-align: center;
    }
  }
`

export interface IOption {
  label: string
  value: string
  type?: string
}

interface IProps {
  label: string
  sortOptions: IOption[]
  activeOption?: IOption
  handleClick: (value: IOption) => void
  width?: string
  noBorder?: boolean
}

export const Dropdown: React.FC<IProps> = (props) => {
  const [openSort, setOpenSort] = useState<string | undefined>()

  const renderSortOption = (option: IOption) => {
    let isActive = option === props.activeOption
    return (
      <SortOption
        key={option.value}
        className={isActive ? 'active' : ''}
        onClick={() => {
          props.handleClick(option)
          setOpenSort(undefined)
        }}
      >
        {option.label}
      </SortOption>
    )
  }

  return (
    <>
      <SortButton
        width={props.width}
        onClick={() => setOpenSort(!openSort ? 'sort' : undefined)}
        className={openSort === 'sort' ? 'open' : ''}
      >
        {props.label}
        <S.ExtraSmallSpacerRow />
        <Icon icon={IconType.ChevronDown} />
        <SortDropDown
          width={props.width}
          onClick={(e) => e.stopPropagation()}
          className={`dropDown ${props.noBorder ? 'noBorder' : ''}`}
        >
          {props.sortOptions.map((o) => {
            return renderSortOption(o)
          })}
        </SortDropDown>
      </SortButton>
      {openSort && <SortBackground onClick={() => setOpenSort(undefined)} />}
    </>
  )
}
