import styled from 'styled-components'
import { colors } from '../core'

export const InputField = styled.input`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  height: 38px;
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  height: 54px;

  @media screen and (min-width: 1200px) {
    height: 46px;
    display: block;
    align-items: flex-start;
  }
`
