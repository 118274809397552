import axios from 'axios'
import React, { useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import { BASE_URL } from '../../utils/config'
import { FormInput } from '../FormInput'
import { ResetPassword } from './ResetPassword'

interface IProps {
  sent: () => void
}

export const ChangePassword: React.FC<IProps> = (props) => {
  const [reset, setReset] = useState(false)
  const [forgot, setForgot] = useState(false)
  const [current, setCurrent] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNew, setEsetConfirmNew] = useState('')
  const [error, setError] = useState('')
  const [errorArray, setErrorArray] = useState<
    { path: string; message: string }[]
  >([])

  const changePasswordRequest = () => {
    const token = localStorage.getItem('token')

    axios
      .post(
        `${BASE_URL}/api/auth/change-password`,
        {
          currentPassword: current,
          password: newPassword,
          passwordConfirmation: confirmNew,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setReset(true)
      })
      .catch((err) => {
        setError(err.response.data.error.message)
        setErrorArray(err.response.data.error.details.errors)
      })
  }

  if (forgot) {
    return (
      <ResetPassword
        sent={() => {
          props.sent()
          setForgot(false)
        }}
      />
    )
  }

  return (
    <S.FlexContainerColumnJustifyCenter>
      <S.H3>Change password</S.H3>
      <S.MediumSpacer />
      {!reset ? (
        <>
          <S.ParagraphSmall>
            Please enter your current and a new password.
          </S.ParagraphSmall>
          <S.MediumSpacer />
          <FormInput
            label="Nuvarande lösenord *"
            error={
              errorArray?.length > 0
                ? errorArray?.find((e) => e.path.includes('currentPassword'))
                  ? true
                  : false
                : error.includes('current')
            }
          >
            <S.InputField
              value={current}
              type={'password'}
              onChange={(e) => setCurrent(e.target.value)}
              placeholder="NUVARANDE LÖSENORD"
            />
          </FormInput>
          <FormInput
            label="Ange nytt lösenord *"
            error={
              errorArray?.find((e) => e.path.includes('password'))
                ? true
                : false
            }
          >
            <S.InputField
              value={newPassword}
              type={'password'}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="NYTT LÖSENORD"
            />
          </FormInput>
          <FormInput
            label="Bekräfta nytt lösenord *"
            error={
              errorArray?.find((e) => e.path.includes('passwordConfirmation'))
                ? true
                : false
            }
          >
            <S.InputField
              value={confirmNew}
              type={'password'}
              onChange={(e) => setEsetConfirmNew(e.target.value)}
              placeholder="BEKRÄFTA NYTT LÖSENORD"
            />
          </FormInput>
          {errorArray ? (
            errorArray.map((e) => {
              return (
                <S.Paragraph color={colors.orange}>{e.message}</S.Paragraph>
              )
            })
          ) : (
            <S.Paragraph color={colors.orange}>{error}</S.Paragraph>
          )}
          <S.ExtraSmallSpacer />
          <S.FlexContainerRowJustifyEnd>
            <S.TextButton
              margin="12"
              onClick={() => setForgot(true)}
              color={colors.gray}
            >
              Forgot password
            </S.TextButton>
          </S.FlexContainerRowJustifyEnd>
          <S.ExtraLargeSpacer />
          <S.ModalButtonPrimary
            onClick={() => {
              changePasswordRequest()
            }}
          >
            RESET
          </S.ModalButtonPrimary>
        </>
      ) : (
        <>
          <S.ParagraphSmall>Your password has been changed!</S.ParagraphSmall>
          <S.ExtraLargeSpacer />
          <S.ModalButtonPrimary
            onClick={() => {
              props.sent()
            }}
          >
            CLOSE
          </S.ModalButtonPrimary>
        </>
      )}
    </S.FlexContainerColumnJustifyCenter>
  )
}
