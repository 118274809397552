import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  IFacility,
  ILocationInfo,
  ILocationTag,
} from '../../pages/Client/CreateLocation'
import {
  Image,
  Image1,
  Image2,
  ImageButtons,
  ImageContainer,
} from '../../pages/CreativePartners/components/CreativePartner'
import { ILocation } from '../../types'
import * as S from '../../ui'
import { colors } from '../../ui'
import { Icon, IconType } from '../../ui/assets/icons'
import { BASE_URL } from '../../utils/config'
import { FavoritesButton } from '../FavoritesButton'
import { FullscreenImages } from '../FullscreenImages'
import { Background, ModalContainer, ModalContent } from './Modal'
import { Spinner } from '../../components/Spinner'

const ModalWrapper = styled(S.FlexContainerColumn)`
  position: relative;

  .closeButton,
  .imageButton {
    margin: auto;
    margin-right: 0;
  }
`

const Container = styled.div`
  padding: 8px;
  @media screen and (min-width: 800px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 50px;
  }
  @media screen and (min-width: 1200px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 0 150px;
  }
`

const TagContainer = styled(S.FlexContainerRowAlignCeter)`
  align-items: flex-start;
  height: min-content;
  flex-wrap: wrap;
  @media screen and (min-width: 800px) {
    padding-top: 24px;
  }
`
const Tag = styled.div`
  background-color: ${colors.black};
  width: max-content;
  padding: 8px;
  margin: 4px;
`

const InformationContainer = styled(S.FlexContainerColumnCenterCenter)`
  background-color: ${colors.orange};
  padding: 50px 0;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 150px;
  }
`

const InfoBlock = styled(S.FlexContainerColumnCenterCenter)`
  @media screen and (min-width: 1200px) {
    align-items: unset;
    justify-content: unset;
  }
`
const Underline = styled.div`
  margin-top: 8px;
  background-color: ${colors.white};
  width: calc(100% + 32px);
  height: 1px;
`

export const Default = styled.img`
  height: 400px;
  width: 400px;
  object-fit: cover;
  object-position: center;
  background-color: ${colors.pink};
  border: none;
`

export const Default2 = styled.img`
  height: 300px;
  width: 400px;
  object-fit: cover;
  object-position: center;
  background-color: ${colors.pink};
  border: none;
`

export interface IPreviewLocation {
  address: string
  county: string
  zip: string
  description: string
  title: string
  squareMeters?: number
  rooms: number
  exclusive: boolean
  images: {
    data: {
      attributes: {
        url: string
      }
    }[]
  }
  directions: number[]
  facilities: number[]
  locationTags: number[]
}

export interface IDirection {
  id: string
  attributes: {
    direction: string
  }
}

interface IProps {
  closeModal: () => void
  location: ILocationInfo
  previewFacilities?: string[]
  imageUrls?: string[]
}
export const PreviewLocationModal: React.FC<IProps> = (props) => {
  const [showImages, setShowImages] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [facilities, setFacilities] = useState<IFacility[]>()
  const [tags, setTags] = useState<ILocationTag[]>()
  const [directions, setDirections] = useState<IDirection[]>()

  // console.log(props, 'props preview modal')

  useEffect(() => {
    const token = localStorage.getItem('token')
    setIsLoading(true)
    async function fetchData() {
      await axios
        .get(`${BASE_URL}/api/facilities?populate=icon`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFacilities(res.data.data)
        })

      await axios
        .get(`${BASE_URL}/api/location-tags`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTags(res.data.data)
        })

      await axios
        .get(`${BASE_URL}/api/directions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDirections(res.data.data)
        })
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const renderInfoContainer = (
    top: { title?: string; value?: string },
    bottom: { title?: string; value?: string }
  ) => {
    return (
      <InfoBlock>
        <S.FlexContainerRowAlignCeter>
          {top.title && (
            <>
              <S.H5 color={colors.white}>{top.title}</S.H5>
              {top.value && <S.SmallSpacerRow />}
            </>
          )}
          <S.ParagraphSmall color={colors.white}>{top.value}</S.ParagraphSmall>
        </S.FlexContainerRowAlignCeter>
        <S.ExtraSmallSpacer />
        <Underline />
        <S.SmallSpacer />
        <S.FlexContainerRowAlignCeter>
          {bottom.title && (
            <>
              <S.H5 color={colors.white}>{bottom.title}</S.H5>
              <S.SmallSpacerRow />
            </>
          )}
          <S.ParagraphSmall color={colors.white}>
            {bottom.value}
          </S.ParagraphSmall>
        </S.FlexContainerRowAlignCeter>
      </InfoBlock>
    )
  }

  interface ITagWithData {
    data: ILocationTag[]
  }

  interface IDirectionWithData {
    data: IDirection[]
  }

  const myTags = props.location.locationTags as unknown as ITagWithData
  const myDirections = props.location
    .directions as unknown as IDirectionWithData

  if (!props.location) return <></>

  return (
    <Background onClick={props.closeModal}>
      <ModalWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <S.InvisibleButton
              className="closeButton"
              onClick={props.closeModal}
            >
              <Icon color={colors.white} icon={IconType.Close} />
            </S.InvisibleButton>
            <S.SmallSpacer />
            <ModalContainer
              padding="0"
              onClick={(e) => e.stopPropagation()}
              scroll
            >
              <ModalContent height="auto">
                <ImageContainer>
                  {props.imageUrls && props.imageUrls[0] ? (
                    <Image1>
                      <Image
                        src={props.imageUrls && props.imageUrls[0]}
                        alt="Location image 1"
                      />
                    </Image1>
                  ) : (
                    <Image1>
                      <Default />
                    </Image1>
                  )}
                  <S.FlexContainerColumn>
                    {props.imageUrls && props.imageUrls[1] ? (
                      <Image2>
                        <Image
                          src={props.imageUrls && props.imageUrls[1]}
                          alt="Location image 2"
                        />
                      </Image2>
                    ) : (
                      <Image2>
                        <Default2 />
                      </Image2>
                    )}
                    <S.ExtraSmallSpacer />
                    {props.imageUrls && props.imageUrls[2] ? (
                      <Image2>
                        <Image
                          src={props.imageUrls && props.imageUrls[2]}
                          alt="Location image 3"
                        />
                      </Image2>
                    ) : (
                      <Image2>
                        <Default2 />
                      </Image2>
                    )}
                  </S.FlexContainerColumn>
                  {props.imageUrls && (
                    <ImageButtons>
                      <S.WhiteButton
                        className="imageButton"
                        onClick={() => {
                          setShowImages(true)
                        }}
                      >
                        Se alla bilder
                      </S.WhiteButton>
                    </ImageButtons>
                  )}
                </ImageContainer>

                <Container>
                  <S.FlexContainerColumn width="100%">
                    <S.MediumSpacer />
                    <S.H1>{props.location.title}</S.H1>
                    <S.ExtraSmallSpacer />
                    <S.DetailsBig>{props.location.county}</S.DetailsBig>
                    <S.MediumSpacer />
                    <S.Paragraph>{props.location.description}</S.Paragraph>
                    <S.ExtraLargeSpacer />
                  </S.FlexContainerColumn>

                  <S.FlexContainerColumn>
                    <TagContainer>
                      {myTags && myTags.data
                        ? myTags.data.map((d, i) => {
                            if (!tags) {
                              return <div key={i}></div>
                            } else {
                              const tag = tags.find((t) => {
                                return t.id === d.id
                              })
                              return (
                                <Tag key={tag?.id}>
                                  <S.DetailsBig color={colors.white}>
                                    {tag?.attributes.tag}
                                  </S.DetailsBig>
                                </Tag>
                              )
                            }
                          })
                        : props.location.locationTags?.map((d, i) => {
                            if (!tags) {
                              return <div key={i}></div>
                            } else {
                              const tag = tags.find((t) => {
                                return t.attributes.tag === d.toString()
                              })

                              return (
                                <Tag key={tag?.id}>
                                  <S.DetailsBig color={colors.white}>
                                    {tag?.attributes.tag}
                                  </S.DetailsBig>
                                </Tag>
                              )
                            }
                          })}
                    </TagContainer>
                    {props.location.exclusive && (
                      <S.ExclusiveButton
                        margin="4px 0 0 4px"
                        width="max-content"
                      >
                        <Icon icon={IconType.Tag} />
                        <S.ExtraSmallSpacerRow />
                        exklusivt objekt
                      </S.ExclusiveButton>
                    )}
                    <S.ExtraLargeSpacer />

                    {facilities &&
                      props.previewFacilities &&
                      props.previewFacilities.length > 0 && (
                        <>
                          <S.H4>Faciliteter</S.H4>
                          <S.LargeSpacer />
                          <S.FlexContainerColumn>
                            {props.previewFacilities?.map((d) => {
                              const facility = facilities.find((f) => {
                                return f.id === parseInt(d)
                              })
                              return (
                                <div key={facility?.id}>
                                  <S.FlexContainerRowAlignCeter>
                                    <img
                                      height={25}
                                      src={
                                        facility?.attributes.icon.data
                                          .attributes.url
                                      }
                                      alt={
                                        facility?.attributes.icon.data
                                          .attributes.alternativeText
                                      }
                                    />
                                    <S.MediumSpacerRow />
                                    <S.DetailsSmall>
                                      {facility?.attributes.facility}
                                    </S.DetailsSmall>
                                  </S.FlexContainerRowAlignCeter>
                                  <S.LargeSpacer />
                                </div>
                              )
                            })}
                          </S.FlexContainerColumn>
                        </>
                      )}
                  </S.FlexContainerColumn>
                </Container>
                <S.VerticalMargin />
                <InformationContainer>
                  {renderInfoContainer(
                    { title: props.location.title, value: undefined },
                    { title: undefined, value: props.location.county }
                  )}
                  <S.ExtraLargeSpacer />
                  {renderInfoContainer(
                    { title: 'KVM', value: props.location.squareMeters + 'M2' },
                    {
                      title: 'RUM',
                      value: props.location.rooms + ' RUM & KÖK',
                    }
                  )}
                  <S.ExtraLargeSpacer />
                  {renderInfoContainer(
                    {
                      title: 'EXKLUSIVT OBJEKT',
                      value: props.location.exclusive ? 'JA' : 'NEJ',
                    },
                    {
                      title: 'VÄDERSTRECK',
                      value:
                        myDirections && myDirections.data
                          ? myDirections.data
                              .map((d) => {
                                const direction = directions?.find((x) => {
                                  return x.id == d.id
                                })
                                return ' ' + direction?.attributes.direction
                              })
                              .toString()
                          : props.location.directions
                              ?.map((d) => {
                                const direction = directions?.find((x) => {
                                  return x.id == d.toString()
                                })
                                return ' ' + direction?.attributes.direction
                              })
                              .toString(),
                    }
                  )}
                </InformationContainer>
                {showImages && (
                  <FullscreenImages
                    close={() => setShowImages(false)}
                    urls={props.imageUrls}
                  />
                )}
              </ModalContent>
            </ModalContainer>
          </>
        )}
      </ModalWrapper>
    </Background>
  )
}
