import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import { AccountInfo } from './components/AccountInfo'

interface IProfileInfo {
  name: string
  company?: string
  orgNumber?: string
  email: string
  phoneNumber: string
  invoiceAddress: string
  type: string
  zipCode: string
  country: string
  postalArea: string
  id: string
}

export const CompanyAccount: React.FC = () => {
  return (
    <>
      <AccountInfo />
    </>
  )
}
