import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import styled from 'styled-components'
import { Modal } from './Modal'
import { Input } from '../Input'
import { TextArea } from '../TextArea'
import { useHistory, useLocation } from 'react-router-dom'
import { NonAuthRoutes } from '../../types'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { useGetMe } from '../../utils/useGetMe'
import { Icon, IconType } from '../../ui/assets/icons'

interface IProps {
  closeModal: () => void
}

export const ContactModal: React.FC<IProps> = (props) => {
  const history = useHistory()
  const user = useGetMe()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (user) {
      setEmail(user.email)
    }
  }, [user])

  const sendEmail = async () => {
    axios
      .post(`${BASE_URL}/api/contact-form`, {
        userEmail: email,
        userId: user?.id,
        message: message,
      })
      .then(() => setSuccess(true))
      .catch((err) => console.log(err))
  }

  return (
    <Modal closeModal={props.closeModal}>
      <S.FlexContainerColumnJustifyBetween>
        {success ? (
          <>
            <S.FlexContainerRowAlignCeter>
              <S.H3>Thank you</S.H3>
              <S.SmallSpacerRow />
              <Icon icon={IconType.Heart} />
            </S.FlexContainerRowAlignCeter>
            <S.SmallSpacer />
            <S.Paragraph>
              Vi svarar så snart vi kan, håll ett öga på din inkorg!
            </S.Paragraph>
            <S.ExtraLargeSpacer />
            <S.ModalButtonPrimary
              onClick={() => {
                setSuccess(false)
                props.closeModal()
              }}
            >
              OKEJ
            </S.ModalButtonPrimary>
          </>
        ) : (
          <>
            <S.H3>Contact us</S.H3>
            <S.SmallSpacer />
            <S.ParagraphSmall>
              You can find answers to frequently asked questions on our{' '}
              <S.TextButton
                onClick={() => {
                  history.push(NonAuthRoutes.faq)
                  props.closeModal()
                  window.scroll(0, 0)
                }}
                display="inline"
                padding="0"
                margin="0"
              >
                FAQ PAGE
              </S.TextButton>
            </S.ParagraphSmall>
            <S.MediumSpacer />
            <Input
              label="Email"
              type="email"
              placeholder="ENTER YOUR EMAIL ADRESS"
              value={email}
              setValue={setEmail}
              border
            />
            <S.ExtraSmallSpacer />
            <TextArea
              label="Meddelande"
              placeholder={`WRITE YOUR MESSAGE HERE`}
              value={message}
              setValue={setMessage}
            />
            <S.LargeSpacer />
            <S.ModalButtonPrimary onClick={sendEmail}>
              SEND MESSAGE
            </S.ModalButtonPrimary>
          </>
        )}
      </S.FlexContainerColumnJustifyBetween>
    </Modal>
  )
}
