import React from 'react'
import ReactSelect, { Props, GroupBase } from 'react-select'
import { colors } from '../ui'

export function SearchSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return (
    <>
      <ReactSelect
        {...props}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        styles={{
          container: (provided) => ({
            ...provided,
            border: `1px solid ${colors.gray}`,
            borderRadius: '1px',
            width: '100%',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.02em',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingLeft: '24px',
            margin: '6px 0',
            '@media only screen and (min-width: 1200px)': {
              minHeight: '54px',
            },
          }),

          option: (provided, state) => ({
            ...provided,
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: colors.black,
            letterSpacing: '0.02em',
            cursor: 'pointer',
          }),
          menu: (provided) => ({
            ...provided,
            width: '100%',
            left: '0',
            padding: '16px',
            backgroundColor: colors.white,
            border: `1px solid ${colors.gray}`,
            textTransform: 'uppercase',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.02em',
          }),
          multiValue: (state) => ({
            textTransform: 'uppercase',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            background: 'black',
            color: 'white',
            letterSpacing: '0.02em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px',
            borderRadius: '1px',
            margin: '0 6px 6px 0',
            position: 'relative',
            top: '4px',
          }),
          multiValueLabel: (state) => ({
            color: 'white',
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
          }),

          control: (provided) => ({
            ...provided,
            width: '100%',
            minHeight: '100%',
            height: 'max-content',
            borderRadius: '8px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.02em',
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }),

          valueContainer: (provided) => ({
            ...provided,
            padding: '4px 8px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          indicatorsContainer: () => ({
            display: 'none',
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1
            const transition = 'opacity 300ms'

            return { ...provided, opacity, transition }
          },
        }}
      />
    </>
  )
}
