import React, { useState } from 'react'
import * as S from '../../../../../ui'
import styled from 'styled-components'
import { colors } from '../../../../../ui/core'
import { useHistory } from 'react-router-dom'
import {
  AuthRoutes,
  ICreativePartner,
  ILocation,
  NonAuthRoutes,
} from '../../../../../types'
import { Icon, IconType } from '../../../../../ui/assets/icons'

const CTAContainer = styled(S.NoMarginContainer)`
  background-color: ${colors.pink};
  height: calc(100vh - 42px);
  flex-direction: column;

  @media screen and (min-width: 1440px) {
    flex-direction: row;
  }
`
export const ImageContainer = styled(S.ContentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 260px;
  gap: 32px;
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-auto-rows: 400px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1200px) {
    grid-auto-rows: 460px;
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Card = styled(S.FlexContainerColumn)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  align-items: flex-start;
`

export const Image = styled.img`
  height: calc(100% - 60px);
  width: 100%;
  object-fit: cover;
  object-position: center;
`

export const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.pink};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  width: 100%;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
`
export const Btn = styled.button`
  width: 25px;
  padding: 0px;
  border: unset;
  cursor: pointer;
  background-color: unset;
`

interface IProps {
  location?: ILocation
  creativePartner?: ICreativePartner
  handleLocations?: (locationId: number | undefined) => void
  handleCreativePartners?: (creativePartnerId: number | undefined) => void
}

export const ImageGallery: React.FC<IProps> = (props) => {
  const history = useHistory()

  const redirectLocations = (id?: number) => {
    history.push(`${AuthRoutes.locations}/${id}`)
  }
  const redirectCreativePartners = (id?: number) => {
    history.push(`${NonAuthRoutes.creativePartners}/${id}`)
  }

  return (
    <>
      {props.location && (
        <Card
          key={props.location?.id}
          style={{ cursor: 'pointer' }}
          onClick={() => redirectLocations(props.location?.id)}
        >
          {props.location?.images ? (
            <Image src={props.location?.images[0].url} />
          ) : (
            <Placeholder />
          )}
          <InfoContainer>
            <InnerContainer>
              <S.DetailsSmall>
                {props.location?.title ? props.location.title : '-'}
              </S.DetailsSmall>
              <S.DetailsSmall color={colors.gray}>
                {props.location?.county ? props.location?.county : '-'}
              </S.DetailsSmall>
            </InnerContainer>
            <Btn
              onClick={(e) => {
                e.stopPropagation()
                props.handleLocations &&
                  props.handleLocations(props.location?.id)
              }}
            >
              <Icon icon={IconType.Bookmark} fill={'black'} stroke={'black'} />
            </Btn>
          </InfoContainer>
        </Card>
      )}
      {props.creativePartner && (
        <Card
          key={props.creativePartner.id}
          style={{ cursor: 'pointer' }}
          onClick={() => redirectCreativePartners(props.creativePartner?.id)}
        >
          {props.creativePartner.image ? (
            <Image src={props.creativePartner.image[0].url} />
          ) : (
            <Placeholder />
          )}
          <InfoContainer>
            <InnerContainer>
              <S.DetailsSmall>
                {props.creativePartner.partnerName
                  ? props.creativePartner.partnerName
                  : '-'}
              </S.DetailsSmall>
              <S.DetailsSmall color={colors.gray}>
                {props.creativePartner.county
                  ? props.creativePartner.county
                  : '-'}
              </S.DetailsSmall>
            </InnerContainer>
            <Btn
              onClick={(e) => {
                e.stopPropagation()
                props.handleCreativePartners &&
                  props.handleCreativePartners(props.location?.id)
              }}
            >
              <Icon icon={IconType.Bookmark} fill={'black'} stroke={'black'} />
            </Btn>
          </InfoContainer>
        </Card>
      )}
    </>
  )
}
