import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AuthRoutes } from '../types'
import * as S from '../ui'
import { Icon, IconType } from '../ui/assets/icons'
import { colors } from '../ui/core'
import { signOut } from '../utils/signOut'
import { ContactModal } from './modals/ContactModal'
import { SignInModal } from './modals/SignInModal'

const NavIcon = styled.button`
  height: 32px;
  width: 32px;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: ${colors.black};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 7px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 15px;
  }

  span:nth-child(4) {
    top: 23px;
  }

  &.open span:nth-child(1) {
    opacity: 0;
  }

  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    opacity: 0;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const DropDown = styled.div`
  background-color: ${colors.white};
  position: fixed;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;

  &.unverified {
    top: 80px;
  }
`
const NavBar = styled.div`
  display: none;

  @media screen and (min-width: 1200px) {
    background-color: ${colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-right: 0;
    width: max-content;
  }
`

interface IProps {
  open?: string
  links?: { title: string; link: string }[]
  accountLinks?: { title: string; link: string }[]
  userType?: string
  isSignedIn?: boolean
  hasVerifiedBanner?: boolean
}

export const SmallNav: React.FC<IProps> = (props) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [sections, setSections] = useState<NodeListOf<HTMLElement>>()
  const [navItems, setNavItems] = useState<NodeListOf<HTMLElement>>()
  const [signInModal, setSignInModal] = useState(false)
  const [showAccountLinks, setShowAccountLinks] = useState(false)

  useEffect(() => {
    setSections(document.querySelectorAll('section'))
    setNavItems(document.querySelectorAll('nav .navlink'))
  }, [])

  window.onscroll = () => {
    var current: string | null = ''

    sections?.forEach((section) => {
      const sectionTop = section.offsetTop
      // eslint-disable-next-line no-restricted-globals
      if (scrollY >= sectionTop - 49) {
        current = section.getAttribute('id')
      }
    })

    navItems?.forEach((li) => {
      li.classList.remove('active')
      if (current && li.classList.contains(current)) {
        li.classList.add('active')
      }
    })
  }

  const renderNavLinks = () => {
    return props.links!.map((link, i) => {
      return (
        <S.NavLink
          className="navlink"
          smooth
          to={`${link.link}`}
          onClick={() => setOpen(false)}
          key={link.title + i}
        >
          {link.title.toUpperCase()}
        </S.NavLink>
      )
    })
  }

  useEffect(() => {
    if (
      location.pathname === AuthRoutes.account ||
      location.pathname === AuthRoutes.favorites ||
      location.pathname === AuthRoutes.customerAccount ||
      location.pathname === AuthRoutes.customerLocations
    ) {
      setShowAccountLinks(true)
    }
  }, [location.pathname])

  const renderAccountLinks = () => {
    if (showAccountLinks) {
      return props.accountLinks!.map((link, i) => {
        return (
          <S.NavLink
            className="navlink"
            smooth
            to={`${link.link}`}
            onClick={() => setOpen(false)}
            key={link.title + i}
          >
            {link.title.toUpperCase()}
          </S.NavLink>
        )
      })
    }
  }

  const renderSignOut = () => {
    return (
      <S.StyledLinkButton
        padding="16px"
        onClick={() => {
          signOut(true)
        }}
      >
        SIGN OUT
      </S.StyledLinkButton>
    )
  }

  const renderSingInLink = () => {
    if (props.isSignedIn && props.userType === 'company') {
      return (
        <S.NavLink
          className="navlink"
          to={AuthRoutes.account}
          onClick={() => setOpen(false)}
        >
          MY ACCOUNT
        </S.NavLink>
      )
    }
    if (props.isSignedIn && props.userType === 'customer') {
      return (
        <S.NavLink
          className="navlink"
          to={AuthRoutes.customerAccount}
          onClick={() => setOpen(false)}
        >
          MY ACCOUNT
        </S.NavLink>
      )
    }
    if (!props.isSignedIn) {
      return (
        <S.NavLink
          className="navlink"
          to={location.pathname}
          onClick={() => {
            setOpen(false)
            setSignInModal(true)
          }}
        >
          SIGN IN
        </S.NavLink>
      )
    }
  }

  return (
    <>
      <nav className="SmallNav">
        <NavIcon onClick={() => setOpen(!open)} className={open ? 'open' : ''}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </NavIcon>
        {open && (
          <DropDown className={props.hasVerifiedBanner ? 'unverified' : ''}>
            {props.links && <>{renderNavLinks()}</>}

            <S.NavLink
              className="navlink"
              to={location.pathname}
              onClick={() => {
                setOpen(false)
                setShowContactModal(true)
              }}
            >
              CONTACT
            </S.NavLink>
            {renderSingInLink()}
            {showAccountLinks && <S.Underline />}
            {props.accountLinks && showAccountLinks && (
              <>{renderAccountLinks()}</>
            )}
            {showAccountLinks && <>{renderSignOut()}</>}
          </DropDown>
        )}
        <NavBar>
          <div>
            {props.links && renderNavLinks()}
            <S.NavLink
              className="navlink"
              to={location.pathname}
              onClick={() => {
                setOpen(false)
                setShowContactModal(true)
              }}
            >
              CONTACT
            </S.NavLink>
            {renderSingInLink()}
          </div>
        </NavBar>
      </nav>
      {showContactModal && (
        <ContactModal closeModal={() => setShowContactModal(false)} />
      )}
      {signInModal && <SignInModal closeModal={() => setSignInModal(false)} />}
    </>
  )
}
