import React from 'react'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

interface IProps {
  message: string
}

export const FormErrorMessage: React.FC<IProps> = (props) => (
  <>
    <S.FlexContainerRowAlignCeter>
      <Icon icon={IconType.Error} />
      <S.ExtraSmallSpacerRow />
      <S.ParagraphXSmall color={colors.orange}>
        {props.message}
      </S.ParagraphXSmall>
    </S.FlexContainerRowAlignCeter>
    <S.SmallSpacer />
  </>
)
