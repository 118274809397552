import React from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

interface IProps {
  label?: string
  error?: boolean
  icon?: IconType
}
export const FormInput: React.FC<IProps> = (props) => {
  return (
    <S.FlexContainerColumn width="100%" position="relative">
      {props.label && (
        <>
          <S.FlexContainerRowAlignCeter>
            {props.error && (
              <>
                <Icon icon={IconType.Error} />
                <S.ExtraSmallSpacerRow />
              </>
            )}
            <S.Paragraph color={props.error ? colors.orange : colors.black}>
              {props.label}
            </S.Paragraph>
          </S.FlexContainerRowAlignCeter>
          <S.ExtraSmallSpacer />
        </>
      )}
      {props.icon && <Icon icon={props.icon} />}
      {props.children}
      <S.ExtraSmallSpacer />
    </S.FlexContainerColumn>
  )
}
