export interface ISeo {
  canonicalURL?: string
  id?: number
  keywords?: string
  metaDescription?: string
  metaRobots?: string
  metaTitle?: string
  metaViewport?: string
  structuredData?: string
  preventIndexing?: boolean
  metaImage?: any
}

export interface ILocation {
  id: number
  address: string
  county: string
  createdAt: string
  declaredInterest: string
  description: string
  exclusive: boolean
  images: IImage[]
  numberOfBookings: string
  publishedAt: string
  reviewed: string
  rooms: string
  squareMeters: string
  title: string
  updatedAt: string
  views: string
  zipCode: string
}

export interface ICreativePartner {
  id: number
  partnerName: string
  image: IImage[]
  county: string
  profession: string
  website: string
  instagram: string
  facebook: string
  linkedin: string
  youtube: string
  websiteButtonText: string
  description: string
}

export interface IUpdate {
  id: number
  attributes: {
    title: string
    text: string
    updatedAt: string
    publishedAt: string
    createdAt: string
    updateCategory: {
      data: {
        attributes: {
          category: string
        }
      }
    }
    author: string
    thumbnail: {
      data: {
        attributes: {
          url: string
          alternativeText: string
        }
        id: number
      }
    }
    seo: ISeo
  }
}

export interface IAbout {
  quote: string
  text: string
  images: {
    data: {
      id: number
      attributes: {
        url: string
        caption: string
        alternativeText: string
      }
    }[]
  }
}

export interface IImage {
  alternativeText: string
  caption: string
  createdAt: string
  ext: string
  formats: {
    medium: {
      ext: string
      hash: string
      height: number
      mime: string
      name: string
      path: string
      size: number
      url: string
      width: number
    }
    small: {
      ext: string
      hash: string
      height: number
      mime: string
      name: string
      path: string
      size: number
      url: string
      width: number
    }
    thumbnail: {
      ext: string
      hash: string
      height: number
      mime: string
      name: string
      path: string
      size: number
      url: string
      width: number
    }
  }
  hash: string
  height: number
  id: number
  mime: string
  name: string
  previewUrl: string
  provider: string
  provider_metadata: string
  size: number
  updatedAt: string
  url: string
  width: number
}

export interface IFavorite {
  id: number
  blocked: boolean
  company: string
  confirmed: boolean
  county: string
  createdAt: string
  email: string
  favoriteLocations?: ILocation[]
  favoriteCreativePartners?: ICreativePartner[]
  invoiceAddress: string
  name: string
  orgNumber: string
  phoneNumber: string
  provider: string
  type: string
  updatedAt: string
  username: string
  verified: boolean
  zipCode: string
}

export interface IProfession {
  attributes: {
    profession: string
  }
  id: number
}

export interface IPartner {
  id: string
  attributes: {
    county: string
    createdAt: string
    partnerName: string
    publishedAt: string
    updatedAt: string
    description: string
    youtube?: string
    instagram?: string
    facebook?: string
    linkedin?: string
    website?: string
    websiteButtonText?: string
    image: {
      data: {
        attributes: {
          url: string
          alternativeText: string
        }
      }[]
    }
    profession: {
      data: IProfession
    }
    seo: ISeo
  }
}

export interface ILocationPage {
  id: string
  isFavorite?: boolean
  attributes: {
    address: string
    createdAt: string
    county: string
    publishedAt: string
    updatedAt: string
    views: string
    description: string
    title: string
    squareMeters: string
    rooms: string
    exclusive: boolean
    declaredInterest: string
    images: {
      data: {
        attributes: {
          url: string
          alternativeText: string
        }
      }[]
    }
    directions: {
      data: {
        attributes: {
          direction: string
        }
      }[]
    }
    facilities: {
      data: {
        attributes: {
          facility: string
          icon: {
            data: {
              attributes: {
                alternativeText: string
                url: string
              }
            }
          }
        }
      }[]
    }
    locationTags: {
      data: {
        attributes: {
          tag: string
        }
      }[]
    }
  }
}

export interface IHowItWorksStep {
  is: number
  heading: string
  text: string
}

export interface IHowItWorksPage {
  createdAt: string
  heading: string
  headingCompany: string
  headingCustomer: string
  introHeadingCompany: string
  introHeadingCustomer: string
  publishedAt: string
  text: string
  textCompany: string
  textCustomer: string
  updatedAt: string
  media: {
    data: {
      attributes: IImage
    }
  }
  companySteps: IHowItWorksStep[]
  customerSteps: IHowItWorksStep[]
  seo: ISeo
}

export enum AuthRoutes {
  customerAccount = '/user/account',
  customerLocations = '/user/locations',
  customeUpdateLocationWithId = '/user/update-location',
  locations = '/locations',
  createLocation = '/create-location',
  favorites = '/favorites',
  account = '/account',
}

export enum NonAuthRoutes {
  home = '/',
  updates = '/updates',
  creativePartners = '/creative-partners',
  faq = '/faq',
  privacy = '/privacy',
  terms = '/terms',
  about = '/about',
  signUp = '/sign-up',
  howItWorks = '/how-it-works',
  reset = '/reset',
  notFound = '/404',
}

export enum UserRoles {
  company = 'company',
  customer = 'customer',
}
