import React from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'

const Textarea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  resize: vertical;
  min-height: 150px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  height: 54px;
`

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`

interface IProps {
  value: string
  setValue: (value: string) => void
  placeholder?: string
  label?: string
  type?: string
  submit?: () => void
}

export const TextArea: React.FC<IProps> = (props) => {
  return (
    <S.FlexContainerRowCenterCenter width="100%">
      <S.FlexContainerColumn position="relative" width="100%">
        {props.label && (
          <>
            <S.Paragraph>{props.label}</S.Paragraph>
            <S.ExtraSmallSpacer />
          </>
        )}
        <Textarea
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          onSubmit={() => props.submit && props.submit()}
          placeholder={props.placeholder}
        />
      </S.FlexContainerColumn>
    </S.FlexContainerRowCenterCenter>
  )
}
