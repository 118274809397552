import React from 'react'
import styled from 'styled-components'
import { colors } from '../../ui/core'
import * as S from '../../ui'
import { Images } from '../../ui/assets/images'
import { TextImageBlocks } from './components/TextImageBlocks'
import { SubscribeCTA } from '../../components/SubscribeCTA'
import { SubscribeWithBackground } from '../../components/SubscribeWithBackground'
import { Instagram } from '../../components/Instagram'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'

export const About: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <S.ContentContainer>
          <S.PaddingContainer>
            <TextImageBlocks />
            <S.ExtraLargeSpacer />
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}
