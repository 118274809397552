import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, FieldValues } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { logIn, signUp } from '../../auth/requests'
import { FormInput } from '../../components/FormInput'
import { loginAction } from '../../store/userReducer/actions'
import axios from 'axios'
import { AuthRoutes, NonAuthRoutes } from '../../types'
import { toast } from 'react-toastify'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { BASE_URL } from '../../utils/config'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { useIsLoggedIn } from '../../utils/useIsLoggedIn'
import { useGetUserType } from '../../utils/useGetUserType'
import {
  emailPattern,
  swedishPhoneNumberPattern,
  swedishOrgNumberPattern,
  zipCodePattern,
} from '../../utils/regex'
import { useSendVerifiedContract } from '../../utils/useSendVerifiedContract'

const Checkbox = styled.input`
  cursor: pointer;
`

const StyledTextButton = styled(S.TextButton)`
  display: inline-block;
`

const StyledParagraphSmall = styled(S.ParagraphSmall)`
  display: inline-block;
`

interface IProfileInfo {
  name: string
  password: string
  username: string
  company?: string
  orgNumber?: string
  email: string
  phoneNumber: string
  invoiceAddress: string
  type: string
  zipCode: string
  postalArea: string
  id: string
}

export const SignUp: React.FC = () => {
  const history = useHistory()
  const isSignedIn = useIsLoggedIn()
  const userType = useGetUserType()
  const dispatch = useDispatch()
  const [type, setType] = useState<string | null>()
  const [checkedSubscribe, setCheckedSubscribe] = useState<boolean>(true)

  useEffect(() => {
    if (isSignedIn) {
      let path =
        userType === 'company'
          ? AuthRoutes.favorites
          : userType === 'customer'
          ? AuthRoutes.customerLocations
          : NonAuthRoutes.signUp

      window.scroll(0, 0)
      history.push(path)
    }
  }, [userType])

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    setError,
  } = useForm<IProfileInfo>()

  const handleChange = () => {
    setCheckedSubscribe(!checkedSubscribe)
  }

  const SendContract = (id: number, type: string, email: string) => {
    useSendVerifiedContract(id, type, email)
  }

  const updateUser = (data: FieldValues) => {
    if (!type) return
    const { email, password } = getValues()
    setValue('username', email)
    setValue('type', type)
    if (checkedSubscribe) {
      axios
        .post(`${BASE_URL}/api/mailchimp/subscribe`, {
          data: { email: email, type: type },
        })
        .catch((err) => {
          toast.error('Could not subscribe to newsletter. Try again', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    }

    signUp(data)
      .then(() => {
        logIn(email, password).then((res) => {
          dispatch(loginAction({ ...res.data.user }))
          localStorage.setItem('token', res.data.jwt)
          SendContract(
            res.data.user.id,
            res.data.user.type,
            res.data.user.email
          )

          let path =
            type === 'company'
              ? AuthRoutes.favorites
              : type === 'customer'
              ? AuthRoutes.customerLocations
              : NonAuthRoutes.signUp

          window.scroll(0, 0)
          history.push(path)
        })
      })
      .catch((err) => {
        if (
          err.response.data.error.message ===
          'Email or Username are already taken'
        ) {
          const element = document.getElementById('emailInput')

          element &&
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          setError('email', { message: 'Mailadressen används redan' })
        }
      })
  }

  if (isSignedIn) return <></>

  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <S.ContentContainer maxWidth="700px">
          <S.PaddingContainer>
            <S.MediumSpacer />
            <S.H1>Create Account</S.H1>
            <S.MediumSpacer />

            <form onSubmit={handleSubmit(updateUser)}>
              <S.FlexContainerRow>
                <S.ButtonPrimary
                  id="company"
                  className={
                    !type ? '' : type == 'company' ? 'active' : 'notActive'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    setType('company')
                    setValue('type', 'company')
                  }}
                >
                  Rent locations
                </S.ButtonPrimary>
                <S.MediumSpacerRow />
                <S.ButtonPrimary
                  id="customer"
                  className={
                    !type ? '' : type == 'customer' ? 'active' : 'notActive'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    setType('customer')
                    setValue('type', 'customer')
                  }}
                >
                  List location
                </S.ButtonPrimary>
              </S.FlexContainerRow>
              <S.SmallSpacer />

              <StyledParagraphSmall>
                Not sure what to choose? Read about&nbsp;
              </StyledParagraphSmall>
              <StyledTextButton
                onClick={() => history.push(NonAuthRoutes.howItWorks)}
                padding="0"
                margin="8"
              >
                How it works
              </StyledTextButton>
              <S.MediumSpacer />

              {type && (
                <>
                  <S.H4>
                    {type == 'company' ? 'Rent Locations' : 'List Location'}
                  </S.H4>
                  <S.Paragraph>
                    {type == 'company'
                      ? '4 999 kr/år'
                      : '499 kr/år i medlemsavgift, som dras vid första uthyrningen'}
                  </S.Paragraph>
                  <S.MediumSpacer />
                  <FormInput label="Namn">
                    <S.InputField
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'Ange för- och efternamn',
                        },
                      })}
                      placeholder="För- och efternamn*"
                    />
                  </FormInput>
                  {errors.name?.message && (
                    <FormErrorMessage message={errors.name?.message} />
                  )}

                  {type === 'company' && (
                    <>
                      <FormInput label="Företagsnamn*">
                        <S.InputField
                          {...register('company', {
                            required: {
                              value: true,
                              message: 'Ange företagsnamn',
                            },
                          })}
                          placeholder="Företagsnamn"
                        />
                      </FormInput>
                      {errors.company?.message && (
                        <FormErrorMessage message={errors.company?.message} />
                      )}
                      <FormInput label="Organisationsnummer*">
                        <S.InputField
                          {...register('orgNumber', {
                            required: {
                              value: true,
                              message: 'Ange organisationsnummer',
                            },
                          })}
                          type="number"
                          placeholder="Organisationsnummer"
                        />
                      </FormInput>
                      {errors.orgNumber?.message && (
                        <FormErrorMessage message={errors.orgNumber?.message} />
                      )}
                      <S.MediumSpacer />
                    </>
                  )}

                  <FormInput label="Telefonnummer*">
                    <S.InputField
                      {...register('phoneNumber', {
                        required: {
                          value: true,
                          message: 'Ange telefonnummer',
                        },
                        pattern: {
                          value: swedishPhoneNumberPattern,
                          message: 'Ange giltig telefonnummer',
                        },
                      })}
                      type="tel"
                      placeholder="Telefonnummer"
                    />
                  </FormInput>
                  {errors.phoneNumber?.message && (
                    <FormErrorMessage message={errors.phoneNumber?.message} />
                  )}

                  <FormInput label="Mailadress*">
                    <S.InputField
                      {...register('email', {
                        required: { value: true, message: 'Ange mailadress' },
                        pattern: {
                          value: emailPattern,
                          message: 'Ange giltig mailadress',
                        },
                      })}
                      id="emailInput"
                      type="email"
                      placeholder="Mailadress"
                    />
                  </FormInput>
                  {errors.email?.message && (
                    <FormErrorMessage message={errors.email?.message} />
                  )}
                  <FormInput label="Lösenord*">
                    <S.InputField
                      {...register('password', {
                        required: { value: true, message: 'Ange lösenord' },
                        minLength: {
                          value: 6,
                          message: 'Lösenordet måste vara minst 6 tecken långt',
                        },
                      })}
                      type="password"
                      placeholder="LÖSENORD"
                    />
                  </FormInput>
                  {errors.password?.message && (
                    <FormErrorMessage message={errors.password?.message} />
                  )}

                  <S.LargeSpacer />
                  <FormInput label="Faktureringsadress*">
                    <S.InputField
                      {...register('invoiceAddress', {
                        required: {
                          value: true,
                          message: 'Ange adress',
                        },
                      })}
                      placeholder="Adress"
                    />
                  </FormInput>
                  {errors.invoiceAddress?.message && (
                    <FormErrorMessage
                      message={errors.invoiceAddress?.message}
                    />
                  )}
                  <FormInput label="Postnummer*">
                    <S.InputField
                      {...register('zipCode', {
                        required: {
                          value: true,
                          message: 'Ange postnummer',
                        },
                        pattern: {
                          value: zipCodePattern,
                          message: 'Ange giltig postnummer',
                        },
                      })}
                      placeholder="NNNNN"
                      type="number"
                    />
                  </FormInput>
                  {errors.zipCode?.message && (
                    <FormErrorMessage message={errors.zipCode?.message} />
                  )}
                  <FormInput label="Postort*">
                    <S.InputField
                      {...register('postalArea', {
                        required: {
                          value: true,
                          message: 'Ange ort',
                        },
                      })}
                      placeholder="Ort"
                    />
                  </FormInput>
                  {errors.postalArea?.message && (
                    <FormErrorMessage message={errors.postalArea?.message} />
                  )}

                  <S.LargeSpacer />
                  <S.FlexContainerRowAlignCeter>
                    <S.SmallSpacerRow />
                    <Checkbox
                      type="checkbox"
                      checked={checkedSubscribe}
                      onChange={handleChange}
                    />
                    <S.SmallSpacerRow />
                    <S.ParagraphSmall>
                      Jag vill få nyhetsbrev med tips och aktuella händelser
                    </S.ParagraphSmall>
                  </S.FlexContainerRowAlignCeter>

                  <S.LargeSpacer />
                  <S.ParagraphSmall>
                    By signing up you agree to Bonanza Location’s
                  </S.ParagraphSmall>
                  <S.FlexContainerRowAlignCeter>
                    <S.StyledLink to={NonAuthRoutes.terms} target="_blank">
                      TERMS OF SERVICE
                    </S.StyledLink>
                    <S.ExtraSmallSpacerRow />
                    <S.ParagraphSmall>&</S.ParagraphSmall>
                    <S.ExtraSmallSpacerRow />
                    <S.StyledLink to={NonAuthRoutes.privacy} target="_blank">
                      PRIVACY POLICY
                    </S.StyledLink>
                  </S.FlexContainerRowAlignCeter>
                  <S.SmallSpacer />

                  <S.LargeSpacer />
                  <S.FlexContainerRowJustifyEnd>
                    <S.TextButton
                      onClick={() => {
                        window.scroll(0, 0)
                        history.push(NonAuthRoutes.home)
                      }}
                      color={colors.black}
                    >
                      Avbryt
                    </S.TextButton>
                    <S.ExtraLargeSpacerRow />
                    <S.ButtonPrimary type="submit">Spara</S.ButtonPrimary>
                  </S.FlexContainerRowJustifyEnd>
                </>
              )}

              {!type && (
                <>
                  <S.StyledLinkButton
                    onClick={() => history.push(NonAuthRoutes.home)}
                  >
                    Avbryt
                  </S.StyledLinkButton>
                </>
              )}
            </form>
            <S.VerticalMargin />
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}
