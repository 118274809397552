import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../ui'
import * as S from '../../../ui'

const CookiePopUp = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  padding: 24px;
  box-shadow: 0 0 50px #0000003e;

  @media screen and (min-width: 1200px) {
    width: 449px;
    margin-bottom: 84px;
    margin-left: 64px;
  }
`

export const Cookies: React.FC = () => {
  const [showCookiePopUp, setShowCookiePopUp] = useState(true)
  const consentLS = localStorage.getItem('cookieConsent')

  if (!showCookiePopUp || consentLS) return <></>

  return (
    <CookiePopUp>
      <S.ParagraphSmall>
        Den här hemsidan använder cookies för att erbjuda en bättre
        användarupplevelse.{' '}
        <S.StyledLink to="/privacy">
          <S.H5 display="inline">LÄS MER</S.H5>
        </S.StyledLink>
      </S.ParagraphSmall>
      <S.MediumSpacer />

      <S.FlexContainerRow>
        <S.TextButton
          onClick={() => {
            localStorage.setItem('cookieConsent', 'false')
            setShowCookiePopUp(false)
          }}
          width="50%"
          display="flex"
          margin="0"
          color={colors.black}
        >
          AVVISA ALLA
        </S.TextButton>
        <S.MediumSpacerRow />
        <S.ButtonPrimary
          onClick={() => {
            localStorage.setItem('cookieConsent', 'true')
            setShowCookiePopUp(false)
          }}
          width="50%"
        >
          GODKÄNN ALLA
        </S.ButtonPrimary>
      </S.FlexContainerRow>
    </CookiePopUp>
  )
}
