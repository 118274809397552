import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import { validateEmail } from '../../utils/validateEmail'
import { FormErrorMessage } from '../FormErrorMessage'
import { FormInput } from '../FormInput'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../utils/config'
import { useGetMe } from '../../utils/useGetMe'

interface IProps {
  sent: () => void
}

export const ResetPassword: React.FC<IProps> = (props) => {
  const user = useGetMe()
  const [sent, setSent] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  useEffect(() => {
    if (user) {
      setEmail(user.email)
    }
  }, [user])

  const checkEmail = (value: string) => {
    validateEmail(value) ? setError(false) : setError(true)
  }

  const resetPasswordRequest = () => {
    checkEmail(email)
    axios
      .post(`${BASE_URL}/api/auth/forgot-password`, {
        email: email,
      })
      .then((response) => {
        setSuccessMessage('Your user received an email')
        setSent(true)
      })
      .catch((error) => {
        toast.error('An error occurred. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  return (
    <S.FlexContainerColumnJustifyCenter>
      <S.H3>Reset password</S.H3>
      <S.MediumSpacer />
      {!sent ? (
        <>
          <S.ParagraphSmall>
            Please enter your email. You will recieve a link to create a new
            password.
          </S.ParagraphSmall>
          <S.MediumSpacer />
          <FormInput label="Email*">
            <S.InputField
              value={email}
              onChange={(e) => (setEmail(e.target.value), setError(false))}
              placeholder="EMAIL"
            />
          </FormInput>
          {error && (
            <FormErrorMessage
              message={successMessage ? successMessage : 'Invalid email'}
            />
          )}
          <S.ExtraLargeSpacer />
          <S.ModalButtonPrimary
            onClick={() => {
              resetPasswordRequest()
            }}
          >
            Send
          </S.ModalButtonPrimary>
        </>
      ) : (
        <>
          <S.ParagraphSmall>
            We've sent you an email with instructions to reset your password.
            Please check your inbox.
          </S.ParagraphSmall>
          <S.ExtraLargeSpacer />
          <S.ModalButtonPrimary
            onClick={() => {
              props.sent()
            }}
          >
            CLOSE
          </S.ModalButtonPrimary>
        </>
      )}
    </S.FlexContainerColumnJustifyCenter>
  )
}
