export const colors = {
  blue: '#1737B3',
  yellow: '#BF9408',
  pink: '#CFADA2',
  white: '#F6F3F1',
  orange: '#AE6323',
  brown: '#3D2523',
  black: '#000000',
  gray: '#888177',
} as const

export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
} as const
