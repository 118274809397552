import axios from 'axios'
import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as S from '../../../../ui'
import { colors } from '../../../../ui'
import { Icon, IconType } from '../../../../ui/assets/icons'
import {
  IFavorite,
  ILocation,
  ICreativePartner,
  AuthRoutes,
  NonAuthRoutes,
} from '../../../../types'
import { ImageGallery } from './components/ImageGallery'
import { CompanyAccountNavBar } from './components/CompanyAccountNavBar'
import { PaginationButton } from '../../../Updates/components/AllUpdates'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../../utils/config'

const AccountContainer = styled.div`
  margin-top: 30px;
  align-items: center;
  @media screen and (min-width: 1200px) {
    margin-top: 30px;
  }
`

const ImageContainer = styled(S.ContentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 260px;
  gap: 32px;
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-auto-rows: 400px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1200px) {
    grid-auto-rows: 460px;
    grid-template-columns: repeat(4, 1fr);
  }
`

const Card = styled(S.FlexContainerColumn)`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  word-wrap: break-word;
  width: 80%;
  &.large {
    :nth-of-type(3) {
      width: calc(50% - 16px);
    }
  }
  .text {
    opacity: 1;
  }
  @media screen and (max-width: 1000px) {
    .text {
      opacity: 0;
    }
  }
`

const Image = styled.img`
  @media screen and (max-width: 1199px) {
    height: 40vw;
  }
  max-height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 75%;
  background: rgba(61, 37, 35, 0.9);
  padding: 12px 32px;
  h5,
  h6,
  p {
    height: 30px;
    width: 200px;
    color: ${colors.white};
  }
  button {
    margin-top: 25px;
    margin-left: 30px;
    height: 50px;
    width: 200px;
  }
`

const Review = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 186px;
  height: 32px;
  background: ${colors.white};
  border: 1px ${colors.brown} solid;
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    margin-left: auto;
    margin-right: auto;
  }
`

const MobileText = styled.div`
  padding: 20px;
  display: block;
  color: ${colors.white};
  background: ${colors.brown};
  @media screen and (min-width: 1000px) {
    display: none;
  }
  h5,
  h6,
  p {
    height: 30px;
    width: 200px;
    color: ${colors.white};
  }
  button {
    margin-right: auto;
    margin-left: auto;
    height: 50px;
    width: 200px;
  }
`

const HeaderContainer = styled.div`
  width: min-content;
  margin: auto;
`

const Underline = styled.div`
  margin-top: 0px;
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
`

const NoLocationsContainer = styled.div`
  max-width: 800px;
  padding-top: 42px;
  padding-bottom: 170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    padding-top: 169px;
  }
`

const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 8px 0;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    button {
      margin: 0 8px;
    }
  }
`

const StyledH6 = styled(S.H6)`
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
`

export const MyFavorites: React.FC = () => {
  const history = useHistory()
  const [data, setData] = useState<IFavorite>()
  const [locations, setLocations] = useState<ILocation[]>([])
  const [creativePartners, setCreativePartners] = useState<ICreativePartner[]>(
    []
  )

  // pagination
  const PageSize = 8

  //locations
  const [total, setTotal] = useState(0)
  const totalPageCount = Math.ceil(locations.length / 8)
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return locations.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, locations])

  //creativePartners
  const [totalPartner, setTotalPartner] = useState(0)
  const totalPageCountPartner = Math.ceil(creativePartners.length / 8)
  const [paginationButtonsPartner, setPaginationButtonsPartner] = useState<
    JSX.Element[]
  >([])
  const [currentPagePartner, setCurrentPagePartner] = useState(1)

  const currentTableDataPartner = useMemo(() => {
    const firstPageIndex = (currentPagePartner - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return creativePartners.slice(firstPageIndex, lastPageIndex)
  }, [currentPagePartner, creativePartners])

  const qs = require('qs')
  const query = qs.stringify({
    populate: {
      favoriteLocations: {
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      favoriteCreativePartners: {
        populate: '*',
      },
    },
  })

  const getMe = () => {
    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data)
        setLocations(res.data.favoriteLocations)
        setCreativePartners(res.data.favoriteCreativePartners)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const updateFavorite = (locations: any) => {
    const favorites = {
      favoriteLocations: locations,
    }
    axios
      .put(`${BASE_URL}/api/users/${data?.id}`, favorites, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        getMe()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const updateCreativePartners = (creativePartners: any) => {
    const favorites = {
      favoriteCreativePartners: creativePartners,
    }
    axios
      .put(`${BASE_URL}/api/users/${data?.id}`, favorites, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        getMe()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    getMe()
  }, [query])

  const removeFavorite = (locationId?: number) => {
    const filteredLocations = locations?.filter(
      (location: ILocation) => location.id !== locationId
    )
    const locationsArray = filteredLocations?.map(({ id }) => id)
    updateFavorite(locationsArray)
  }

  const removeCreativePartners = (creativePartnerId?: number) => {
    const filteredCreativePartners = creativePartners?.filter(
      (creativePartner: ICreativePartner) =>
        creativePartner.id !== creativePartnerId
    )
    const creativePartnersArray = filteredCreativePartners?.map(({ id }) => id)
    updateCreativePartners(creativePartnersArray)
  }

  useEffect(() => {
    setPaginationButtons([])
    for (let i = 0; i < totalPageCount; i++) {
      setPaginationButtons((prev) => [
        ...prev,
        <PaginationButton
          key={i}
          onClick={() => {
            window.scroll(0, 0)
            setCurrentPage(i + 1)
          }}
          className={currentPage === i + 1 ? 'active' : ''}
          disabled={currentPage === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [totalPageCount, currentPage, locations])

  useEffect(() => {
    setPaginationButtonsPartner([])
    for (let i = 0; i < totalPageCount; i++) {
      setPaginationButtonsPartner((prev) => [
        ...prev,
        <PaginationButton
          key={i}
          onClick={() => setCurrentPagePartner(i + 1)}
          className={currentPagePartner === i + 1 ? 'active' : ''}
          disabled={currentPagePartner === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [totalPageCountPartner, currentPagePartner, creativePartners])

  if (!data || !currentTableData || !currentTableDataPartner) return <></>

  return (
    <>
      <CompanyAccountNavBar />
      <S.Wrapper>
        <S.ContentContainer>
          <S.PaddingContainer>
            <S.LargeSpacer />
            <S.H1>MY ACCOUNT / SAVED</S.H1>

            {currentTableData && currentTableData.length > 0 && (
              <>
                <S.LargeSpacer />
                <S.H4>LOCATIONS</S.H4>
                <S.LargeSpacer />
                <S.Wrapper>
                  <ImageContainer>
                    {currentTableData?.map((location: ILocation) => {
                      return (
                        <ImageGallery
                          key={location.id}
                          location={location}
                          handleLocations={() => {
                            removeFavorite(location.id)
                          }}
                        />
                      )
                    })}
                  </ImageContainer>
                </S.Wrapper>
                <S.LargeSpacer />
                {totalPageCount > 1 && (
                  <>
                    <S.FlexContainerRowCenterCenter>
                      {paginationButtons}
                    </S.FlexContainerRowCenterCenter>
                    <S.LargeSpacer />
                  </>
                )}
              </>
            )}

            {currentTableDataPartner && currentTableDataPartner.length > 0 && (
              <>
                <S.ExtraLargeSpacer />
                <S.H4>CREATIVE PARTNERS</S.H4>
                <S.LargeSpacer />
                <S.Wrapper>
                  <ImageContainer>
                    {currentTableDataPartner?.map(
                      (creativePartner: ICreativePartner) => {
                        return (
                          <ImageGallery
                            key={creativePartner.id}
                            creativePartner={creativePartner}
                            handleCreativePartners={() => {
                              removeCreativePartners(creativePartner.id)
                            }}
                          />
                        )
                      }
                    )}
                  </ImageContainer>
                </S.Wrapper>
                <S.ExtraLargeSpacer />
                {totalPageCountPartner > 1 && (
                  <>
                    <S.FlexContainerRowCenterCenter>
                      {paginationButtonsPartner}
                    </S.FlexContainerRowCenterCenter>
                    <S.LargeSpacer />
                  </>
                )}
              </>
            )}

            {currentTableDataPartner &&
              currentTableDataPartner.length <= 0 &&
              currentTableData &&
              currentTableData.length <= 0 && (
                <NoLocationsContainer>
                  <Icon
                    icon={IconType.Bookmark}
                    stroke={colors.gray}
                    height={42}
                    width={42}
                  />
                  <S.MediumSpacer />
                  <S.H4 color={colors.gray}>DU HAR INTE SPARAT NÅGOT ÄNNU</S.H4>
                  <S.MediumSpacer />
                  <StyledH6 color={colors.gray}>
                    Kolla in våra härliga locations och våra creative partners
                    för att börja spara favoriter.
                  </StyledH6>
                  <S.ExtraLargeSpacer />
                  <FlexContainer>
                    <S.ButtonPrimary
                      onClick={() => {
                        history.push(AuthRoutes.locations)
                      }}
                    >
                      LOCATIONS
                    </S.ButtonPrimary>

                    <S.ButtonPrimary
                      onClick={() => {
                        history.push(NonAuthRoutes.creativePartners)
                      }}
                    >
                      CREATIVE PARTNERS
                    </S.ButtonPrimary>
                  </FlexContainer>
                </NoLocationsContainer>
              )}
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}
