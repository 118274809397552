import React, { useEffect, useState } from 'react'
import * as S from '../../../ui'
import styled from 'styled-components'
import { colors } from '../../../ui/core'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

const Banner = styled(S.FlexContainerColumnCenterCenter)`
  background-color: ${colors.white};
  padding: 8px;
  position: relative;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: end;
  }
`

const StatNumber = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: ${colors.pink};

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
`

interface ICount {
  lastWeekCount: number
  totalCount: number
}

export const StatBanner: React.FC = () => {
  const [count, setCount] = useState<ICount | undefined>()

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/location/count`)
      .then((res) => {
        setCount(res.data)
      })
      .catch((err) => {
        console.error('Could not fetch count' + err)
      })
  }, [])

  return (
    <>
      {count && (count.lastWeekCount > 0 || count.totalCount > 0) && (
        <Banner>
          {count.lastWeekCount > 0 && (
            <S.FlexContainerRowCenterCenter>
              <StatNumber>{count.lastWeekCount}</StatNumber>
              <S.ExtraSmallSpacerRow />
              <S.DetailsSmall>New Locations in the last 7 days</S.DetailsSmall>
            </S.FlexContainerRowCenterCenter>
          )}
          <S.MediumSpacerRow />
          {count.totalCount > 0 && (
            <S.FlexContainerRowCenterCenter>
              <StatNumber>{count.totalCount}</StatNumber>
              <S.ExtraSmallSpacerRow />
              <S.DetailsSmall>Total locations</S.DetailsSmall>
            </S.FlexContainerRowCenterCenter>
          )}
        </Banner>
      )}
    </>
  )
}
