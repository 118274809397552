import styled from 'styled-components'
import { colors } from './core'

interface IProps {
  color?: string
  align?: string
  display?: string
}

export const H1 = styled.h1<IProps>`
  font-family: 'Archivo Black', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};
  word-wrap: break-word;

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
`

export const H2 = styled.h2<IProps>`
  font-family: 'Archivo Black', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 90%;
  letter-spacing: 0.04em;
  color: ${(props) => props.color ?? colors.black};
  text-transform: uppercase;

  @media screen and (min-width: 1200px) {
    font-size: 64px;
  }
`

export const H3 = styled.h3<IProps>`
  font-family: 'Archivo Black', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: ${(props) => props.align ?? 'auto'};
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`

export const H4 = styled.h4<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
`

export const H5 = styled.h5<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  line-height: 19px;
  letter-spacing: 0.05em;
  display: ${(props) => props.display ?? 'static'};
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`

export const H6 = styled.h6<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.color ?? colors.black};

  @media screen and (min-width: 1200px) {
    font-size: 14px;
    line-height: 19px;
  }
`

export const Paragraph = styled.p<IProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: ${(props) => props.color ?? colors.black};
  text-align: ${(props) => props.align ?? 'left'};

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    line-height: 160%;
  }
`

export const ParagraphBig = styled(Paragraph)`
  font-size: 18px;
  line-height: 130%;

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
`

export const ParagraphSmall = styled(Paragraph)`
  font-size: 14px;
  line-height: 19px;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`

export const ParagraphXSmall = styled(Paragraph)`
  font-size: 12px;
  line-height: 19px;

  @media screen and (min-width: 1200px) {
    font-size: 14px;
  }
`

export const DetailsSmall = styled(Paragraph)`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const DetailsBig = styled(DetailsSmall)`
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;

  @media screen and (min-width: 1200px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
`

export const HeaderText = styled.a`
  font-family: 'Prata', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.blue};

  @media screen and (min-width: 1200px) {
    font-weight: 400;
    font-size: 85px;
    line-height: 120%;
    letter-spacing: 0.04em;
  }

  @media screen and (min-width: 1360px) {
    font-size: 100px;
    line-height: unset;
  }
`

export const SmallHeaderText = styled(HeaderText)`
  @media screen and (min-width: 1200px) {
    font-size: 48px;
  }
`
export const ValidationError = styled(Paragraph)`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.orange};

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`
