import React, { useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Input } from './Input'
import axios from 'axios'
import { BASE_URL } from '../utils/config'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from './Spinner'
import { FormErrorMessage } from './FormErrorMessage'
import { validateEmail } from '../utils/validateEmail'

const CTAContainer = styled.div<IProps>`
  width: 100%;
  margin: auto;
  max-width: ${(props) => props.maxWidth ?? 'auto'};
`
const InnerContainer = styled.div`
  display: flex;
`

interface IProps {
  maxWidth?: string
}

export const SubscribeCTA: React.FC<IProps> = (props) => {
  const [role, setRole] = useState<string | undefined>()
  const [email, setEmail] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [roleError, setRoleError] = useState<string | undefined>()

  const subscribe = () => {
    setIsLoading(true)
    const validateRole = !role
      ? setRoleError('Choose your role')
      : setRoleError(undefined)
    const validate = validateEmail(email)

    if (!validate) {
      setIsLoading(false)
      setError('Enter correct email')
      return
    }
    axios
      .post(`${BASE_URL}/api/mailchimp/subscribe`, {
        data: { email: email, type: role },
      })
      .then(() => {
        setSubscribed(true)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        setError('Could not subscribe to newsletter. Try again later.')
        throw Error('Could not subscribe to newsletter' + err)
      })
  }

  return (
    <CTAContainer maxWidth={props.maxWidth}>
      {!subscribed ? (
        <>
          <S.H5 color={colors.white}>SUBSCRIBE TO OUR NEWSLETTER</S.H5>
          <S.SmallSpacer />
          <InnerContainer>
            <Input value={email} setValue={setEmail} placeholder="YOUR EMAIL" />
            <S.SmallSpacerRow />

            <S.ButtonPrimary
              color={colors.white}
              background={colors.blue}
              width="200px"
              onClick={subscribe}
            >
              {isLoading ? (
                <Spinner noBackground width={'20px'} height={'20px'} />
              ) : (
                <span>SUBSCRIBE</span>
              )}
            </S.ButtonPrimary>
          </InnerContainer>
          <S.SmallSpacer />
          {error && <FormErrorMessage message={error} />}

          <form>
            <S.FlexContainerRowAlignCeter width="calc(100vw - 32px)">
              <S.RadioButton
                id="customer"
                name="test"
                onClick={() => setRole('customer')}
              />
              <S.ExtraSmallSpacerRow />
              <S.ParagraphSmall color={colors.white}>
                Rent locations
              </S.ParagraphSmall>
              <S.MediumSpacerRow />
              <S.RadioButton
                id="business"
                name="test"
                onClick={() => setRole('company')}
              />
              <S.ExtraSmallSpacerRow />
              <S.ParagraphSmall color={colors.white}>
                List locations
              </S.ParagraphSmall>
            </S.FlexContainerRowAlignCeter>
            <S.SmallSpacer />
            {roleError && <FormErrorMessage message={roleError} />}
          </form>
        </>
      ) : (
        <S.H5 color={colors.white}>Thank you!</S.H5>
      )}
    </CTAContainer>
  )
}
