import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { AuthRoutes, IPartner, NonAuthRoutes } from '../../../types'
import { Icon, IconType } from '../../../ui/assets/icons'
import { FullscreenImages } from '../../../components/FullscreenImages'
import { IContent, ImageSection } from '../../../components/ImageSection'
import { EnquiryModal } from '../../../components/modals/EnquiryModal'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { FavoritesButton } from '../../../components/FavoritesButton'
import { ICreativePartner, IFavorite } from '../../../types'
import { removeFavorite, addFavorite } from '../../../utils/helperFunctions'
import { BASE_URL } from '../../../utils/config'
import { toast } from 'react-toastify'
import { useIsLoggedIn } from '../../../utils/useIsLoggedIn'
import { Seo } from '../../../components/Seo'

export const Container = styled.div`
  @media screen and (min-width: 800px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 50px;
  }

  @media screen and (min-width: 1200px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60% 30%;
    padding: 0 150px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 295px;
  min-height: 295px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: 1200px) {
    height: 808px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

export const Image1 = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    height: 808px;
  }
`

export const Image2 = styled.div`
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
    height: 400px;
  }
`

export const ImageButtons = styled(S.FlexContainerRow)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
  }
`

export const BackLink = styled.div`
  display: none;

  @media screen and (min-width: 800px) {
    display: unset;
    position: absolute;
    bottom: -40px;
    left: 0;
  }
`

export const TagContainer = styled(S.FlexContainerColumn)`
  align-items: center;
  @media screen and (min-width: 1200px) {
    align-items: flex-start;
  }
`

export const Tag = styled.a`
  background-color: ${colors.black};
  width: max-content;
  padding: 8px;
  margin: 4px;
  color: ${colors.white};
  text-decoration: none;
`

interface IParams {
  id: string
}

export const CreativePartner: React.FC = () => {
  const history = useHistory()
  const isSignedIn = useIsLoggedIn()
  const { id } = useParams<IParams>()
  const [userType, setUserType] = useState<string>()
  const [partner, setPartner] = useState<IPartner>()
  const [suggestedPartners, setSuggestedPartners] = useState<IContent[]>()
  const [showImages, setShowImages] = useState(false)
  const [favoriteCreativePartners, setFavoriteCreativePartners] =
    useState<ICreativePartner[]>()
  const [isFavoriteCreativePartner, setIsFavoriteCreativePartner] =
    useState<boolean>(false)
  const [data, setData] = useState<IFavorite>()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const getMe = async () => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        favoriteCreativePartners: {
          populate: '*',
        },
      },
    })
    const token = localStorage.getItem('token')
    await axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data)
        setUserType(res.data.type)
        setFavoriteCreativePartners(res.data.favoriteCreativePartners)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    if (isSignedIn) {
      getMe()
    }
  }, [id, isSignedIn])

  useEffect(() => {
    async function fetchData() {
      const qs = require('qs')
      const query = qs.stringify({
        populate: {
          image: '*',
          seo: {
            populate: {
              metaImage: '*',
            },
          },
        },
        encodeValuesOnly: true, // prettify URL
      })

      await axios
        .get(`${BASE_URL}/api/creative-partners/${id}?${query}`)
        .then((res) => {
          setPartner(res.data.data)
        })
        .catch((err) => {
          history.push(NonAuthRoutes.notFound)
          throw Error(err)
        })
    }
    fetchData()
  }, [id])

  useEffect(() => {
    async function fetchData() {
      const qs = require('qs')
      const query = qs.stringify({
        pagination: {
          pageSize: 2,
        },
        populate: {
          image: '*',
        },
        filters: {
          county: {
            $eq: partner?.attributes.county,
          },
          id: {
            $ne: partner?.id,
          },
        },
        encodeValuesOnly: true, // prettify URL
      })

      await axios
        .get(`${BASE_URL}/api/creative-partners?${query}`)
        .then((res) => {
          setSuggestedPartners(
            res.data.data.map((d: IPartner) => {
              return {
                image: d.attributes.image.data[0].attributes?.url,
                header: d.attributes.partnerName,
                body: d.attributes.county,
                id: d.id,
              }
            })
          )
        })
        .catch((err) => {
          toast.error('2', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    }
    fetchData()
  }, [partner])

  const updateFavorite = async (creativePartners: any) => {
    const favorites = {
      favoriteCreativePartners: creativePartners,
    }
    await axios
      .put(`${BASE_URL}/api/users/${data?.id}`, favorites, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(() => {
        getMe()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    if (showImages) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = 'static'
    }
  }, [showImages])

  useEffect(() => {
    if (favoriteCreativePartners) {
      const locId = Number(id)
      setIsFavoriteCreativePartner(
        favoriteCreativePartners?.some((p) => p.id === locId)
      )
    }
  }, [favoriteCreativePartners, id])

  const toggleFavorite = (partnerId: number) => {
    if (isFavoriteCreativePartner) {
      const data = removeFavorite(partnerId, favoriteCreativePartners)
      updateFavorite(data)
    } else {
      const data = addFavorite(partnerId, favoriteCreativePartners)
      updateFavorite(data)
    }
  }

  const redirect = (id: string) => {
    history.push(`${NonAuthRoutes.creativePartners}/${id}`)
  }

  const sendPartnerEnquiry = async (budget: string, message: string) => {
    setSuccess(false)
    setError(false)

    await axios
      .post(`${BASE_URL}/api/creative-partner/email`, {
        budget: budget,
        message: message,
        userEmail: data?.email,
        partnerId: partner?.id,
        partnerName: partner?.attributes.partnerName,
      })
      .then(() => setSuccess(true))
      .catch(() => setError(true))
  }

  if (!partner) return <></>

  return (
    <>
      {partner.attributes?.seo && <Seo metaData={partner.attributes?.seo} />}
      <ScrollToTopOnMount />
      <S.Wrapper>
        <S.ContentContainer>
          <S.PaddingContainer>
            <S.VerticalMargin />

            <ImageContainer>
              <Image1>
                <Image
                  src={partner?.attributes?.image?.data[0]?.attributes?.url}
                  alt={
                    partner?.attributes?.image?.data[0]?.attributes
                      ?.alternativeText
                  }
                />
              </Image1>
              <S.FlexContainerColumn>
                <Image2>
                  <Image
                    src={`${partner?.attributes?.image?.data[1]?.attributes?.url}`}
                    alt={
                      partner?.attributes?.image?.data[1]?.attributes
                        ?.alternativeText
                    }
                  />
                </Image2>
                <S.ExtraSmallSpacer />
                <Image2>
                  <Image
                    src={`${partner?.attributes?.image?.data[2]?.attributes?.url}`}
                    alt={
                      partner?.attributes?.image?.data[2]?.attributes
                        ?.alternativeText
                    }
                  />
                </Image2>
              </S.FlexContainerColumn>
              <ImageButtons>
                {isSignedIn && userType === 'company' && (
                  <FavoritesButton
                    creativePartner
                    position="static"
                    border
                    isFavorite={favoriteCreativePartners?.some(
                      (p) => p.id === Number(id)
                    )}
                    onClick={() => {
                      toggleFavorite(Number(id))
                    }}
                  />
                )}

                <S.ExtraSmallSpacerRow />
                <S.WhiteButton
                  onClick={() => setShowImages(true)}
                  border="none"
                >
                  Se alla bilder
                </S.WhiteButton>
              </ImageButtons>

              <BackLink>
                <S.FlexContainerRowAlignCeter>
                  <Icon icon={IconType.ChevronLeft} />
                  <S.TextButton
                    margin="0"
                    onClick={() => history.goBack()}
                    color={colors.black}
                  >
                    TILLBAKA
                  </S.TextButton>
                </S.FlexContainerRowAlignCeter>
              </BackLink>
            </ImageContainer>

            <Container>
              <S.FlexContainerColumn width="100%">
                <S.MediumSpacer />
                <S.H1>{partner.attributes.partnerName}</S.H1>
                <S.ExtraSmallSpacer />
                <S.DetailsBig>{partner.attributes.county}</S.DetailsBig>
                <S.MediumSpacer />
                <S.Paragraph>{partner.attributes.description}</S.Paragraph>
                <S.ExtraLargeSpacer />
              </S.FlexContainerColumn>

              <TagContainer>
                {partner.attributes.website && (
                  <>
                    <Tag href={partner.attributes.website} target="_blank">
                      <S.DetailsBig color={colors.white}>
                        {partner.attributes.websiteButtonText}
                      </S.DetailsBig>
                    </Tag>
                    <S.ExtraLargeSpacer />
                  </>
                )}

                {(partner.attributes.linkedin ||
                  partner.attributes.facebook ||
                  partner.attributes.youtube ||
                  partner.attributes.instagram) && (
                  <>
                    <S.H4>SOCIALA MEDIER</S.H4>
                    <S.LargeSpacer />
                    <S.FlexContainerColumn>
                      {partner.attributes.instagram && (
                        <>
                          <S.FlexContainerRowAlignCeter>
                            <Icon icon={IconType.Instagram} />
                            <S.MediumSpacerRow />
                            <S.StyledA
                              target="_blank"
                              href={partner.attributes.instagram}
                            >
                              Instagram
                            </S.StyledA>
                          </S.FlexContainerRowAlignCeter>
                          <S.LargeSpacer />
                        </>
                      )}
                      {partner.attributes.facebook && (
                        <>
                          <S.FlexContainerRowAlignCeter>
                            <Icon icon={IconType.Facebook} />
                            <S.MediumSpacerRow />
                            <S.StyledA
                              target="_blank"
                              href={partner.attributes.facebook}
                            >
                              Facebook
                            </S.StyledA>
                          </S.FlexContainerRowAlignCeter>
                          <S.LargeSpacer />
                        </>
                      )}
                      {partner.attributes.youtube && (
                        <>
                          <S.FlexContainerRowAlignCeter>
                            <Icon icon={IconType.Youtube} />
                            <S.MediumSpacerRow />
                            <S.StyledA
                              target="_blank"
                              href={partner.attributes.youtube}
                            >
                              Youtube
                            </S.StyledA>
                          </S.FlexContainerRowAlignCeter>
                          <S.LargeSpacer />
                        </>
                      )}
                      {partner.attributes.linkedin && (
                        <>
                          <S.FlexContainerRowAlignCeter>
                            <Icon icon={IconType.Linkedin} />
                            <S.MediumSpacerRow />
                            <S.StyledA
                              target="_blank"
                              href={partner.attributes.linkedin}
                            >
                              LinkedIn
                            </S.StyledA>
                          </S.FlexContainerRowAlignCeter>
                          <S.LargeSpacer />
                        </>
                      )}
                    </S.FlexContainerColumn>
                  </>
                )}
              </TagContainer>
            </Container>
            <S.VerticalMargin />
          </S.PaddingContainer>
          {suggestedPartners && suggestedPartners.length > 0 && (
            <>
              <ImageSection
                fullWidthImages
                handleClick={redirect}
                title={`Fler creative partners i ${partner.attributes.county}`}
                content={suggestedPartners}
              />
              <S.VerticalMargin />
            </>
          )}
          {showImages && (
            <>
              <S.VerticalMargin />
              {partner.attributes.image && (
                <FullscreenImages
                  close={() => setShowImages(false)}
                  images={partner.attributes.image.data}
                />
              )}
            </>
          )}
        </S.ContentContainer>
        <EnquiryModal
          recipient={'CREATIVE PARTNER'}
          onSend={sendPartnerEnquiry}
          success={success}
          error={error}
          setSuccess={setSuccess}
        />
      </S.Wrapper>
    </>
  )
}
