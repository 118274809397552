import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as S from '../../../ui'
import { Images } from '../../../ui/assets/images'
import { colors } from '../../../ui/core'
import { NonAuthRoutes, IUpdate } from '../../../types'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'
import { StyledMarkdownParser } from '../../../components/StyledParsedMarkdown'

const Container = styled(S.FlexContainerColumn)`
  @media screen and (min-width: 1200px) {
    cursor: pointer;
    flex-direction: row;
    position: relative;
    height: min-content;
    justify-content: center;
    align-items: stretch;
  }
`

const Image = styled(S.FlexContainerColumn)`
  height: 250px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: 1200px) {
    height: auto;
    max-height: 100%;

    img {
      width: 50%;
      position: absolute;
      left: 0;
    }
  }
`

const Text = styled(S.FlexContainerColumnCenterCenter)`
  background-color: ${colors.orange};
  padding: 16px;
  height: min-content;
  margin: auto;
  margin-right: 0;
  width: 100%;

  * {
    color: ${colors.white};
    text-align: center;
  }

  @media screen and (min-width: 1200px) {
    width: 50%;
  }
`

export const HighlightedUpdate: React.FC = () => {
  const history = useHistory()
  const [update, setUpdate] = useState<IUpdate>()

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify(
      {
        sort: 'publishedAt:desc',
        populate: '*',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(`${BASE_URL}/api/updates?${query}`)
      .then((res) => {
        setUpdate(res.data.data[0])
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  if (!update) {
    return <></>
  }

  return (
    <>
      <Container
        onClick={() => history.push(`${NonAuthRoutes.updates}/${update.id}`)}
      >
        <Image>
          <img
            src={update.attributes?.thumbnail?.data?.attributes?.url}
            alt={update.attributes.thumbnail.data.attributes.alternativeText}
          />
        </Image>
        <Text>
          <S.SmallSpacer />
          <S.FlexContainerColumnCenterCenter style={{ width: 'min-content' }}>
            <S.DetailsBig>
              {update.attributes?.updateCategory?.data?.attributes?.category}
            </S.DetailsBig>
            <S.Underline color={colors.white} width={'calc(100% + 20px)'} />
          </S.FlexContainerColumnCenterCenter>
          <S.SmallSpacer />
          <S.H3>{update.attributes?.title}</S.H3>
          <S.SmallSpacer />
          <S.ParagraphBig>
            <StyledMarkdownParser
              text={update.attributes?.text.substring(0, 200) + '...'}
              removeStyling
            />
          </S.ParagraphBig>
          <S.MediumSpacer />
          <S.StyledLink to={`/updates/${update.id}`} color={colors.white}>
            READ ARTICLE
          </S.StyledLink>
          <S.MediumSpacer />
        </Text>
      </Container>
    </>
  )
}
