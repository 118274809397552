import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui/core'
import { ContactModal } from './modals/ContactModal'
import { AuthRoutes } from '../types'
import { SignInModal } from './modals/SignInModal'
import { useIsLoggedIn } from '../utils/useIsLoggedIn'
import { useGetUserType } from '../utils/useGetUserType'
import { userState } from '../store/userReducer/selectors'
import { useSelector } from 'react-redux'
import { State } from '../store/userReducer'
import { useLocation } from 'react-router-dom'

const NavIcon = styled.button`
  height: 32px;
  width: 32px;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 24px;
    background: ${colors.black};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 7px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 15px;
  }

  span:nth-child(4) {
    top: 23px;
  }

  &.open span:nth-child(1) {
    opacity: 0;
  }

  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    opacity: 0;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const DropDown = styled.div`
  background-color: ${colors.white};
  position: fixed;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
`
const NavBar = styled.div`
  display: none;

  @media screen and (min-width: 1200px) {
    width: 1032px;
    background-color: ${colors.white};
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
  }
  @media screen and (min-width: 1360px) {
    width: calc(1187px + 32px);
  }
`

interface IProps {
  open?: string
}

export const Nav: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [sections, setSections] = useState<NodeListOf<HTMLElement>>()
  const [navItems, setNavItems] = useState<NodeListOf<HTMLElement>>()
  const [signInModal, setSignInModal] = useState(false)
  const isSignedIn = useIsLoggedIn()
  const state: State = useSelector(userState)
  const userType = useGetUserType()
  const location = useLocation()

  useEffect(() => {
    setSections(document.querySelectorAll('section'))
    setNavItems(document.querySelectorAll('nav .navlink'))

    const redirected = localStorage.getItem('redirectedFromPasswordDirect')
    if (redirected) {
      setSignInModal(true)
      localStorage.removeItem('redirectedFromPasswordDirect')
    }
  }, [])

  window.onscroll = () => {
    var current: string | null = ''

    sections?.forEach((section) => {
      const sectionTop = section.offsetTop
      // eslint-disable-next-line no-restricted-globals
      if (scrollY >= sectionTop - 49) {
        current = section.getAttribute('id')
      }
    })

    navItems?.forEach((li) => {
      li.classList.remove('active')
      if (current && li.classList.contains(current)) {
        li.classList.add('active')
      }
    })
  }

  const renderNavLink = (title: string, link: string, classNames: string) => {
    return (
      <S.NavLink
        className={classNames}
        smooth
        to={`/#${link}`}
        onClick={() => setOpen(false)}
      >
        {title.toUpperCase()}
      </S.NavLink>
    )
  }

  const renderNavLinks = () => {
    return (
      <>
        {renderNavLink('home', 'hero', 'navlink hero active')}
        {renderNavLink('locations', 'locations', 'navlink locations')}
        {renderNavLink(
          'creative partners',
          'creativePartners',
          'navlink creativePartners'
        )}
        {renderNavLink('about', 'about', 'navlink about')}
        {renderNavLink('updates', 'updates', 'navlink updates')}
      </>
    )
  }

  const renderSingInLink = () => {
    if (isSignedIn && userType === 'company') {
      return (
        <S.NavLink
          className="navlink"
          to={AuthRoutes.account}
          onClick={() => setOpen(false)}
        >
          MY ACCOUNT
        </S.NavLink>
      )
    }
    if (isSignedIn && userType === 'customer') {
      return (
        <S.NavLink
          className="navlink"
          to={AuthRoutes.customerAccount}
          onClick={() => setOpen(false)}
        >
          MY ACCOUNT
        </S.NavLink>
      )
    }
    if (!isSignedIn) {
      return (
        <S.NavLink
          className="navlink"
          to={location.pathname}
          onClick={() => {
            setOpen(false)
            setSignInModal(true)
          }}
        >
          SIGN IN
        </S.NavLink>
      )
    }
  }

  return (
    <>
      <nav>
        <NavIcon onClick={() => setOpen(!open)} className={open ? 'open' : ''}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </NavIcon>

        {open && (
          <DropDown>
            {renderNavLinks()}
            <S.Underline />

            <S.NavLink
              className="navlink"
              onClick={() => {
                setOpen(false)
                setShowContactModal(true)
              }}
              to={''}
            >
              CONTACT
            </S.NavLink>
            {renderSingInLink()}
          </DropDown>
        )}

        <NavBar>
          <div>{renderNavLinks()}</div>
          <div>
            <S.NavLink
              className="navlink"
              onClick={() => setShowContactModal(true)}
              to={''}
            >
              CONTACT
            </S.NavLink>
            {renderSingInLink()}
          </div>
        </NavBar>
      </nav>

      {showContactModal && (
        <ContactModal closeModal={() => setShowContactModal(false)} />
      )}

      {signInModal && <SignInModal closeModal={() => setSignInModal(false)} />}
    </>
  )
}
