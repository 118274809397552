import React, { useEffect, useState } from 'react'
import * as S from '../../../ui'
import axios from 'axios'
import { colors } from '../../../ui'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { FormInput } from '../../../components/FormInput'
import { counties } from '../../../utils/counties'
import { Select } from '../../../components/Select'
import { Icon, IconType } from '../../../ui/assets/icons'
import { PreviewLocationModal } from '../../../components/modals/PreviewLocationModal'
import {
  Checkbox,
  PreviewButton,
  IDirection,
  IFacility,
  ILocationInfo,
  ILocationTag,
  IOption,
  IProfileInfo,
  MediaInput,
  TextArea,
  MediaFormInput,
} from '../../Client/CreateLocation'
import { AccountNavBar } from '../components/AccountNavBar'
import { AuthRoutes, IImage } from '../../../types'
import { FormErrorMessage } from '../../../components/FormErrorMessage'
import { toast } from 'react-toastify'
import { Spinner } from '../../../components/Spinner'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { Modal } from '../../../components/modals/Modal'
import {
  BASE_URL,
  maxFileSize,
  fileSizeIntervalHigh,
  fileSizeIntervalLow,
  targetFileSize,
} from '../../../utils/config'
import imageCompression from 'browser-image-compression'
import { zipCodePattern } from '../../../utils/regex'

const AccountContainer = styled.div`
  padding-top: 20px;
  padding-left: 50px;
  @media screen and (min-width: 1200px) {
    padding-top: 20px;
    padding-left: 50px;
  }
`

const FlexContainer = styled(S.FlexContainerRowJustifyEnd)`
  flex-direction: column-reverse;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`

const HiddenMediaInput = styled(MediaInput)`
  display: none;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 24px 0;
`

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`

const ImageContent = styled.div`
  position: relative;
  margin: 0 20px 14px 0;
`

const ImageContentFirst = styled(ImageContent)`
  background: ${colors.orange};
  padding: 2px 2px 20px 2px;
`

const StyledDetail = styled(S.DetailsSmall)`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  position: absolute;
  right: -10px;
  bottom: 4px;
  width: 100%;
  height: auto;
  z-index: 100;
  color: ${colors.white};
`

const BtnContainer = styled.div`
  position: absolute;
  right: -14px;
  top: -14px;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
`

interface IParams {
  id: string
}

export const UpdateLocation: React.FC = () => {
  const { id } = useParams<IParams>()

  const history = useHistory()
  const [tags, setTags] = useState<ILocationTag[]>([])
  const [facilities, setFacilities] = useState<IFacility[]>([])
  const [directions, setDirections] = useState<IDirection[]>([])
  const [directionsOptions, setDirectionsOptions] = useState<IOption[]>([])
  const [tagOptions, setTagOptions] = useState<IOption[]>([])
  const [selectedTagOptions, setSelectedTagOptions] = useState<IOption[]>([])
  const [selectedDirectionOptions, setSelectedDirectionOptions] = useState<
    IOption[]
  >([])
  const [previewState, setPreviewState] = useState<ILocationInfo>()
  const [previewFacilities, setPreviewFacilities] = useState<
    string[] | undefined
  >()
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [user, setUser] = useState<IProfileInfo>()
  const [previewModal, setPreviewModal] = useState(false)
  const [facilityOption, setFacilityOption] = useState<IOption[]>([])
  const [savedSuccess, setSavedSuccess] = useState<boolean>(false)
  const [fileError, setFileError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasNewDirections, setHasNewDirections] = useState<boolean>(false)
  const [hasNewFacilities, setHasNewFacilities] = useState<boolean>(false)
  const [hasNewLocationTags, setHasNewLocationTags] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [originalImages, setOriginalImages] = useState<string[]>([])
  const [originalImagesArray, setOriginalImagesArray] = useState<IImage[]>([])
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([])
  const [imageFiles, setImageFiles] = useState<any[]>([])

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<ILocationInfo>()

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await axios
        .get(`${BASE_URL}/api/locations/${id}?populate=%2a`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const location = res.data.data.attributes
          setPreviewFacilities(
            location.facilities.data.map((d: { id: any }) => {
              return d.id
            })
          )
          if (!location) {
            setIsLoading(false)
            toast.error('Could not find location', {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
          }
          setOriginalImages(location.images.data.map((d: any) => d.id))
          setOriginalImagesArray(location.images.data)
          setImageUrls(
            location.images.data.map((d: any) => {
              return d.attributes.url
            })
          )
          setValue('address', location.address)
          setValue('county', location.county)
          setValue('description', location.description)
          setValue('title', location.title)
          setValue('request', location.request)
          setValue('squareMeters', location.squareMeters)
          setValue('rooms', location.rooms)
          setValue('exclusive', location.exclusive)
          setValue('images', location.images)
          setValue('directions', location.directions)
          setSelectedDirectionOptions(
            location.directions.data.map((d: any) => {
              return { label: d.attributes.direction, value: d.id }
            })
          )
          setValue(
            'facilities',
            location.facilities.data.map((d: { id: any }) => {
              return d.id
            })
          )
          setValue(
            'locationTags',
            location.locationTags.data.map((d: any) => {
              return d.attributes.tag
            })
          )
          setSelectedTagOptions(
            location.locationTags.data.map((d: any) => {
              return { label: d.attributes.tag, value: d.id }
            })
          )
          setValue('zipCode', location.zipCode)
        })

        .catch((err) => {
          if (err.response.status === 404) {
            history.push(AuthRoutes.customerLocations)
            toast.error('Could not find location', {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
          } else {
            setIsLoading(false)
            toast.error('Something went wrong. Try again.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
          }
        })
      const token = localStorage.getItem('token')

      await axios
        .get(`${BASE_URL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUser(res.data)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
        })
      await axios
        .get(`${BASE_URL}/api/location-tags`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTags(res.data.data)
          setTagOptions(
            res.data.data.map(
              (t: { id: number; attributes: { tag: string } }) => {
                return { label: t.attributes.tag, value: t.id }
              }
            )
          )
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Something went wrong. Try again.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })

      await axios
        .get(`${BASE_URL}/api/facilities?populate=icon`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFacilities(res.data.data)
          setFacilityOption(
            res.data.data.map((f: { attributes: { facility: string } }) => {
              return {
                label: f.attributes.facility,
                value: f.attributes.facility,
              }
            })
          )
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Something went wrong. Try again.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })

      await axios
        .get(`${BASE_URL}/api/directions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDirections(res.data.data)
          setDirectionsOptions(
            res.data.data.map(
              (f: { id: number; attributes: { direction: string } }) => {
                return {
                  label: f.attributes.direction,
                  value: f.id,
                }
              }
            )
          )
        })
        .catch((err) => {
          console.error(err)
        })
      setPreviewState(getValues())
      setIsLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (previewState) {
      sessionStorage.setItem('updateLocation', JSON.stringify(previewState))
      setTagOptionHandler()
      setDirectionOptionHandler()
    }
  }, [previewState])

  const deleteLocation = async () => {
    const token = localStorage.getItem('token')
    let config = {
      method: 'delete',
      url: `${BASE_URL}/api/location/delete-location?id=${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    await axios(config)
      .then((res) => {
        if ((res.status = 200)) {
          sessionStorage.removeItem('updateLocation')
          history.push(AuthRoutes.customerLocations)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const convertTags = (tags: any) => {
    if (typeof tags[0] === 'number') {
      return tags
    } else {
      const myTags = tagOptions?.filter((o) => {
        return tags.find((d: any) => {
          return d === o.label
        })
      })
      let result = myTags.map((a: any) => a.value)
      return result
    }
  }

  const onSubmit: SubmitHandler<ILocationInfo> = async (data: FieldValues) => {
    const convertedTags = convertTags(data.locationTags)
    const locObj = {
      title: data.title,
      address: data.address,
      county: data.county,
      zipCode: data.zipCode,
      description: data.description,
      request: data.request,
      exclusive: data.exclusive,
      squareMeters: data.squareMeters,
      rooms: data.rooms,
      facilities: data.facilities.map((d: any) => d.toString()),
      directions: hasNewDirections
        ? data.directions
        : data.directions.data.length === 0
        ? []
        : data.directions.data.map((d: any) => d.id),
      locationTags: convertedTags,
    }
    window.scroll(0, 0)
    const formData = new FormData()
    setFileError('')
    setIsLoading(true)

    let totalUploadSize: number = 0

    // Check the files
    // check if we have the correct amount of files
    if (imageUrls && (imageUrls?.length < 3 || imageUrls?.length > 8)) {
      setIsLoading(false)
      setFileError('Välj 3 - 8 bilder')
      throw Error('files need too be min 3 and max 8')
    }

    for (let i in imageFiles) {
      // Check file size of all files before we try to compress any
      if (imageFiles[i as any].size > fileSizeIntervalHigh) {
        setIsLoading(false)
        setFileError(
          `${imageFiles[i as any].name} är för stor (${
            Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
          } MB). Max tillåten filstorlek är ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
        throw new Error(
          `File ${imageFiles[i as any].name} is to large, max: ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
      }
    }

    if (imageFiles) {
      for (let i = 0; i < imageFiles.length; i++) {
        // if file size is between 10MB and 20MB, compress in client and then add it to upload list
        if (
          imageFiles[i as any].size >= fileSizeIntervalLow &&
          imageFiles[i as any].size <= fileSizeIntervalHigh
        ) {
          const options = {
            maxSizeMB: targetFileSize, // TODO: tune this value
            useWebWorker: true,
          }
          try {
            const compressedBlob = await imageCompression(
              imageFiles[i as any],
              options
            )
            var compressedFile = new File(
              [compressedBlob],
              compressedBlob.name,
              {
                type: compressedBlob.type,
              }
            )
            // console.log(
            //   `Successfully compressed ${imageFiles[i as any].name} from ${
            //     Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
            //   } MB -> ${
            //     Math.round((compressedFile.size / 1024 / 1024) * 10) / 10
            //   } MB`
            // )
            if (compressedFile.size > 8 * 1024 * 1024) {
              throw Error('Failed to compress file enough')
            }

            totalUploadSize += compressedFile.size
            formData.append('files', compressedFile)
          } catch (error) {
            setIsLoading(false)
            setFileError('')
            throw Error('Failed to handle files')
          }
        } else {
          // if file size is below 10MB, add it to upload list and let strapi compress it
          totalUploadSize +=
            Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
          formData.append('files', imageFiles[i as any])
        }
      }
      // console.log(
      //   `totalUploadSize: ${
      //     Math.round((totalUploadSize / 1024 / 1024) * 10) / 10
      //   } MB`
      // )
      if (totalUploadSize > maxFileSize) {
        setIsLoading(false)
        setFileError(
          `Max total storlek på filerna är ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
        throw new Error(
          `Max total filesize: ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
      }
    } else {
      setIsLoading(false)
      throw new Error('Could not read imageFiles')
    }
    if (imageFiles.length > 0) {
      // if we have files to upload, upload them first
      await axios
        .post(`${BASE_URL}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (response) => {
          const imageIdList = response.data.map((s: { id: number }) => s.id)
          const im = [...originalImages, ...imageIdList]
          await axios
            .put(`${BASE_URL}/api/locations/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
              data: { ...locObj, user: user?.id, images: im },
            })
            .then((res) => {
              if ((res.status = 200)) {
                setIsLoading(false)
                setSavedSuccess(true)
                sessionStorage.removeItem('updateLocation')
                window.scroll(0, 0)
                history.push(AuthRoutes.customerLocations)
              }
            })
            .catch((err) => {
              setIsLoading(false)
              setSavedSuccess(false)
              setFileError('Kunde inte ladda upp bilderna, pröva igen')
              throw Error('Could not upload the location:' + err)
            })
          // if we have deleted images, send a delete request with array of ids
          if (imagesToDelete.length > 0) {
            await axios
              .delete(`${BASE_URL}/api/location/delete-images`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                data: { imageIdList: imagesToDelete },
              })
              .catch((err) => {
                throw Error('Could not delete old images:' + err)
              })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          setFileError('Kunde inte ladda upp bilderna, pröva igen')
          throw Error('Could not upload the images:' + err)
        })
    } else {
      // if we dont have files to upload, just update the location
      await axios
        .put(`${BASE_URL}/api/locations/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: { ...(delete data.images && locObj), user: user?.id },
        })
        .then((res) => {
          if ((res.status = 200)) {
            setIsLoading(false)
            setSavedSuccess(true)
            sessionStorage.removeItem('updateLocation')
            window.scroll(0, 0)
            history.push(AuthRoutes.customerLocations)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          setSavedSuccess(false)
          setFileError('Kunde inte ladda upp bilderna, pröva igen')
          throw Error('Could not upload the location:' + err)
        })
      // if we have deleted images, send a delete request with array of ids
      if (imagesToDelete.length > 0) {
        await axios
          .delete(`${BASE_URL}/api/location/delete-images`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: { imageIdList: imagesToDelete },
          })
          .catch((err) => {
            throw Error('Could not delete old images:' + err)
          })
      }
    }
  }

  const onChangePicture = (e: any) => {
    setSavedSuccess(false)
    if (!e.target.files) return

    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    )

    let ImagesF = Object.entries(e.target.files).map((e) => e[1])
    setImageFiles([...imageFiles, ...ImagesF])
    setImageUrls([...imageUrls, ...ImagesArray])
  }

  const deleteFile = (e: any) => {
    //list of preview images
    const previewImageList = imageUrls?.filter((item, index) => index !== e)
    setImageUrls(previewImageList)

    // list of File
    const newArr = imageFiles?.filter(
      (item, index) => index !== e - originalImagesArray.length
    )
    setImageFiles(newArr)

    // list of new images id to be deleted
    const imageId = originalImages?.find((item, index) => index === e)
    if (imageId) {
      setImagesToDelete([...imagesToDelete, imageId as string])
    }

    // list of id to be deleted
    const oia = originalImagesArray?.filter((item, index) => index !== e)
    setOriginalImagesArray(oia)
    setOriginalImages(oia.map((d: any) => d.id))
  }

  useEffect(() => {
    if (previewModal) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = 'static'
    }
  }, [previewModal])

  const setTagOptionHandler = () => {
    interface ILocationTagData {
      data: ILocationTag[]
    }
    const dataTags = previewState?.locationTags as unknown as ILocationTagData
    const myTags = tagOptions?.filter((o) => {
      return dataTags.data?.find((d) => {
        return d.attributes.tag === o.label
      })
    })

    if (!dataTags || !dataTags.data) {
      const myTags = tagOptions?.filter((o) => {
        return previewState?.locationTags.find((d) => {
          if (typeof d === 'string') {
            return d === o.label
          } else {
            return d === parseInt(o.value)
          }
        })
      })
      return setSelectedTagOptions(myTags)
    }
    setSelectedTagOptions(myTags)
  }

  const setDirectionOptionHandler = () => {
    interface IDirectionData {
      data: IDirection[]
    }
    const dataDirections = previewState?.directions as unknown as IDirectionData
    const myDirections = directionsOptions?.filter((o) => {
      return dataDirections.data?.find((d) => {
        return d.attributes.direction === o.label
      })
    })

    if (!dataDirections || !dataDirections.data) {
      const myDirections = directionsOptions?.filter((o) => {
        return previewState?.directions.find((d) => {
          return d === parseInt(o.value)
        })
      })
      return setSelectedDirectionOptions(myDirections)
    }
    setSelectedDirectionOptions(myDirections)
  }

  return (
    <>
      <ScrollToTopOnMount />
      <PreviewButton
        disabled={!previewState}
        className={previewState ? '' : 'disabled'}
        onClick={() => {
          setPreviewModal(true)
        }}
      >
        Förhandsgranska
      </PreviewButton>

      <AccountNavBar />

      <S.Wrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <S.ContentContainer>
              <S.PaddingContainer>
                <S.LargeSpacer />
                <S.H1>MY ACCOUNT / MY LOCATION</S.H1>
              </S.PaddingContainer>
            </S.ContentContainer>
            <S.ContentContainer maxWidth="700px">
              <S.PaddingContainer>
                <S.LargeSpacer />

                <form
                  onChange={() => {
                    setPreviewFacilities(getValues().facilities)
                    setPreviewState(getValues())
                    setSavedSuccess(false)
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormInput label="Titel på location*">
                    <S.InputField
                      placeholder={'TITEL'}
                      {...register('title', {
                        required: {
                          value: true,
                          message: 'Ange titel',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 tecken',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.title?.message && (
                    <FormErrorMessage message={errors.title?.message} />
                  )}
                  <FormInput label="Adress*">
                    <S.InputField
                      placeholder={'ADRESS'}
                      {...register('address', {
                        required: {
                          value: true,
                          message: 'Ange adress',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 tecken',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.address?.message && (
                    <FormErrorMessage message={errors.address?.message} />
                  )}
                  <FormInput label="Postnummer*">
                    <S.InputField
                      placeholder={'POSTNUMMER'}
                      {...register('zipCode', {
                        required: {
                          value: true,
                          message: 'Ange postnummer',
                        },
                        pattern: {
                          value: zipCodePattern,
                          message: 'Ange giltig postnummer',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.zipCode?.message && (
                    <FormErrorMessage message={errors.zipCode?.message} />
                  )}
                  <FormInput label="Län">
                    <Select
                      options={counties}
                      value={{
                        value: previewState?.county ?? '',
                        label: previewState?.county ?? '',
                      }}
                      placeholder="LÄN"
                      {...register('county', {
                        required: {
                          value: true,
                          message: 'Välj län',
                        },
                      })}
                      getOptionValue={(o) => o.value}
                      getOptionLabel={(o) => o.label}
                      onChange={(c) => {
                        c && setValue('county', c?.label)
                        setSavedSuccess(false)
                        setPreviewState(getValues())
                      }}
                    />
                  </FormInput>
                  {errors.county?.message && (
                    <FormErrorMessage message={errors.county?.message} />
                  )}
                  <FormInput label="Bilder*">
                    <MediaFormInput>
                      <label
                        className="fileInput"
                        htmlFor="file"
                        style={{
                          borderRadius: '1px',
                          width: '100%',
                          background: colors.orange,
                          padding: '15px',
                          margin: '0',
                          cursor: 'pointer',
                        }}
                      >
                        <S.FlexContainerRowCenterCenter>
                          <Icon icon={IconType.PaperClip} />
                          <S.SmallSpacerRow />
                          <S.DetailsBig color={colors.white}>
                            LADDA UPP BILDER
                          </S.DetailsBig>
                        </S.FlexContainerRowCenterCenter>
                      </label>
                      <S.ExtraSmallSpacer />
                      <HiddenMediaInput
                        type="file"
                        multiple
                        id="file"
                        accept="image/*"
                        placeholder={'Bilder'.toUpperCase()}
                        {...register('images')}
                        onChange={onChangePicture}
                      />
                    </MediaFormInput>
                    <ImageContainer>
                      {imageUrls?.map((item, index) => {
                        if (index === 0) {
                          return (
                            <ImageContentFirst key={item}>
                              <Image src={item} alt="" />
                              <BtnContainer>
                                <S.InvisibleButton
                                  onClick={(e) => deleteFile(index)}
                                  padding={'10px'}
                                >
                                  <Icon
                                    icon={IconType.Close}
                                    width={'12px'}
                                    color={colors.black}
                                  />
                                </S.InvisibleButton>
                              </BtnContainer>
                              <StyledDetail className="detail">
                                UTVALD BILD
                              </StyledDetail>
                            </ImageContentFirst>
                          )
                        } else {
                          return (
                            <ImageContent key={item}>
                              <Image src={item} alt="" />
                              <BtnContainer>
                                <S.InvisibleButton
                                  onClick={(e) => deleteFile(index)}
                                  padding={'10px'}
                                >
                                  <Icon
                                    icon={IconType.Close}
                                    width={'12px'}
                                    color={colors.black}
                                  />
                                </S.InvisibleButton>
                              </BtnContainer>
                            </ImageContent>
                          )
                        }
                      })}
                    </ImageContainer>
                    <S.DetailsSmall></S.DetailsSmall>
                    <S.SmallSpacer />
                    <S.Paragraph align="center">
                      Ladda upp 3 - 8 bilder. Den utvalda bilden kan komma att
                      hamna på startsidan. Du kan också ladda upp en planritning
                      om du har det. Max filstorlek{' '}
                      {Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) /
                        10}
                      MB per bild och totalt{' '}
                      {Math.round((maxFileSize / 1024 / 1024) * 10) / 10}MB.
                    </S.Paragraph>
                  </FormInput>

                  {fileError ? (
                    <FormErrorMessage message={fileError} />
                  ) : imageUrls &&
                    imageUrls.length > 0 &&
                    (imageUrls.length < 3 || imageUrls.length > 8) ? (
                    <FormErrorMessage message={'Välj 3 - 8 bilder'} />
                  ) : null}

                  <S.MediumSpacer />
                  <FormInput label="Beskrivning av din location">
                    <TextArea
                      minHeight="280px"
                      placeholder={'Beskriv din location, do it with passion'.toUpperCase()}
                      {...register('description', {
                        required: {
                          value: true,
                          message: 'Beskriv location',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.description?.message && (
                    <FormErrorMessage message={errors.description?.message} />
                  )}
                  <FormInput label="Speciella önskemål till hyresgäster (om du har)">
                    <TextArea
                      value={getValues().request}
                      placeholder={'Speciella önskemål till hyresgäst'.toUpperCase()}
                      {...register('request')}
                    />
                  </FormInput>
                  <S.MediumSpacer />
                  <S.FlexContainerRowAlignCeter>
                    <S.SmallSpacerRow />
                    <Checkbox type="checkbox" {...register('exclusive')} />
                    <S.SmallSpacerRow />
                    <S.ParagraphSmall>
                      Det här är en exklusiv location
                    </S.ParagraphSmall>
                  </S.FlexContainerRowAlignCeter>
                  <S.SmallSpacer />
                  <S.FlexContainerRow>
                    <S.Spacer width="45px" />
                    <S.ParagraphSmall>
                      Exklusiv location innebär att priset för uthyrning kan
                      komma att överstiga 30.000SEK / dag
                    </S.ParagraphSmall>
                  </S.FlexContainerRow>
                  <S.MediumSpacer />
                  <FormInput label="Kvadratmeter*">
                    <S.InputField
                      placeholder={'KVM'}
                      type="number"
                      {...register('squareMeters', {
                        required: {
                          value: true,
                          message: 'Ange kvadratmeter',
                        },
                        max: {
                          value: 9999,
                          message: 'Ange giltig värde',
                        },
                        min: {
                          value: 1,
                          message: 'Ange giltig värde',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.squareMeters?.message && (
                    <FormErrorMessage message={errors.squareMeters?.message} />
                  )}
                  <FormInput label="Antal rum*">
                    <S.InputField
                      type="number"
                      placeholder={'Antal rum'.toUpperCase()}
                      {...register('rooms', {
                        required: {
                          value: true,
                          message: 'Ange antal rum',
                        },
                        max: {
                          value: 99,
                          message: 'Ange giltig värde',
                        },
                        min: {
                          value: 1,
                          message: 'Ange giltig värde',
                        },
                      })}
                    />
                  </FormInput>
                  {errors.rooms?.message && (
                    <FormErrorMessage message={errors.rooms?.message} />
                  )}
                  <FormInput label="Väderstreck">
                    <Select
                      isMulti
                      options={directionsOptions}
                      value={selectedDirectionOptions}
                      placeholder="VÄDERSTRECK"
                      {...register('directions', { required: false, max: 4 })}
                      getOptionValue={(o) => o.value}
                      getOptionLabel={(o) => o.label}
                      onChange={(selectedDirections) => {
                        setSavedSuccess(false)
                        const matches = directions.filter((t) => {
                          return selectedDirections.find((s) => {
                            return s.label === t.attributes.direction
                          })
                        })
                        selectedDirections &&
                          setValue(
                            'directions',
                            matches.map((s: { id: number }) => {
                              return s.id
                            })
                          )
                        setDirectionOptionHandler()
                        setPreviewState(getValues())
                        setHasNewDirections(true)
                      }}
                    />
                  </FormInput>
                  {errors.rooms?.message && (
                    <FormErrorMessage message={errors.rooms?.message} />
                  )}
                  <S.MediumSpacer />
                  <S.FlexContainerColumn>
                    <S.ParagraphSmall>
                      Markera vilka faciliteter som finns
                    </S.ParagraphSmall>
                    <S.SmallSpacer />
                    {facilities.map((f) => {
                      const match =
                        getValues() &&
                        Array.isArray(getValues().facilities) &&
                        getValues().facilities.find(
                          (x) => x.toString() === f.id.toString()
                        )
                      return (
                        <div key={f.id}>
                          <S.FlexContainerRowAlignCeter>
                            <S.SmallSpacerRow />
                            <Checkbox
                              value={f.id}
                              type="checkbox"
                              checked={match ? true : false}
                              {...register('facilities')}
                            />
                            <S.SmallSpacerRow />
                            <S.DetailsSmall>
                              {f.attributes.facility}
                            </S.DetailsSmall>
                          </S.FlexContainerRowAlignCeter>
                          <S.ExtraSmallSpacer />
                        </div>
                      )
                    })}
                  </S.FlexContainerColumn>
                  <S.MediumSpacer />
                  <FormInput label="Sökord*">
                    <Select
                      isMulti
                      isOptionDisabled={() => {
                        return (
                          getValues().locationTags &&
                          getValues().locationTags.length >= 15
                        )
                      }}
                      options={tagOptions}
                      value={selectedTagOptions}
                      placeholder="SÖKORD"
                      {...register('locationTags', {
                        required: {
                          value: true,
                          message: 'Välj 1 - 15 sökord',
                        },
                        minLength: 1,
                        maxLength: 15,
                      })}
                      getOptionValue={(o) => o.value}
                      getOptionLabel={(o) => o.label}
                      onChange={(selectedTags) => {
                        setSavedSuccess(false)
                        const matches = tags.filter((t) => {
                          return selectedTags.find((s) => {
                            return s.label === t.attributes.tag
                          })
                        })
                        selectedTags &&
                          setValue(
                            'locationTags',
                            matches.map((s: { id: number }) => s.id)
                          )
                        setTagOptionHandler()
                        setPreviewState(getValues())
                        setHasNewLocationTags(true)
                      }}
                    />
                  </FormInput>
                  {errors.locationTags?.message && (
                    <FormErrorMessage message={errors.locationTags?.message} />
                  )}
                  <S.LargeSpacer />
                  <FlexContainer>
                    <S.TextButton
                      onClick={() => {
                        window.scroll(0, 0)
                        history.push(AuthRoutes.customerAccount)
                      }}
                      color={colors.black}
                    >
                      Avbryt
                    </S.TextButton>
                    <S.SmallSpacer />
                    <S.ExtraLargeSpacerRow />
                    <S.WhiteButton
                      onClick={(e) => (
                        e.preventDefault(), setDeleteModal(true)
                      )}
                    >
                      Ta bort location
                    </S.WhiteButton>
                    <S.SmallSpacer />
                    <S.ExtraLargeSpacerRow />
                    <S.WhiteButton type="submit">Spara ändringar</S.WhiteButton>
                  </FlexContainer>

                  <S.MediumSpacer />
                  <S.VerticalMargin />
                </form>

                <S.MediumSpacer />
              </S.PaddingContainer>
            </S.ContentContainer>
          </>
        )}
      </S.Wrapper>

      {previewModal && previewState && (
        <PreviewLocationModal
          location={previewState}
          previewFacilities={previewFacilities}
          imageUrls={
            imageUrls.length > 0
              ? imageUrls
              : previewState.images.data.map((d) => {
                  return d.attributes.url
                })
          }
          closeModal={() => {
            setPreviewModal(false)
          }}
        />
      )}
      {deleteModal && (
        <Modal
          closeModal={() => {
            setDeleteModal(false)
          }}
        >
          <S.H3>Are you sure?</S.H3>
          <S.ExtraSmallSpacer />
          <S.Paragraph>Den här åtgärden går inte att ångra.</S.Paragraph>
          <S.ExtraLargeSpacer />
          <S.FlexContainerRowJustifyBetween>
            <S.StyledLinkButton onClick={() => setDeleteModal(false)}>
              Tillbaka
            </S.StyledLinkButton>
            <S.ModalButtonPrimary onClick={deleteLocation} width="max-content">
              Ta bort location
            </S.ModalButtonPrimary>
          </S.FlexContainerRowJustifyBetween>
        </Modal>
      )}
    </>
  )
}
