import React from 'react'
import ReactSelect, { Props, GroupBase } from 'react-select'
import { colors } from '../ui'

export function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      styles={{
        container: (provided) => ({
          ...provided,
          border: `1px solid ${colors.gray}`,
          borderRadius: '1px',
          width: '100%',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          minHeight: '54px',
          height: 'calc(100% - 8px)',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          color: state.isDisabled
            ? colors.gray
            : state.isFocused
            ? colors.yellow
            : colors.black,
          background: 'none',
          cursor: state.isDisabled ? 'default' : 'pointer',
        }),
        menu: (provided) => ({
          ...provided,
          width: '100%',
          left: '0',
          padding: '16px',
          backgroundColor: colors.white,
          border: `1px solid ${colors.gray}`,
          textTransform: 'uppercase',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
        }),
        multiValue: (state) => ({
          textTransform: 'uppercase',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px',
          borderRadius: '1px',
          margin: '0 8px 8px 0',
          position: 'relative',
          top: '4px',
          border: `1px solid ${colors.gray}`,
          color: state.isFocused ? colors.yellow : colors.black,
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
          marginLeft: '8px',
        }),

        control: (provided) => ({
          ...provided,
          width: '100%',
          minHeight: '100%',
          height: 'max-content',
          borderRadius: '8px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.02em',
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }),

        valueContainer: (provided) => ({
          ...provided,
          padding: '4px 8px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1
          const transition = 'opacity 300ms'

          return { ...provided, opacity, transition }
        },
      }}
    />
  )
}
