import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Dropdown } from '../../../components/Dropdown'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { FavoritesButton } from '../../../components/FavoritesButton'
import { AuthRoutes, IPartner, NonAuthRoutes } from '../../../types'
import { BASE_URL } from '../../../utils/config'

const Card = styled(S.FlexContainerColumn)`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  word-wrap: break-word;
  align-items: flex-start;
  position: relative;
  margin-bottom: 16px;

  @media screen and (min-width: 1200px) {
    height: 540px;
    margin-bottom: 32px;
  }
`

const Image = styled.img`
  height: 60vw;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 100%;
`

const Text = styled.div`
  display: block;
  white-space: normal;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(61, 37, 35);
  padding: 8px 16px;

  @media screen and (min-width: 1200px) {
    background: rgba(61, 37, 35, 0.9);
    padding: 24px 158px;
  }
`

interface IProps {
  p: IPartner
  isSignedIn: boolean
  isFavorite: boolean
  userType?: string | undefined
  toggleFavorite: (item: IPartner, isFavorite: boolean) => void
}

export const AllCreativePartners: React.FC<IProps> = (props) => {
  const history = useHistory()
  return (
    <Card
      className={'card large'}
      style={{
        cursor: 'pointer',
      }}
      onClick={() =>
        history.push(`${NonAuthRoutes.creativePartners}/${props.p.id}`)
      }
    >
      {props.isSignedIn && props.userType === 'company' && (
        <FavoritesButton
          isFavorite={props.isFavorite}
          creativePartner
          onClick={() => {
            props.toggleFavorite(props.p, props.isFavorite)
          }}
        />
      )}

      <Image
        src={
          props.p.attributes.image.data &&
          props.p.attributes.image.data[0].attributes.url
        }
        alt={props.p.attributes.image.data[0].attributes.alternativeText}
      />

      <Text className="text">
        <S.ExtraSmallSpacer />
        <S.H4 color={colors.white}>
          {props.p.attributes.profession &&
            props.p.attributes.profession.data &&
            props.p.attributes.profession.data.attributes.profession}
        </S.H4>
        <S.ExtraSmallSpacer />
        <S.ParagraphBig color={colors.white}>
          {props.p.attributes.county}
        </S.ParagraphBig>
        <S.ExtraSmallSpacer />
      </Text>
    </Card>
  )
}
