import React, { useEffect, useState } from 'react'
import axios from 'axios'
import * as S from '../../../ui'
import styled from 'styled-components'
import { colors } from '../../../ui/core'
import { Images } from '../../../ui/assets/images'
import { useHistory } from 'react-router-dom'
import { SignInModal } from '../../../components/modals/SignInModal'
import { useIsLoggedIn } from '../../../utils/useIsLoggedIn'
import { Icon, IconType } from '../../../ui/assets/icons'
import { AuthRoutes, ISeo, NonAuthRoutes } from '../../../types'
import { useSelector } from 'react-redux'
import { State } from '../../../store/userReducer'
import { userState } from '../../../store/userReducer/selectors'
import { useGetUserType } from '../../../utils/useGetUserType'
import { BASE_URL } from '../../../utils/config'
import { Seo } from '../../../components/Seo'

interface IHeroStyles {
  image?: string
}

const HeroImage = styled.div<IHeroStyles>`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  position: relative;

  video {
    position: absolute;
    height: calc(100vh - 42px);
    width: 100%;
    left: 0;
    object-fit: cover;

    @media screen and (min-width: 1200px) {
      height: calc(100vh - 169px);
    }
  }
`

const ImageOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    95.2deg,
    rgba(16, 13, 13, 0.6) 39.02%,
    rgba(52, 36, 36, 0) 114.39%
  );
  position: relative;
  z-index: 9;

  @media screen and (min-width: 1200px) {
    background: linear-gradient(
      95.2deg,
      rgba(16, 13, 13, 0.324) 5.98%,
      rgba(52, 36, 36, 0) 114.39%
    );
  }
`

const VideoButton = styled(S.ButtonSmallLink)`
  width: max-content;
  position: absolute;
  bottom: 76px;
  left: 16px;
  z-index: 10;

  @media screen and (min-width: 1200px) {
    left: 64px;
  }
`

const LandingPage = styled(S.ContentContainer)`
  height: calc(100vh - 42px);
  display: flex;
  align-items: center;
  max-width: calc(100vw - 32px);
  position: relative;
  z-index: 10;

  @media screen and (min-width: 1200px) {
    height: calc(100vh - 169px);
  }
`

const LandingText = styled.div`
  padding-bottom: 148px;
  height: min-content;
  max-width: 1250px;

  &.desktop {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    &.mobile {
      display: none;
    }

    &.desktop {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 78px;
      padding-bottom: 72px;

      .spacer {
        width: 64px;
      }

      h1,
      p {
        color: ${colors.black};
      }
    }
  }
`

interface IHeroContent {
  quote: string
  text: string
  videoUrl: string
  video: {
    data: {
      attributes: {
        url: string
      }
    }
  }
  image: {
    data: {
      attributes: {
        url: string
      }
    }
  }
  seo: ISeo
}

export const Hero: React.FC = () => {
  const history = useHistory()
  const getUserType = useGetUserType()
  const [signedIn, setSignedIn] = useState(useIsLoggedIn())
  const [signInModal, setSignInModal] = useState(false)
  const state: State = useSelector(userState)
  const [userType, setUserType] = useState<string | null>()
  const [heroContent, setHeroContent] = useState<IHeroContent>()

  useEffect(() => {
    if (state.userInfo?.type) {
      setUserType(state.userInfo.type)
    } else {
      setUserType(getUserType)
    }
  }, [])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        video: '*',
        seo: {
          populate: {
            metaImage: '*',
          },
        },
      },
    })
    axios.get(`${BASE_URL}/api/home?${query}`).then((res) => {
      const { quote, text, videoUrl, image, video, seo } =
        res.data.data.attributes
      setHeroContent({
        quote: quote,
        text: text,
        videoUrl: videoUrl,
        image: image,
        video: video,
        seo: seo,
      })
    })
  }, [])

  const renderLandingText = (className: string) => {
    return (
      <>
        {heroContent?.seo && <Seo metaData={heroContent.seo} />}
        <LandingText className={className}>
          <S.FlexContainerColumn>
            <S.H1 color={colors.white}>{heroContent?.quote}</S.H1>
            <S.SmallSpacer />
            <S.ParagraphBig color={colors.white}>
              {heroContent?.text}
            </S.ParagraphBig>
          </S.FlexContainerColumn>
          <S.MediumSpacer className="spacer" />
          <div>
            <S.ButtonPrimary
              onClick={() => {
                if (signedIn) {
                  let path =
                    userType === 'company'
                      ? AuthRoutes.locations
                      : userType === 'customer'
                      ? AuthRoutes.customerLocations
                      : NonAuthRoutes.signUp
                  history.push(path)
                } else {
                  setSignInModal(true)
                }
              }}
            >
              FIND LOCATION
            </S.ButtonPrimary>
            <S.SmallSpacer />
            <S.ButtonPrimary
              onClick={() => {
                if (signedIn) {
                  let path =
                    userType === 'company'
                      ? AuthRoutes.account
                      : userType === 'customer'
                      ? AuthRoutes.customerAccount
                      : NonAuthRoutes.signUp
                  history.push(path)
                } else {
                  setSignInModal(true)
                }
              }}
            >
              LIST LOCATION
            </S.ButtonPrimary>
          </div>
        </LandingText>
      </>
    )
  }

  return (
    <>
      <HeroImage image={heroContent?.image?.data.attributes.url}>
        {heroContent?.video?.data.attributes.url && (
          <video className="video_bg" autoPlay loop playsInline muted>
            <source
              src={heroContent?.video?.data.attributes.url}
              type="video/mp4"
            />
          </video>
        )}
        <ImageOverlay>
          <S.Wrapper>
            <LandingPage>{renderLandingText('mobile')}</LandingPage>
          </S.Wrapper>
        </ImageOverlay>
        <VideoButton href={heroContent?.videoUrl} target={'_blank'}>
          <Icon icon={IconType.Play} />
          <S.SmallSpacerRow />
          Se hela filmen
        </VideoButton>
      </HeroImage>
      <S.VerticalMargin />
      <S.Wrapper>{renderLandingText('desktop')}</S.Wrapper>
      {signInModal && (
        <SignInModal
          closeModal={() => setSignInModal(false)}
          path={'locations'}
        />
      )}
    </>
  )
}
