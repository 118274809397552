import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { StyledMarkdownParser } from '../../../components/StyledParsedMarkdown'
import { TextButtonCTA } from '../../../components/TextButtonCTA'
import * as S from '../../../ui'
import { colors } from '../../../ui/core'
import { dateWithMonthName, formatTime } from '../../../utils/dateFormatting'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'
import { Suggested } from './Suggested'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'
import { IUpdate } from '../../../types'
import { Seo } from '../../../components/Seo'

const MetaContainer = styled(S.FlexContainerColumn)`
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`

interface IParams {
  id: string
}

const StyledArticleContianer = styled(S.ArticleContainer)`
  h2 {
    font-family: 'Archivo Black', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${(props) => props.color ?? colors.black};

    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }
  }

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: ${(props) => props.color ?? colors.black};

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }
`

export const SingleUpdate: React.FC = () => {
  const { id } = useParams<IParams>()
  const [update, setUpdate] = useState<IUpdate>()
  const [showSuggested, setShowSuggested] = useState(true)

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/updates/${id}?populate=seo.metaImage`)
      .then((res) => {
        setUpdate(res.data.data)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [id])

  return (
    <>
      {update?.attributes?.seo && <Seo metaData={update.attributes.seo} />}
      <ScrollToTopOnMount />
      <S.Wrapper>
        <StyledArticleContianer maxWidth="700px">
          <S.PaddingContainer>
            <S.MediumSpacer />
            <S.H1>{update?.attributes?.title}</S.H1>
            <S.ExtraSmallSpacer />
            <MetaContainer width="100%">
              <S.FlexContainerRow>
                {update?.attributes?.updateCategory?.data?.attributes
                  ?.category && (
                  <>
                    <S.DetailsSmall color={colors.gray}>
                      <b>
                        {
                          update?.attributes?.updateCategory?.data?.attributes
                            ?.category
                        }
                      </b>
                    </S.DetailsSmall>
                    <S.SmallSpacerRow />
                  </>
                )}
                {update?.attributes?.publishedAt && (
                  <>
                    <S.DetailsSmall color={colors.gray}>
                      PUBLISHED: {formatTime(update?.attributes?.publishedAt)}{' '}
                      {dateWithMonthName(update?.attributes?.publishedAt, true)}
                    </S.DetailsSmall>
                    <S.SmallSpacerRow />
                  </>
                )}
              </S.FlexContainerRow>
              {update?.attributes?.author && (
                <S.DetailsSmall color={colors.gray}>
                  by: {update?.attributes?.author}
                </S.DetailsSmall>
              )}
            </MetaContainer>
            <S.LargeSpacer />
            {update?.attributes?.text && (
              <StyledMarkdownParser text={update?.attributes?.text} />
            )}
            <S.VerticalMargin />
          </S.PaddingContainer>
        </StyledArticleContianer>
        <S.ContentContainer>
          <S.PaddingContainer>
            <TextButtonCTA
              text="Do you like this post?"
              buttonText="SHARE"
              shareUrl={`bonanzalocation.com/updates/${id}`}
              title={update?.attributes.title}
              reverse
            />
            <S.VerticalMargin />
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
      {update?.attributes?.updateCategory?.data?.attributes?.category &&
        showSuggested && (
          <>
            <Suggested
              exclude={update?.id}
              hasContent={setShowSuggested}
              title={`more from ${update?.attributes?.updateCategory?.data?.attributes?.category}`}
              category={
                update?.attributes?.updateCategory?.data?.attributes?.category
              }
            />
            <S.VerticalMargin />
          </>
        )}
    </>
  )
}
