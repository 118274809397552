import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AuthRoutes } from '../../../../../types'
import * as S from '../../../../../ui'
import { colors } from '../../../../../ui/core'
import { signOut } from '../../../../../utils/signOut'

const NavBar = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    width: 100%;
    height: 70px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: 1360px) {
    width: 100%;
  }
`

const StyledNav = styled.nav`
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
    height: 70px;
    width: 100%;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.gray};
  }
`

interface IProps {
  open?: string
}

export const CompanyAccountNavBar: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false)
  const [sections, setSections] = useState<NodeListOf<HTMLElement>>()
  const [navItems, setNavItems] = useState<NodeListOf<HTMLElement>>()

  useEffect(() => {
    setSections(document.querySelectorAll('section'))
    setNavItems(document.querySelectorAll('nav .navlink'))
  }, [])

  window.onscroll = () => {
    var current: string | null = ''

    sections?.forEach((section) => {
      const sectionTop = section.offsetTop
      // eslint-disable-next-line no-restricted-globals
      if (scrollY >= sectionTop - 49) {
        current = section.getAttribute('id')
      }
    })

    navItems?.forEach((li) => {
      li.classList.remove('active')
      if (current && li.classList.contains(current)) {
        li.classList.add('active')
      }
    })
  }

  const renderNavLink = (title: string, link: string) => {
    return (
      <S.NavLink
        className="navLink"
        to={`${link}`}
        onClick={() => setOpen(false)}
      >
        {title.toUpperCase()}
      </S.NavLink>
    )
  }

  const renderNavLinks = () => {
    return (
      <>
        {renderNavLink('saved', AuthRoutes.favorites)}
        {renderNavLink('profile information', AuthRoutes.account)}
      </>
    )
  }

  const renderSignOut = () => {
    return (
      <S.StyledLinkButton
        padding="16px"
        onClick={() => {
          signOut(true)
        }}
      >
        SIGN OUT
      </S.StyledLinkButton>
    )
  }

  return (
    <StyledNav className="companyaccountnav">
      <NavBar>
        <div>
          {renderNavLinks()}
          {renderSignOut()}
        </div>
      </NavBar>
    </StyledNav>
  )
}
