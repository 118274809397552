import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

const Background = styled(S.PaddingContainer)`
  background-color: #00000098;
  z-index: 100;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
  overflow: scroll;

  img {
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    padding: 62px;
  }
`

const TopContainer = styled(S.FlexContainerRow)`
  justify-content: end;
  padding-bottom: 18px;

  @media screen and (min-width: 1200px) {
    padding-bottom: 32px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
`

interface IProps {
  close: () => void
  images?: {
    attributes: {
      url: string
      alternativeText: string
    }
  }[]
  urls?: string[]
}

export const FullscreenImages: React.FC<IProps> = (props) => {
  return (
    <Background onClick={props.close}>
      <S.ContentContainer>
        <TopContainer>
          <S.InvisibleButton onClick={props.close}>
            <Icon icon={IconType.Close} color={colors.white} />
          </S.InvisibleButton>
        </TopContainer>
        <S.FlexContainerColumnCenterCenter>
          {props.images?.map((image, i) => {
            return (
              <ImageContainer key={i}>
                <img
                  onClick={(e) => e.stopPropagation()}
                  style={{ cursor: 'default' }}
                  src={image.attributes.url}
                  alt={image.attributes.alternativeText}
                />
                <S.MediumSpacer />
              </ImageContainer>
            )
          })}
          {props.urls?.map((url, i) => {
            return (
              <ImageContainer key={i}>
                <img
                  onClick={(e) => e.stopPropagation()}
                  style={{ cursor: 'default' }}
                  src={url}
                  alt={'image ' + (i + 1)}
                />
                <S.MediumSpacer />
              </ImageContainer>
            )
          })}
        </S.FlexContainerColumnCenterCenter>
      </S.ContentContainer>
    </Background>
  )
}
