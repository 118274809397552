import axios from 'axios'
import { BASE_URL } from './config'

export const useIsVerified = async (id: number) => {
  await axios
    .post(`${BASE_URL}/api/verified/checkHasSigned`, {
      data: { id: id },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw Error('Could not check if user is verified' + err)
    })
}
