import React from 'react'
import styled from 'styled-components'
import { colors } from '../ui'

interface IStyleProps {
  columns?: number | undefined
}

const StyledMarkdown = styled.div<IStyleProps>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;

  @media screen and (min-width: 800px) {
    column-count: ${(props) => props.columns ?? null};
    column-gap: ${(props) => (props.columns ? '22px' : null)};
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    line-height: 160%;
    column-gap: ${(props) => (props.columns ? '32px' : null)};
  }

  h1 {
    font-family: 'Archivo Black', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: ${colors.black};
    text-transform: uppercase;
    margin: 7px 0;

    @media screen and (min-width: 1200px) {
      font-size: 40px;
    }
  }

  li {
    list-style: none;
  }
  .bullet {
    background-color: ${colors.blue};
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    margin-top: 8px;
  }

  h2 {
    font-family: 'Archivo Black', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 90%;
    letter-spacing: 0.04em;
    color: ${colors.black};
    text-transform: uppercase;
    margin: 7px 0;

    @media screen and (min-width: 1200px) {
      font-size: 64px;
    }
  }

  h3 {
    font-family: 'Archivo Black', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: ${(props) => props.color && colors.black};
    text-transform: uppercase;
    margin: 7px 0;

    @media screen and (min-width: 1200px) {
      font-size: 32px;
    }
  }

  h4 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${(props) => props.color && colors.black};
    text-transform: uppercase;
    margin: 7px 0;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  h5 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: ${(props) => props.color && colors.black};
    text-transform: uppercase;
    margin: 7px 0;

    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }

  h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: ${(props) => props.color && colors.black};
    margin: 7px 0;
    text-transform: uppercase;

    @media screen and (min-width: 1200px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    text-transform: uppercase;
    color: ${colors.black};
    cursor: pointer;

    :hover {
      color: ${colors.yellow};
    }

    @media screen and (min-width: 1200px) {
      font-size: 18px;
    }
  }
  img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
    object-position: center;
  }

  .iframeContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

interface IProps {
  text: string
  columns?: number
  onlyP?: boolean
  removeStyling?: boolean
}

export const StyledMarkdownParser: React.FC<IProps> = (props) => {
  const toHTML = props.text
    .replace(/^###### (.*$)/gim, '</p><h6>$1</h6><p>') // h6
    .replace(/^##### (.*$)/gim, '</p><h5>$1</h5><p>') // h5
    .replace(/^#### (.*$)/gim, '</p><h4>$1</h4><p>') // h4
    .replace(/^### (.*$)/gim, '</p><h3>$1</h3><p>') // h3
    .replace(/^## (.*$)/gim, '</p><h2>$1</h2><p>') // h2
    .replace(/^# (.*$)/gim, '</p><h1>$1</h1><p>') // h1
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>') // bold
    .replace(/(?:[ ])\_(.*?)\_/gim, '<i>$1</i>') // italic
    .replace(/^\_(.*?)\_/gim, '<i>$1</i>') // italic
    .replace(/\~~(.*)\~~/gim, '<s>$1</s>') // strike through
    .replace(
      /^\-(.*)/gim,
      "</p><div style='display: flex; flex-direction: row; margin-bottom: 10px;';><div class='bullet'></div><li>$1</li></div><p>"
    ) // list item ul
    .replace(/^(\d+)\.*(.*)/gm, '</p><li>$1. $2</li><p>') // list item ol
    .replace(/^!\[(.*)\]\((.*)\)/gm, "</p><img src='$2' alt='$1' /><p>") // img
    .replace(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
      "</p><div class='iframeContainer'><iframe class='responsive-iframe' src='https://www.youtube.com/embed/$5'></iframe></div><p>"
    ) // youtube link
    .replace(
      /\[.*\]\(https:\/\/(www\.)?youtube\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\)/gm,
      "</p><div class='iframeContainer'><iframe class='responsive-iframe' src='https://www.youtube.com$2'></iframe></div><p>"
    ) // youtube link
    .replace(
      /\[(.*?)\]+\(https:\/\/(www\.)?bonanzalocation\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\)\B/gm,
      `<a href='$2'>$1</a>`
    ) // internal link
    .replace(/\[(.*?)\]+\((.*?)\)/gm, `<a href='$2' target='_blank'>$1</a>`) // link
    .replace(/^>(.*)/gim, '<blockquote>$1</blockquote>')
    .split('\n\n')
    .join('<br /><br />') // br
    .trim() // using trim method to remove whitespace

  // remove everything but unstyled text
  const toPtag = props.text
    .replace('######', '') // h6
    .replace('#####', '') // h5
    .replace('####', '') // h4
    .replace('###', '') // h3
    .replace('##', '') // h2
    .replace('#', '') // h1
    .replace(/\*\*(.*)\*\*/gim, '$1') // bold
    .replace(/(?:[ ])\_(.*?)\_/gim, '$1') // italic
    .replace(/^\_(.*?)\_/gim, '$1') // italic
    .replace(/\~~(.*)\~~/gim, '') // strike through
    .replace(/^\-(.*)/gim, '') // list item ul
    .replace(/^(\d+)\.*(.*)/gm, '') // list item ol
    .replace(/^!\[(.*)\]\((.*)\)/gm, '') // img
    .replace(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
      ''
    ) // youtube link
    .replace(
      /\[.*\]\(https:\/\/(www\.)?youtube\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\)/gm,
      ''
    ) // youtube link
    .replace(/\[(.*?)\]+\((.*?)\)/gm, '') // link
    .replace(/^>(.*)/gim, '')
    .split('\n')
    .join('<br />') // br
    .trim() // using trim method to remove whitespace

  const text = toPtag.split('<p>').pop()?.split('</p>')[0]

  const removeStyling = props.text
    .replace('######', '') // h6
    .replace('#####', '') // h5
    .replace('####', '') // h4
    .replace('###', '') // h3
    .replace('##', '') // h2
    .replace('#', '') // h1
    .replace(/\*\*(.*)\*\*/gim, '$1') // bold
    .replace(/(?:[ ])\_(.*?)\_/gim, '$1') // italic
    .replace(/^\_(.*?)\_/gim, '$1') // italic
    .replace(/\~~(.*)\~~/gim, '$1') // strike through
    .replace(/^\-(.*)/gim, '$1') // list item ul
    .replace(/^(\d+)\.*(.*)/gm, '$1') // list item ol
    .replace(/^!\[(.*)\]\((.*)\)/gm, '$1') // img
    .replace(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
      '$1'
    ) // youtube link
    .replace(
      /\[.*\]\(https:\/\/(www\.)?youtube\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)\)/gm,
      '$1'
    ) // youtube link
    .replace(/\[(.*?)\]+\((.*?)\)/gm, '$1') // link
    .replace(/^>(.*)/gim, '$1')
    .split('\n')
    .join('<br />') // br
    .trim() // using trim method to remove whitespace

  const rawText = removeStyling.split('<p>').pop()?.split('</p>')[0]

  if (props.onlyP) {
    return (
      <StyledMarkdown
        dangerouslySetInnerHTML={{
          __html: '<p>' + text + '</p>',
        }}
        columns={props.columns ?? undefined}
      />
    )
  }

  if (props.removeStyling) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: '<p>' + rawText + '</p>',
        }}
      />
    )
  }

  return (
    <div>
      <StyledMarkdown
        dangerouslySetInnerHTML={{
          __html: '<p>' + toHTML + '</p>',
        }}
        columns={props.columns ?? undefined}
      />
    </div>
  )
}
