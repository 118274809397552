import React from 'react'

import * as S from '../../ui'

import { Icon, IconType } from '../../ui/assets/icons'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { HeaderContainer } from '../../components/Header'
import { ExtraLargeSpacer } from '../../ui'
import { NonAuthRoutes } from '../../types'

const Container = styled.div`
  height: calc(100vh - 42px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
  }

  svg {
    max-width: 90vw;
  }

  @media screen and (min-width: 1200px) {
    height: calc(100vh - 169px);
    margin-top: 56px;

    button {
      width: 240px;
    }
  }
`

export const PageNotFound: React.FC = () => {
  const history = useHistory()

  return (
    <>
      <HeaderContainer>
        <S.HeaderText href="/">Bonanza location</S.HeaderText>
      </HeaderContainer>
      <S.Main>
        <S.Wrapper>
          <S.ContentContainer>
            <S.PaddingContainer>
              <Container>
                <Icon icon={IconType.PageNotFound} />
                <S.LargeSpacer />
                <S.DetailsBig>Sorry, this page could not be found</S.DetailsBig>
                <S.LargeSpacer />
                <S.ButtonPrimary
                  onClick={() => history.push(NonAuthRoutes.home)}
                >
                  Return home
                </S.ButtonPrimary>
                <ExtraLargeSpacer />
                <ExtraLargeSpacer />
                <ExtraLargeSpacer />
              </Container>
            </S.PaddingContainer>
          </S.ContentContainer>
        </S.Wrapper>
      </S.Main>
    </>
  )
}
