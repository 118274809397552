import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import styled from 'styled-components'
import { Modal } from './Modal'
import { Input } from '../Input'
import { getUser } from '../../auth/requests'
import { IUser } from '../../auth/types'
import { FormErrorMessage } from '../FormErrorMessage'
import { TextArea } from '../../pages/Client/CreateLocation'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { FormInput } from '../FormInput'
import { Icon, IconType } from '../../ui/assets/icons'

const EnquiryButton = styled(S.ModalButtonSecondary)`
  position: fixed;
  transform: rotate(-90deg);
  width: 176px;
  height: 38px;
  right: -70px;
  top: 33vh;

  @media screen and (min-width: 1200px) {
    width: 320px;
    height: 50px;
    right: -135px;
    top: 50vh;
  }
`

interface IProps {
  onSend: (budget: string, message: string) => void
  recipient: string
  success: boolean
  error: boolean
  setSuccess: (value: boolean) => void
}

interface IEnquiry {
  budget: string
  message: string
}

export const EnquiryModal: React.FC<IProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isVerified, setIsVerified] = useState<boolean | undefined>()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IEnquiry>()

  useEffect(() => {
    getUser().then((res: { data: IUser }) => {
      setIsVerified(res.data.verified)
    })
  }, [])

  const onSubmit: SubmitHandler<IEnquiry> = async (data: FieldValues) => {
    props.onSend(data.budget, data.message)
  }

  return (
    <>
      <EnquiryButton
        disabled={!isVerified}
        onClick={() => setModalOpen(true)}
        className={isVerified ? '' : 'disabled'}
      >
        Skicka förfrågan
      </EnquiryButton>

      {modalOpen && (
        <Modal closeModal={() => setModalOpen(false)} padding={'30px'}>
          {props.success ? (
            <>
              <S.FlexContainerRowAlignCeter>
                <S.H3>Thank you</S.H3>
                <S.SmallSpacerRow />
                <Icon icon={IconType.Heart} />
              </S.FlexContainerRowAlignCeter>
              <S.SmallSpacer />
              <S.Paragraph>
                Vi svarar så snart vi kan, håll ett öga på din inkorg!
              </S.Paragraph>
              <S.ExtraLargeSpacer />
              <S.ModalButtonPrimary
                onClick={() => {
                  props.setSuccess(false)
                  setModalOpen(false)
                }}
              >
                OKEJ
              </S.ModalButtonPrimary>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <S.FlexContainerColumnJustifyCenter>
                <S.H3>Intresseanmälan</S.H3>
                <S.SmallSpacer />
                <FormInput label="Budgetförslag*">
                  <S.InputField
                    type="number"
                    placeholder={'LÄMNA ETT BUDGETFÖRSLAG'}
                    {...register('budget', {
                      required: {
                        value: true,
                        message: 'Enter budget',
                      },
                    })}
                  />
                </FormInput>
                {errors.budget?.message && (
                  <FormErrorMessage message={errors.budget?.message} />
                )}

                <S.ExtraSmallSpacer />
                <FormInput label="Meddelande*">
                  <TextArea
                    minHeight="180px"
                    placeholder={`MEDDELANDE TILL ${props.recipient}`}
                    {...register('message', {
                      required: {
                        value: true,
                        message: 'Enter message',
                      },
                    })}
                  />
                </FormInput>
                {errors.message?.message && (
                  <FormErrorMessage message={errors.message?.message} />
                )}

                <S.SmallSpacer />
                <S.ParagraphSmall>
                  Beskriv ditt företag och syftet med projektet. Om det finns
                  någonting annat värt att nämna bör du göra det i meddelandet.
                </S.ParagraphSmall>
                <S.LargeSpacer />
                <S.ModalButtonSecondary type="submit">
                  Skicka förfrågan
                </S.ModalButtonSecondary>
                <S.SmallSpacer />
                {props.error && (
                  <FormErrorMessage message={'Ett fel uppstod.'} />
                )}
              </S.FlexContainerColumnJustifyCenter>
            </form>
          )}
        </Modal>
      )}
    </>
  )
}
