import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../ui/core'
import * as S from '../../ui'
import { BASE_URL } from '../../utils/config'
import { IHowItWorksPage } from '../../types'
import { StyledMarkdownParser } from '../../components/StyledParsedMarkdown'
import { Advantages } from './components/Advantages'
import { TextButtonCTA } from '../../components/TextButtonCTA'
import { toast } from 'react-toastify'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { Seo } from '../../components/Seo'

const HowItWorksContainer = styled(S.ContentContainer)`
  margin-top: 18px;
  @media screen and (min-width: 1200px) {
    margin-top: 32px;
  }
`

const AdvantagesContainer = styled(S.ContentContainer)`
  margin: 0 auto;
`

const StyledH1 = styled(S.H1)`
  margin-bottom: 16px;
`

const Image = styled.img`
  height: 270px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: 16px 0;

  @media screen and (min-width: 800px) {
    height: 100%;
    max-height: 470px;
  }

  @media screen and (min-width: 1200px) {
    margin: 32px 0;
  }
`

export const HowItWorks: React.FC = () => {
  const [data, setData] = useState<IHowItWorksPage>()

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        customerSteps: '*',
        companySteps: '*',
        media: '*',
        seo: {
          populate: {
            metaImage: '*',
          },
        },
      },
    })

    axios
      .get(`${BASE_URL}/api/how-it-work?${query}`)
      .then((res) => {
        setData(res.data.data.attributes)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  if (!data) {
    return <></>
  }

  return (
    <>
      {data?.seo && <Seo metaData={data.seo} />}
      <ScrollToTopOnMount />
      <S.Wrapper>
        <HowItWorksContainer maxWidth="976px">
          <S.PaddingContainer>
            <StyledH1>{data?.heading ?? ''}</StyledH1>
            <StyledMarkdownParser text={data?.text ?? ''} columns={2} />
            <Image
              src={data.media && data.media.data.attributes.url}
              alt="How it works"
            />
            <AdvantagesContainer maxWidth="637px">
              <Advantages
                intro={data.introHeadingCustomer ?? ''}
                heading={data.headingCustomer ?? ''}
                text={data.textCustomer ?? ''}
                steps={data.customerSteps ?? []}
              />
              <Advantages
                intro={data.introHeadingCompany ?? ''}
                heading={data.headingCompany ?? ''}
                text={data.textCompany ?? ''}
                steps={data.companySteps ?? []}
              />
            </AdvantagesContainer>
            <TextButtonCTA
              blue
              contact
              text="Got a question? ask us"
              buttonText="CONTACT"
            />
            <S.ExtraLargeSpacer />
          </S.PaddingContainer>
        </HowItWorksContainer>
      </S.Wrapper>
    </>
  )
}
