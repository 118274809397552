import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Dropdown } from '../../../components/Dropdown'
import { ImageSection } from '../../../components/ImageSection'
import { NonAuthRoutes, IUpdate } from '../../../types'
import * as S from '../../../ui'
import { Icon, IconType } from '../../../ui/assets/icons'
import { Images } from '../../../ui/assets/images'
import { colors } from '../../../ui/core'
import { dateWithMonthName } from '../../../utils/dateFormatting'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'
import { StyledMarkdownParser } from '../../../components/StyledParsedMarkdown'

const ImageContainer = styled(S.FlexContainerColumn)`
  @media screen and (min-width: 800px) {
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;
  }
`

const Card = styled(S.FlexContainerColumn)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  word-wrap: break-word;
  align-items: flex-start;
  cursor: pointer;

  @media screen and (min-width: 800px) {
    position: relative;
    width: calc(50% - 16px);

    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }

  @media screen and (min-width: 1200px) {
    /* position: relative; */
    width: calc(25% - 24px);

    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

export const ReadMore = styled(S.FlexContainerColumnCenterCenter)`
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 456px;
    width: 100%;
    background: rgba(61, 37, 35, 0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

const Image = styled.img`
  height: 207px;
  width: 100%;
  background-color: ${colors.brown};
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: 800px) {
    height: 456px;
  }
`

export const PaginationContainer = styled(S.FlexContainerRowJustifyBetween)`
  @media screen and (min-width: 800px) {
    justify-content: end;
  }
`
export const PaginationButton = styled(S.TextButton)`
  padding: 0 8px;
  color: ${colors.black};
  width: min-content;

  &:hover,
  &.active {
    color: ${colors.yellow};
  }
`

interface IOption {
  label: string
  value: string
}

export const AllUpdates: React.FC = () => {
  const history = useHistory()
  const [updates, setUpdates] = useState<IUpdate[]>([])
  const [updatesCategories, setUpdatesCategories] = useState<IOption[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState<IOption | undefined>()
  const maxPages = Math.ceil(total / 8)
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 8,
        },
        populate: '*',
        sort: 'publishedAt:desc',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    const queryWithCategory = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 8,
        },
        populate: '*',
        sort: 'publishedAt:desc',
        filters: {
          updateCategory: {
            category: {
              $eq: category?.value,
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(
        `${BASE_URL}/api/updates?${
          category && category.value !== 'Alla' ? queryWithCategory : query
        }`
      )
      .then((res) => {
        setUpdates(res.data.data)
        setTotal(res.data.meta.pagination.total)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [page, category])

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/update-categories`)
      .then((res) => {
        let categoryList: Array<IOption> = []
        res.data.data.map((d: any) =>
          categoryList.push({
            label: d.attributes?.category,
            value: d.attributes?.category,
          })
        )
        categoryList.push({
          label: 'Alla',
          value: 'Alla',
        })
        setUpdatesCategories(categoryList)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  useEffect(() => {
    setPaginationButtons([])
    for (let i = 0; i < maxPages; i++) {
      setPaginationButtons((prev) => [
        ...prev,
        <PaginationButton
          onClick={() => {
            window.scroll(0, 0)
            setPage(i + 1)
          }}
          className={page === i + 1 ? 'active' : ''}
          disabled={page === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [maxPages, page])

  return (
    <>
      <PaginationContainer>
        <Dropdown
          label={category ? category.value : 'Kategori'}
          sortOptions={updatesCategories}
          activeOption={category}
          handleClick={setCategory}
        />
      </PaginationContainer>
      <S.LargeSpacer />
      <ImageContainer>
        {updates.map((u) => {
          return (
            <Card
              key={u.id}
              onClick={() => history.push(`${NonAuthRoutes.updates}/${u.id}`)}
            >
              <Image
                src={
                  u.attributes?.thumbnail?.data &&
                  u.attributes?.thumbnail?.data?.attributes?.url
                }
              />
              <ReadMore>
                <S.StyledLink
                  to={`${NonAuthRoutes.updates}/${u.id}`}
                  color={colors.white}
                >
                  Read more
                </S.StyledLink>
              </ReadMore>
              <div style={{ whiteSpace: 'normal' }}>
                <S.ExtraSmallSpacer />
                <S.H5>{u.attributes?.title}</S.H5>
                <S.ExtraSmallSpacer />
                <S.FlexContainerRowAlignCeter>
                  <S.H5 color={colors.gray}>
                    <b>
                      {u.attributes?.updateCategory?.data?.attributes?.category}
                    </b>{' '}
                    {dateWithMonthName(u.attributes.createdAt)}
                  </S.H5>
                  <S.ExtraSmallSpacerRow />
                </S.FlexContainerRowAlignCeter>
                <S.ExtraSmallSpacer />
                <S.ParagraphSmall>
                  {u.attributes?.text && (
                    <>
                      <StyledMarkdownParser
                        text={u.attributes?.text.substring(0, 80) + '...'}
                        removeStyling
                      />
                    </>
                  )}
                </S.ParagraphSmall>
              </div>
              <S.MediumSpacer />
            </Card>
          )
        })}
      </ImageContainer>
      <S.FlexContainerRowCenterCenter>
        {paginationButtons}
      </S.FlexContainerRowCenterCenter>
    </>
  )
}
