import { IUser } from '../../auth/types'

export const LOG_IN = 'userReducer/LOG_IN'

export type LoginAction = {
  type: typeof LOG_IN
  payload: {
    userInfo: IUser
  }
}
export const loginAction = (userInfo: IUser): LoginAction => ({
  type: LOG_IN,
  payload: {
    userInfo: userInfo,
  },
})

export type Actions = LoginAction
