import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { SmallHeader } from './components/SmallHeader'
import { MinimalHeader } from './components/MinimalHeader'
import { TermsPolicyFaq } from './components/TermsPolicyFaq'
import { About } from './pages/About'
import { CreateLocation } from './pages/Client/CreateLocation'
import { CompanyAccount } from './pages/Company'
import { CreativePartners } from './pages/CreativePartners'
import { CreativePartner } from './pages/CreativePartners/components/CreativePartner'
import { Home } from './pages/Home'
import { PageNotFound } from './pages/PageNotFound'
import { LocationPage } from './pages/Locations'
import { Location } from './pages/Locations/components/Location'
import { SignUp } from './pages/SignUp'
import { Updates } from './pages/Updates'
import { MyLocations } from './pages/Account/MyLocations'
import { SingleUpdate } from './pages/Updates/components/SingleUpdate'
import { Main } from './ui'
import { PreviewLocationPage } from './pages/Client/PreviewLocationPage'
// import { ProfileInformation } from './pages/Account/CustomerAccount'
import { CustomerAccount } from './pages/Account/CustomerAccount'
import { UpdateLocation } from './pages/Account/UpdateLocation'
import { MyFavorites } from './pages/Company/Account/MyFavorites'
import { HowItWorks } from './pages/HowItWorks'
import { useSelector } from 'react-redux'
import { useIsLoggedIn } from './utils/useIsLoggedIn'
import { useGetMe } from './utils/useGetMe'
import { getUser, useToken } from './auth/requests'
import { IUser } from './auth/types'
import { AuthRoute } from './components/AuthRoute'
import { AuthRoutes, NonAuthRoutes, UserRoles } from './types'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { State } from './store/userReducer'
import { userState } from './store/userReducer/selectors'
import { useGetUserType } from './utils/useGetUserType'
import { signOut } from './utils/signOut'
import { ResetPassword } from './pages/ResetPassword'

function App() {
  const token = useToken()
  const isSignedIn = useIsLoggedIn()
  const state: State = useSelector(userState)
  const [userType, setUserType] = useState<string | null>()
  const getUserType = useGetUserType()

  function isTokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }

  useEffect(() => {
    if (state.userInfo?.type) {
      setUserType(state.userInfo.type)
    } else {
      setUserType(getUserType)
    }
  }, [])

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      signOut()
    }
  }, [token])

  const renderWithLayoutPublic = (component: JSX.Element) => {
    return (
      <>
        <SmallHeader public />
        <Main>{component}</Main>
      </>
    )
  }

  const renderWithLayout = (component: JSX.Element) => {
    return (
      <>
        <SmallHeader />
        <Main>{component}</Main>
      </>
    )
  }

  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route exact path={NonAuthRoutes.home}>
          <Header />
          <Home />
        </Route>
        <Route path={`${NonAuthRoutes.updates}/:id`}>
          {renderWithLayoutPublic(<SingleUpdate />)}
        </Route>
        <Route path={NonAuthRoutes.updates}>
          {renderWithLayoutPublic(<Updates />)}
        </Route>
        <Route path={`${NonAuthRoutes.creativePartners}/:id`}>
          {renderWithLayout(<CreativePartner />)}
        </Route>
        <Route path={NonAuthRoutes.creativePartners}>
          {renderWithLayout(<CreativePartners />)}
        </Route>
        <Route path={NonAuthRoutes.faq}>
          {renderWithLayoutPublic(
            <TermsPolicyFaq title={'Frequently asked questions'} url={'/faq'} />
          )}
        </Route>
        <Route path={NonAuthRoutes.privacy}>
          {renderWithLayoutPublic(
            <TermsPolicyFaq title={'Policy'} url={'/privacy-policy'} />
          )}
        </Route>
        <Route path={NonAuthRoutes.terms}>
          {renderWithLayoutPublic(
            <TermsPolicyFaq
              title={'Terms and Conditions'}
              url={'/terms-and-conditions'}
            />
          )}
        </Route>
        <Route path={NonAuthRoutes.about}>
          {renderWithLayoutPublic(<About />)}
        </Route>
        <Route path={NonAuthRoutes.howItWorks}>
          {renderWithLayoutPublic(<HowItWorks />)}
        </Route>
        <Route path={NonAuthRoutes.reset}>
          {renderWithLayoutPublic(<ResetPassword />)}
        </Route>

        <Route path={NonAuthRoutes.signUp}>
          {renderWithLayout(<SignUp />)}
        </Route>

        {/* customer routes */}
        <AuthRoute
          exact
          path={AuthRoutes.customerLocations}
          requiredRole={String(UserRoles.customer)}
          Component={MyLocations}
        />
        <AuthRoute
          exact
          path={`${AuthRoutes.customeUpdateLocationWithId}/:id`}
          requiredRole={String(UserRoles.customer)}
          Component={UpdateLocation}
        />
        <AuthRoute
          exact
          path={AuthRoutes.customerAccount}
          requiredRole={String(UserRoles.customer)}
          Component={CustomerAccount}
        />
        <AuthRoute
          exact
          path={AuthRoutes.createLocation}
          requiredRole={String(UserRoles.customer)}
          Component={CreateLocation}
        />

        {/* company routes */}
        <AuthRoute
          exact
          path={`${AuthRoutes.locations}/:id`}
          requiredRole={String(UserRoles.company)}
          Component={Location}
        />
        <AuthRoute
          exact
          path={AuthRoutes.locations}
          requiredRole={String(UserRoles.company)}
          Component={LocationPage}
        />
        <AuthRoute
          exact
          path={AuthRoutes.favorites}
          requiredRole={String(UserRoles.company)}
          Component={MyFavorites}
        />
        <AuthRoute
          exact
          path={AuthRoutes.account}
          requiredRole={String(UserRoles.company)}
          Component={CompanyAccount}
        />

        <Route path={NonAuthRoutes.notFound}>
          <PageNotFound />
        </Route>
      </Switch>
      <Footer />
    </Router>
  )
}

export default App
