import React, { useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import axios from 'axios'
import { colors } from '../ui'
import { BASE_URL } from '../utils/config'
import { validateEmail } from '../utils/validateEmail'
import { ContactModal } from './modals/ContactModal'
import { RequestModal } from './modals/RequestModal'
import { ShareModal } from './modals/ShareModal'
import { Icon, IconType } from '../ui/assets/icons'

const CTAContainer = styled(S.FlexContainerColumnCenterCenter)`
  max-width: 60vw;
  text-align: center;

  @media screen and (min-width: 1200px) {
    max-width: 980px;
    text-align: left;
    flex-direction: row-reverse;
    max-lines: 2;

    .spacer {
      width: 52px;
    }

    h2 {
      width: 787px;
    }

    &.reverse {
      flex-direction: row;
      h2 {
        width: auto;
      }
    }
  }
`

interface IProps {
  text: string
  buttonText: string
  reverse?: boolean
  blue?: boolean
  request?: string
  contact?: boolean
  shareUrl?: string
  title?: string
}

export interface IRequest {
  userEmail: string
  message: string
  file: File
}

export const TextButtonCTA: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <S.PaddingContainer>
        <S.Wrapper>
          <CTAContainer className={props.reverse ? 'reverse' : ''}>
            <S.H2 color={props.blue ? colors.blue : colors.yellow}>
              {props.text}
            </S.H2>

            {!props.reverse && <S.ExtraLargeSpacerRow />}
            <S.MediumSpacer className="spacer" />
            <S.ButtonSecondary
              onClick={() => setOpen(true)}
              color={props.blue ? colors.blue : colors.yellow}
            >
              {props.buttonText}
            </S.ButtonSecondary>
          </CTAContainer>
        </S.Wrapper>
      </S.PaddingContainer>
      {open &&
        (props.request ? (
          <RequestModal
            subject={props.request}
            closeModal={() => setOpen(false)}
          />
        ) : props.contact ? (
          <ContactModal closeModal={() => setOpen(false)} />
        ) : (
          <ShareModal
            closeModal={() => setOpen(false)}
            url={props.shareUrl}
            title={props.title}
          />
        ))}
    </>
  )
}
