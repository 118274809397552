import { useStore } from 'react-redux'

export const useIsLoggedIn = () => {
  const state = useStore().getState()
  const token = localStorage.getItem('token')

  if (!state.userReducer.userToken && !token) {
    return false
  } else {
    return true
  }
}
