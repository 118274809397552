import { useSelector, useStore } from 'react-redux'
import { useDispatch } from 'react-redux'
import { loginAction } from '../store/userReducer/actions'
import axios from 'axios'
import { State } from '../store/userReducer'
import { userState } from '../store/userReducer/selectors'
import { BASE_URL } from './config'

export const useGetMe = () => {
  const state: State = useSelector(userState)
  const dispatch = useDispatch()
  const token = localStorage.getItem('token')

  // if no info in state, set state
  if (!state.userInfo && token) {
    axios
      .get(`${BASE_URL}/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(loginAction({ ...response.data }))
        return response.data
      })
      .catch((err) => {
        console.error(err.response.data.error.message)
      })
  }
  return state.userInfo
}
