import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IOption } from '../../components/Dropdown'
import styled from 'styled-components'
import * as S from '../../ui'
import { colors } from '../../ui'
import { PreviewCreativePartners } from './components/PreviewCreativePartners'
import { Icon, IconType } from '../../ui/assets/icons'
import { AllCreativePartners } from './components/AllCreativePartners'
import { RelativeDropdown } from '../../components/RelativeDropdown'
import { useDebouncedCallback } from 'use-debounce'
import { PaginationButton } from '../Updates/components/AllUpdates'
import { countiesWithType } from '../../utils/counties'
import { ICreativePartner, IFavorite, IProfession, IPartner } from '../../types'
import { removeFavorite, addFavorite } from '../../utils/helperFunctions'
import { BASE_URL } from '../../utils/config'
import { toast } from 'react-toastify'
import { useIsLoggedIn } from '../../utils/useIsLoggedIn'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { TextButtonCTA } from '../../components/TextButtonCTA'
import { SearchSelect } from '../../components/SearchSelect'
import { validateEmail } from '../../utils/validateEmail'
import { RequestModal } from '../../components/modals/RequestModal'
import { Spinner } from '../../components/Spinner'

const SearchContainer = styled(S.FlexContainerRowCenterCenter)`
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.gray};
  input {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    input {
      width: 377px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: flex;
    }
  }
`

export const NoResults = styled(S.FlexContainerColumn)`
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const ResultContainer = styled(S.FlexContainerRowCenterCenter)`
  padding: 6px;
  border: 1px solid ${colors.gray};
  width: max-content;
  margin: 0 6px 6px 0;
`

const SearchContent = styled(S.FlexContainerRowAlignCeter)`
  width: 100%;
  max-width: 1150px;
`

const FilterContainer = styled(S.Main)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.white};
  overflow-y: scroll;
  padding-bottom: 32px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

const SearchButton = styled(S.Button)`
  background-color: ${colors.orange};
  color: ${colors.white};
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: max-content;
    height: 54px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CreativePartners: React.FC = () => {
  const [partners, setPartners] = useState<IPartner[] | undefined>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const maxPages = Math.ceil(total / 5)
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([])
  const [openSort, setOpenSort] = useState(false)
  const [searchValue, setSearchValue] = useState<IOption[]>([])
  const [showAll, setShowAll] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [county, setCounty] = useState<IOption | undefined>()
  const [counties, setCounties] = useState<IOption[]>([])
  const [profession, setProfession] = useState<IOption | undefined>()
  const [professions, setProfessions] = useState<IOption[]>([])
  const [names, setNames] = useState<IOption[]>([])
  const [data, setData] = useState<IFavorite>()
  const [userType, setUserType] = useState<string>()
  const [favoriteCreativePartners, setFavoriteCreativePartners] =
    useState<ICreativePartner[]>()
  const isSignedIn = useIsLoggedIn()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getMe = async () => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: {
        favoriteCreativePartners: {
          populate: '*',
        },
      },
    })

    const token = localStorage.getItem('token')
    await axios
      .get(`${BASE_URL}/api/users/me?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data)
        setUserType(res.data.type)
        setFavoriteCreativePartners(res.data.favoriteCreativePartners)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const searchValueFromLS = sessionStorage.getItem('searchValuePartner')
      const professionFromLS = sessionStorage.getItem('profession')
      const countyFromLS = sessionStorage.getItem('countyPartner')

      searchValueFromLS && setSearchValue(JSON.parse(searchValueFromLS))
      professionFromLS &&
        professionFromLS !== 'undefined' &&
        setProfession(JSON.parse(professionFromLS))
      countyFromLS &&
        countyFromLS !== 'undefined' &&
        JSON.parse(countyFromLS) &&
        setCounty(JSON.parse(countyFromLS))

      await axios
        .get(`${BASE_URL}/api/creative-partner/relevant-filters`) // this is an open API
        .then((res) => {
          const professionArray = res.data.professions.map(
            (profession: string) => {
              return {
                label: profession,
                value: profession,
                type: 'profession',
              }
            }
          )
          setProfessions(professionArray)
          const countyArray = res.data.counties.map(
            (county: { label: string; value: string }) => {
              return {
                label: county.label,
                value: county.value,
                type: 'county',
              }
            }
          )
          setCounties(countyArray)
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error('Could not fetch relevent filters')
        })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (isSignedIn) {
      getMe()
    }
    const page = sessionStorage.getItem('partnerPage')
    page && setPage(parseInt(page))
  }, [])

  useEffect(() => {
    getPartners()
  }, [page, county, profession])

  const getPartners = useDebouncedCallback(() => {
    if (searchValue.length > 0 || profession || county || page !== 1) {
      setShowAll(true)
    }
    sessionStorage.setItem('searchValuePartner', JSON.stringify(searchValue))
    sessionStorage.setItem('profession', JSON.stringify(profession))
    sessionStorage.setItem('countyPartner', JSON.stringify(county))

    const professionFilters = searchValue.map((o) => {
      if (o.type === 'profession') return o.label
    })

    const countyFilters = searchValue.map((o) => {
      if (o.type === 'county') return o.label
    })

    const nameFilters = searchValue.map((o) => {
      if (o.type === 'name') return o.label
    })

    const qs = require('qs')
    const query = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 5,
        },
        populate: {
          image: {
            populate: '*',
          },
          profession: '*',
        },
        filters: {
          $and: [
            {
              profession: {
                profession: {
                  $in: professionFilters,
                },
              },
            },
            {
              partnerName: {
                $in: nameFilters,
              },
            },
            {
              county: {
                $in: countyFilters,
              },
            },
          ],
          profession: {
            profession: {
              $eq: profession?.label,
            },
          },
          county: {
            $eq: county?.label,
          },
        },
        sort: 'publishedAt:desc',
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    axios
      .get(`${BASE_URL}/api/creative-partners?${query}`)
      .then((res) => {
        setPartners(res.data.data)
        setTotal(res.data.meta.pagination.total)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setIsLoading(false)
      })
  }, 200)

  useEffect(() => {
    setPaginationButtons([])
    for (let i = 0; i < maxPages; i++) {
      setPaginationButtons((prev) => [
        ...prev,
        <PaginationButton
          onClick={() => {
            window.scroll(0, 0)
            setPage(i + 1)
            sessionStorage.setItem('partnerPage', (i + 1).toString())
          }}
          className={page === i + 1 ? 'active' : ''}
          disabled={page === i + 1}
        >
          {i + 1}
        </PaginationButton>,
      ])
    }
  }, [maxPages, page])

  useEffect(() => {
    const professionsFromStorage = sessionStorage.getItem('professions')

    if (professionsFromStorage) {
      setProfessions(JSON.parse(professionsFromStorage))
    } else {
      axios
        .get(`${BASE_URL}/api/professions`)
        .then((res) => {
          const professionArray = res.data.data.map((p: IProfession) => {
            return {
              label: p.attributes.profession,
              value: p.attributes.profession,
            }
          })
          setProfessions(professionArray)
          sessionStorage.setItem('professions', JSON.stringify(professionArray))
        })
        .catch((err) => {
          toast.error('Something went wrong. Try again.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        })
    }
  }, [])

  const updateFavorite = (creativePartners: any) => {
    const favoriteCreativePartners = {
      favoriteCreativePartners: creativePartners,
    }
    axios
      .put(`${BASE_URL}/api/users/${data?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        favoriteCreativePartners,
      })
      .then(() => {
        if (isSignedIn) {
          getMe()
        }
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const toggleFavorite = (item: IPartner, isFavorite: boolean) => {
    if (isFavorite) {
      const data = removeFavorite(Number(item.id), favoriteCreativePartners)
      updateFavorite(data)
    } else {
      const data = addFavorite(Number(item.id), favoriteCreativePartners)
      updateFavorite(data)
    }
  }

  const NoOptions = () => {
    return (
      <NoResults>
        <S.FlexContainerColumn width="100%" style={{ alignItems: 'start' }}>
          <S.H5 color={colors.gray}>Hittade ingen match på din sökning</S.H5>
          <S.Paragraph color={colors.gray}>
            Skicka önskemål så hjälper vi dig leta
          </S.Paragraph>
        </S.FlexContainerColumn>
        <S.SmallSpacer />
        <S.SmallSpacerRow />
        <S.ButtonSecondary onClick={() => setOpen(true)}>
          Skicka önskemål
        </S.ButtonSecondary>
      </NoResults>
    )
  }

  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <SearchContainer>
          <S.ContentContainer>
            <SearchContent>
              <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                  e.preventDefault()
                  getPartners()
                }}
              >
                <S.FlexContainerRowAlignCeter
                  className="searchBar"
                  style={{ position: 'relative' }}
                >
                  <Icon
                    icon={IconType.Search}
                    style={{
                      position: 'absolute',
                      left: '8px',
                      width: '14px',
                      stroke: colors.gray,
                    }}
                  />
                  <SearchSelect
                    closeMenuOnSelect={false}
                    isMulti
                    noOptionsMessage={NoOptions}
                    placeholder="SÖK"
                    value={searchValue}
                    options={[
                      {
                        label: 'Creativity',
                        options: professions,
                      },
                      {
                        label: 'Län',
                        options: counties,
                      },
                      {
                        label: 'Creative Partner',
                        options: names,
                      },
                    ]}
                    name="searchSelect"
                    onChange={(selectedTags) => {
                      const selectedTagsAsIOptions =
                        selectedTags as unknown as IOption[]

                      setSearchValue(selectedTagsAsIOptions)
                      getPartners()
                    }}
                  />
                </S.FlexContainerRowAlignCeter>
              </form>
              <S.InvisibleButton
                className="mobile"
                onClick={() => setFiltersOpen(true)}
                padding="15px"
              >
                <Icon icon={IconType.Filter} />
              </S.InvisibleButton>
              <S.FlexContainerRow className="desktop">
                <S.SmallSpacerRow />
                <SearchButton onClick={getPartners}>
                  <S.SmallSpacerRow />
                  Search
                  <S.SmallSpacerRow />
                </SearchButton>
                <S.ExtraLargeSpacerRow />
                <RelativeDropdown
                  noBorder
                  label={county?.label ?? 'LÄN'}
                  activeOption={county}
                  sortOptions={counties}
                  handleClick={setCounty}
                  selected={county ? true : false}
                />
                <S.SmallSpacer />
                <S.SmallSpacerRow />
                <RelativeDropdown
                  noBorder
                  activeOption={profession}
                  label={profession?.label ?? 'Creativity'}
                  sortOptions={professions}
                  handleClick={setProfession}
                  selected={profession ? true : false}
                />
              </S.FlexContainerRow>
            </SearchContent>
          </S.ContentContainer>
        </SearchContainer>
        <S.ContentContainer>
          {isLoading ? (
            <Spinner />
          ) : partners ? (
            <S.PaddingContainer>
              <S.SmallSpacer />
              <S.FlexContainerRowJustifyBetween>
                <S.H1>Creative Partners</S.H1>
              </S.FlexContainerRowJustifyBetween>
              <S.MediumSpacer />

              {(searchValue.length > 0 || county || profession) && (
                <>
                  <S.FlexContainerRowAlignCeter style={{ flexWrap: 'wrap' }}>
                    <S.DetailsBig>Visar resultat för: </S.DetailsBig>{' '}
                    <S.SmallSpacerRow />
                    {searchValue.length > 0 && (
                      <>
                        {searchValue.map((v) => {
                          return (
                            <ResultContainer>
                              <S.DetailsSmall>{v.label}</S.DetailsSmall>
                            </ResultContainer>
                          )
                        })}
                      </>
                    )}
                    {county && (
                      <ResultContainer>
                        <S.DetailsSmall>{county.label}</S.DetailsSmall>
                      </ResultContainer>
                    )}
                    {profession && (
                      <ResultContainer>
                        <S.DetailsSmall>{profession.label}</S.DetailsSmall>
                      </ResultContainer>
                    )}
                  </S.FlexContainerRowAlignCeter>
                  <S.MediumSpacer />
                </>
              )}

              {showAll && partners ? (
                <>
                  <ImageContainer className="imageContainer">
                    {partners.map((p, i) => {
                      const f = favoriteCreativePartners?.some(
                        (f) => f.id === Number(p.id)
                      )
                      return (
                        <AllCreativePartners
                          key={i}
                          p={p}
                          isSignedIn={isSignedIn}
                          isFavorite={f ?? false}
                          userType={userType ?? undefined}
                          toggleFavorite={toggleFavorite}
                        />
                      )
                    })}
                  </ImageContainer>
                </>
              ) : (
                <PreviewCreativePartners />
              )}
              {filtersOpen && (
                <FilterContainer className="mobile">
                  <S.PaddingContainer>
                    <S.SmallSpacer />
                    <S.FlexContainerRowJustifyEnd>
                      <S.InvisibleButton onClick={() => setFiltersOpen(false)}>
                        <Icon icon={IconType.Close} />
                      </S.InvisibleButton>
                    </S.FlexContainerRowJustifyEnd>
                    <S.MediumSpacer />
                    <RelativeDropdown
                      width="100%"
                      noBorder
                      label={'LÄN'}
                      sortOptions={counties}
                      handleClick={setCounty}
                    />
                    <S.SmallSpacer />
                    <RelativeDropdown
                      width="100%"
                      label={'Creativity'}
                      sortOptions={professions}
                      handleClick={setProfession}
                    />
                  </S.PaddingContainer>
                </FilterContainer>
              )}
              {showAll && total > 5 && (
                <S.FlexContainerRowCenterCenter>
                  {paginationButtons}
                </S.FlexContainerRowCenterCenter>
              )}
              <S.VerticalMargin />
              <TextButtonCTA
                blue
                contact
                reverse
                text={'Vill du vara creative partner?'}
                buttonText={'Kontakta oss'}
              />
              <S.VerticalMargin />
            </S.PaddingContainer>
          ) : null}
        </S.ContentContainer>
      </S.Wrapper>
      {open && (
        <RequestModal
          subject={'creative partner'}
          closeModal={() => setOpen(false)}
        />
      )}
    </>
  )
}
