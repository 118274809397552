import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Dropdown } from '../../../components/Dropdown'
import styled from 'styled-components'
import * as S from '../../../ui'
import { colors } from '../../../ui'
import { FavoritesButton } from '../../../components/FavoritesButton'
import { Icon, IconType } from '../../../ui/assets/icons'
import { AuthRoutes, IFavorite, ILocation, ILocationPage } from '../../../types'
import { BASE_URL } from '../../../utils/config'

const Card = styled(S.FlexContainerColumn)`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  word-wrap: break-word;
  align-items: flex-start;
  position: relative;
  margin-bottom: 24px;

  @media screen and (min-width: 1200px) {
    height: 540px;
    margin-bottom: 32px;
  }
`

const Image = styled.img`
  height: 40vw;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 100%;

  @media screen and (min-width: 1200px) {
    height: 540px;
  }
`

const ImageButtons = styled.div`
  position: absolute;
  padding: 8px;
`

const Text = styled(S.FlexContainerRowCenterCenter)`
  white-space: normal;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;

  @media screen and (min-width: 1200px) {
    padding: 24px 158px;
    white-space: normal;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(61, 37, 35, 0.8);

    * {
      color: ${colors.white};
    }
  }
`

export const HeaderContainer = styled.div`
  width: max-content;
  position: relative;

  &.top {
    width: 100%;
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1200px) {
    width: max-content;
    margin: auto;
    &.top {
      margin: auto;
      width: max-content;
    }
  }
`

export const Underline = styled.div`
  margin: 8px 0;
  @media screen and (min-width: 1200px) {
    background-color: ${colors.white};
    width: 100%;
    height: 1px;
  }
`

interface IProps {
  l: ILocationPage
  isFavorite: boolean
  toggleFavorite: (item: ILocationPage, isFavorite: boolean) => void
}

export const AllLocations: React.FC<IProps> = (props) => {
  const history = useHistory()

  return (
    <Card
      style={{
        cursor: 'pointer',
      }}
      onClick={() => history.push(`${AuthRoutes.locations}/${props.l.id}`)}
    >
      <ImageButtons>
        {props.l.attributes.exclusive && (
          <>
            <S.ExclusiveButton width="max-content">
              <Icon icon={IconType.Tag} />
              <S.ExtraSmallSpacerRow />
              exklusivt objekt
            </S.ExclusiveButton>
            <S.ExtraSmallSpacer />
          </>
        )}

        <FavoritesButton
          border
          position="relative"
          isFavorite={props.isFavorite}
          onClick={() => {
            props.toggleFavorite(props.l, props.isFavorite)
          }}
        />
      </ImageButtons>
      <Image
        src={
          props.l.attributes.images.data &&
          props.l.attributes.images?.data[0].attributes.url
        }
        alt={props.l.attributes.images?.data[0].attributes.alternativeText}
      />

      <S.SmallSpacer />
      <Text className="text">
        <HeaderContainer className="top">
          <S.FlexContainerRow>
            <S.H4>{props.l.attributes.title}</S.H4>
          </S.FlexContainerRow>
          <Underline />
          <S.FlexContainerRow>
            <S.ParagraphBig>{props.l.attributes.county}</S.ParagraphBig>
          </S.FlexContainerRow>
        </HeaderContainer>

        <HeaderContainer>
          <S.FlexContainerRow>
            <S.H5>KVM</S.H5>
            <S.SmallSpacerRow />
            <S.ParagraphSmall>
              {props.l.attributes.squareMeters}M2
            </S.ParagraphSmall>
          </S.FlexContainerRow>
          <Underline />
          <S.FlexContainerRow>
            <S.H5>Rum</S.H5>
            <S.SmallSpacerRow />
            <S.ParagraphSmall>
              {props.l.attributes.rooms} RUM & KÖK
            </S.ParagraphSmall>
          </S.FlexContainerRow>
        </HeaderContainer>

        <S.SmallSpacerRow />

        <HeaderContainer>
          <S.FlexContainerRow>
            <S.H5>Typ</S.H5>
            <S.SmallSpacerRow />
            <S.ParagraphSmall>VILLA MED TRÄDGÅRD</S.ParagraphSmall>
          </S.FlexContainerRow>
          <Underline />
          <S.FlexContainerRow>
            <S.H5>Väderstreck</S.H5>
            <S.SmallSpacerRow />
            <S.ParagraphSmall>
              {props.l.attributes.directions?.data.map((d, i) => {
                if (props.l.attributes.directions.data.length - 1 === i) {
                  return d.attributes.direction.toUpperCase()
                }
                return d.attributes.direction.toUpperCase() + ', '
              })}
            </S.ParagraphSmall>
          </S.FlexContainerRow>
        </HeaderContainer>
      </Text>
    </Card>
  )
}
