import React, { useState } from 'react'
import axios from 'axios'

import * as S from '../../ui'
import { colors } from '../../ui'

import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { FormInput } from '../../components/FormInput'
import { BASE_URL } from '../../utils/config'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { NonAuthRoutes } from '../../types'

export const ResetPassword: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = location.search
  const code = new URLSearchParams(queryParams).get('code')

  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const resetPassword = () => {
    axios
      .post(`${BASE_URL}/api/auth/reset-password`, {
        code: code,
        password: password,
        passwordConfirmation: passwordConfirmation,
      })
      .then(() => {
        localStorage.setItem('redirectedFromPasswordDirect', 'true')
        history.push(NonAuthRoutes.home)
      })
      .catch((err) => {
        setError(err.response.data.error.message)
      })
  }

  return (
    <S.Wrapper>
      <S.ContentContainer maxWidth="700px">
        <S.PaddingContainer>
          <S.VerticalMargin />
          <S.FlexContainerColumnJustifyCenter>
            <S.H3>Reset Password</S.H3>
            <S.SmallSpacer />
            <FormInput label="Ange nytt lösenord *">
              <S.InputField
                value={password}
                type={'password'}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="NYTT LÖSENORD"
              />
            </FormInput>
            <FormInput label="Bekräfta nytt lösenord *">
              <S.InputField
                value={passwordConfirmation}
                type={'password'}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="BEKRÄFTA NYTT LÖSENORD"
              />
            </FormInput>
            <S.ExtraSmallSpacer />
            {error && (
              <>
                <FormErrorMessage message={error} />
                <S.ExtraSmallSpacer />
              </>
            )}
            <S.FlexContainerRowJustifyEnd>
              <S.TextButton
                onClick={() => history.push('/')}
                color={colors.gray}
              >
                Avbryt
              </S.TextButton>
              <S.LargeSpacerRow />
              <S.ButtonPrimary onClick={resetPassword}>RESET</S.ButtonPrimary>
            </S.FlexContainerRowJustifyEnd>
          </S.FlexContainerColumnJustifyCenter>
        </S.PaddingContainer>
      </S.ContentContainer>
    </S.Wrapper>
  )
}
