import React, { useState } from 'react'
import * as S from '../ui'
import styled from 'styled-components'
import { colors } from '../ui'
import { useIsLoggedIn } from '../utils/useIsLoggedIn'
import { SignInModal } from './modals/SignInModal'
import { dateWithMonthName } from '../utils/dateFormatting'
import { StyledMarkdownParser } from './StyledParsedMarkdown'

export const ImageContainer = styled(S.ContentContainer)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;
  padding-bottom: 20px;

  @media screen and (min-width: 1200px) {
    gap: 32px;
    overflow-x: unset;
    white-space: unset;
    flex-wrap: wrap;
  }
`

export const Card = styled(S.FlexContainerColumn)`
  width: 304px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  word-wrap: break-word;
  align-items: flex-start;

  // mobile only
  @media screen and (max-width: 1199px) {
    :first-of-type {
      margin-left: 16px;
    }
    :last-of-type {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    width: calc(25% - 24px);

    &.fullwidth {
      width: calc(50% - 16px);
    }

    &.large {
      :nth-of-type(3) {
        width: calc(50% - 16px);
      }
    }

    .text {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .text {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

export const ReadMore = styled(S.FlexContainerColumnCenterCenter)`
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 456px;
    width: 100%;
    background: rgba(61, 37, 35, 0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
`

export const Image = styled.img`
  height: 456px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

export const Text = styled.div`
  white-space: normal;
  .detailsContainer {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: rgba(61, 37, 35, 0.9);
    padding: 12px 32px;

    h5,
    p {
      color: ${colors.white};
      text-align: center;
    }

    h5 {
      color: ${colors.white};
      text-align: center;
      padding: 0 12px;
    }

    &.noHover {
      display: none;
    }

    .details {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }

    .detailsContainer {
      display: flex;
      width: 100%;
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const Underline = styled.div`
  margin-top: 8px;
  background-color: ${colors.white};
  width: 100%;
  height: 1px;
`

interface IProps {
  title: string
  link?: string
  content: Array<IContent>
  date?: string
  updates?: boolean
  authRequired?: boolean
  fullWidthImages?: boolean
  location?: boolean
  noHover?: boolean
  handleClick?: (id: string) => void
}

export interface IContent {
  image: any
  id?: string
  header?: string
  body?: string
  keyWords?: string[]
  date?: string
  category?: string
  locationTags?: any
}

export interface ILocationTag {
  attributes: {
    createdAt: string
    publishedAt: string
    tag: string
    updatedAt: string
  }
  id: number
}

export const ImageSection: React.FC<IProps> = (props) => {
  const isSignedIn = useIsLoggedIn()
  const [signInModal, setSignInModal] = useState(false)

  const link = () => {
    if (props.authRequired && !isSignedIn) {
      return (
        <S.StyledLinkButton
          onClick={() => {
            setSignInModal(true)
          }}
        >
          VIEW MORE
        </S.StyledLinkButton>
      )
    }

    return (
      <S.StyledLink onClick={() => {}} to={props.link!}>
        VIEW MORE
      </S.StyledLink>
    )
  }

  return (
    <S.Wrapper>
      <S.TitleTextContainer>
        <S.H4>{props.title}</S.H4>
        {props.link && link()}
      </S.TitleTextContainer>
      <S.SmallSpacer />
      <ImageContainer>
        {props.content.map((c, i) => {
          return (
            <Card
              key={i}
              className={`${props.content.length > 5 ? 'large' : ''} ${
                props.fullWidthImages ? 'fullwidth' : ''
              }`}
              style={{ cursor: `${props.handleClick ? 'pointer' : 'default'}` }}
              onClick={() => props.handleClick && props.handleClick(c.id ?? '')}
            >
              <Image
                src={c.image}
                alt={props.location ? c.body : c.header + ' thumbnail'}
              />
              {!props.updates && (
                <Text className={`text ${props.noHover ? 'noHover' : ''}`}>
                  <S.ExtraSmallSpacer />
                  <S.H5>{c.header}</S.H5>
                  <Underline />
                  <S.ExtraSmallSpacer />
                  <S.Paragraph>{c.body}</S.Paragraph>
                  <S.ExtraSmallSpacer />
                  {props.location && (
                    <S.FlexContainerRowCenterCenter className="detailsContainer">
                      <S.DetailsSmall>
                        {c.locationTags[0] && c.locationTags[0].tag}
                      </S.DetailsSmall>
                      <S.SmallSpacerRow />
                      <S.DetailsSmall>
                        {c.locationTags[1] && c.locationTags[1].tag}
                      </S.DetailsSmall>
                    </S.FlexContainerRowCenterCenter>
                  )}

                  <S.FlexContainerRow>
                    {c.keyWords &&
                      c.keyWords.map((word, i) => {
                        return (
                          <div key={word + i}>
                            <S.DetailsSmall className="details">
                              {word.toUpperCase()}
                            </S.DetailsSmall>
                            <S.SmallSpacerRow />
                          </div>
                        )
                      })}
                  </S.FlexContainerRow>
                </Text>
              )}
              {props.updates && (
                <>
                  <ReadMore>
                    {props.link && (
                      <S.StyledLink to={props.link} color={colors.white}>
                        Read more
                      </S.StyledLink>
                    )}
                  </ReadMore>
                  <div style={{ whiteSpace: 'normal' }}>
                    <S.ExtraSmallSpacer />
                    <S.H5>{c.header}</S.H5>
                    <S.ExtraSmallSpacer />
                    <S.FlexContainerRowAlignCeter>
                      {c.category && (
                        <>
                          <S.H5 color={colors.gray}>{c.category}</S.H5>
                          <S.ExtraSmallSpacerRow />
                        </>
                      )}
                      <S.DetailsSmall color={colors.gray}>
                        {c.date && dateWithMonthName(c.date)}
                      </S.DetailsSmall>
                    </S.FlexContainerRowAlignCeter>
                    <S.ExtraSmallSpacer />
                    <StyledMarkdownParser
                      onlyP
                      text={c.body ? c.body.substring(0, 75) + '...' : ''}
                    />
                  </div>
                </>
              )}
            </Card>
          )
        })}
      </ImageContainer>
      {signInModal && (
        <SignInModal
          closeModal={() => setSignInModal(false)}
          path={'locations'}
        />
      )}
    </S.Wrapper>
  )
}
