import React, { useEffect, useState } from 'react'
import * as S from '../../ui'
import axios from 'axios'
import { colors } from '../../ui'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { FormInput } from '../../components/FormInput'
import { counties } from '../../utils/counties'
import { Select } from '../../components/Select'
import { Icon, IconType } from '../../ui/assets/icons'
import { PreviewLocationModal } from '../../components/modals/PreviewLocationModal'
import { AuthRoutes } from '../../types'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { toast } from 'react-toastify'
import {
  BASE_URL,
  maxFileSize,
  fileSizeIntervalHigh,
  fileSizeIntervalLow,
  targetFileSize,
} from '../../utils/config'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { useIsLoggedIn } from '../../utils/useIsLoggedIn'
import { UnverifiedBanner } from '../../components/SmallHeader'
import { ContactModal } from '../../components/modals/ContactModal'
import { useSelector } from 'react-redux'
import { State } from '../../store/userReducer'
import { userState } from '../../store/userReducer/selectors'
import { Spinner } from '../../components/Spinner'
import imageCompression from 'browser-image-compression'
import { zipCodePattern } from '../../utils/regex'
import { getUser } from '../../auth/requests'

interface IStyleProps {
  minHeight?: string
}

export const PreviewButton = styled(S.ModalButtonSecondary)`
  position: fixed;
  transform: rotate(-90deg);
  width: 176px;
  height: 38px;
  right: -70px;
  top: 33vh;
  z-index: 100;
  @media screen and (min-width: 1200px) {
    width: 320px;
    height: 50px;
    right: -135px;
    top: 50vh;
  }
`

export const StyledForm = styled.form`
  &.disabled {
    * {
      opacity: 0.7;
    }
  }
`

export const Checkbox = styled.input`
  cursor: pointer;
`

export const MediaFormInput = styled(S.FlexContainerColumnCenterCenter)`
  width: 100%;
  label {
    border-radius: 1px;
    width: 100%;
    background: ${colors.orange};
    padding: 15px;
    margin: 0;
    cursor: pointer;
    &.disabled {
      color: ${colors.gray};
      background: #afaaa3;
      cursor: default;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 350px;
    margin: auto;
  }
`

export const MediaInput = styled(S.InputField)`
  border: none;
  padding: 0;
  height: min-content;
  ::-webkit-file-upload-button {
    display: none;
  }
`

export const TextArea = styled.textarea<IStyleProps>`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  height: 38px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  height: 54px;
  min-height: ${(props) => props.minHeight ?? '130px'};
  resize: vertical;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 24px 0;
`

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`

const ImageContent = styled.div`
  position: relative;
  margin: 0 20px 14px 0;
`

const ImageContentFirst = styled(ImageContent)`
  background: ${colors.orange};
  padding: 2px 2px 20px 2px;
`

const StyledDetail = styled(S.DetailsSmall)`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  position: absolute;
  right: -10px;
  bottom: 4px;
  width: 100%;
  height: auto;
  z-index: 100;
  color: ${colors.white};
`

const HiddenMediaInput = styled(MediaInput)`
  display: none;
`

const BtnContainer = styled.div`
  position: absolute;
  right: -14px;
  top: -14px;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-top: 4px;
`

export interface ILocationInfo {
  title: string
  address: string
  county: string
  zipCode: string
  images: {
    data: {
      attributes: {
        url: string
        alternativeText: string
      }
    }[]
  }
  description: string
  request: string
  exclusive: boolean
  squareMeters: number
  rooms: number
  directions: number[]
  facilities: string[]
  locationTags: number[]
  user: number
}

export interface IOption {
  label: string
  value: string
}

export interface IOption {
  label: string
  value: string
}

export interface IFacility {
  id: number
  attributes: {
    createdAt: string
    facility: string
    updatedAt: string
    icon: IImage
    id: number
  }
}

export interface ILocationTag {
  id: number
  attributes: {
    createdAt: string
    tag: string
    updatedAt: string
  }
}

export interface IImage {
  data: {
    attributes: {
      alternativeText: string
      url: string
      id: number
    }
  }
}

export interface IDirection {
  id: number
  attributes: {
    createdAt: string
    direction: string
    updatedAt: string
  }
}

export interface IProfileInfo {
  name: string
  company?: string
  orgNumber?: string
  email: string
  phoneNumber: string
  invoiceAddress: string
  type: string
  zipCode: string
  county: string
  id: number
}

export const CreateLocation: React.FC = () => {
  const history = useHistory()
  const state: State = useSelector(userState)
  const [tags, setTags] = useState<ILocationTag[]>([])
  const [facilities, setFacilities] = useState<IFacility[]>([])
  const [allDirections, setAllDirections] = useState<IDirection[]>([])
  const [allTags, setAllTags] = useState<ILocationTag[]>([])
  const [directions, setDirections] = useState<IDirection[]>([])
  const [directionsOptions, setDirectionsOptions] = useState<IOption[]>([])
  const [directionsDefault, setDirectionsDefault] = useState<IOption[]>([])
  const [tagsDefault, setTagsDefault] = useState<IOption[]>([])
  const [showContactModal, setShowContactModal] = useState(false)
  const [tagOptions, setTagOptions] = useState<IOption[]>([])
  const [previewState, setPreviewState] = useState<ILocationInfo>()
  const [previewFacilities, setPreviewFacilities] = useState<
    string[] | undefined
  >()
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [imageFiles, setImageFiles] = useState<any[]>([])
  const [displayUnverifiedBanner, setDisplayUnverifiedBanner] =
    useState<boolean>(false)
  const [user, setUser] = useState<IProfileInfo>()
  const [previewModal, setPreviewModal] = useState(false)
  const [savedSuccess, setSavedSuccess] = useState<boolean>(false)
  const [fileError, setFileError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<ILocationInfo>()

  useEffect(() => {
    setIsLoading(true)
    // setValue('facilities', [])
    // const locationFromSS = sessionStorage.getItem('location')
    // if (locationFromSS) {
    //   const parsedLocation = JSON.parse(locationFromSS)
    //   setPreviewState(parsedLocation)
    //   setPreviewFacilities(
    //     parsedLocation.facilities.map((d: { id: any }) => {
    //       return d.id
    //     })
    //   )

    //   setValue('address', parsedLocation.address)
    //   setValue('county', parsedLocation.county)
    //   setValue('description', parsedLocation.description)
    //   setValue('title', parsedLocation.title)
    //   setValue('request', parsedLocation.request)
    //   setValue('squareMeters', parsedLocation.squareMeters)
    //   setValue('rooms', parsedLocation.rooms)
    //   setValue('exclusive', parsedLocation.exclusive)
    //   setValue('images', parsedLocation.images)
    //   setValue('zipCode', parsedLocation.zipCode)
    //   setValue('directions', parsedLocation.directions)
    //   setValue('facilities', parsedLocation.facilities)
    //   setValue('locationTags', parsedLocation.locationTags)
    // }

    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
    axios
      .get(`${BASE_URL}/api/location-tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllTags(res.data.data)
        setTags(res.data.data)
        setTagOptions(
          res.data.data.map(
            (t: { id: number; attributes: { tag: string } }) => {
              return { label: t.attributes.tag, value: t.id }
            }
          )
        )
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setIsLoading(false)
      })
    axios
      .get(`${BASE_URL}/api/facilities?populate=icon`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFacilities(res.data.data)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setIsLoading(false)
      })
    axios
      .get(`${BASE_URL}/api/directions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllDirections(res.data.data)
        setDirections(res.data.data)
        setDirectionsOptions(
          res.data.data.map(
            (f: { id: number; attributes: { direction: string } }) => {
              return {
                label: f.attributes.direction,
                value: f.id,
              }
            }
          )
        )
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        setIsLoading(false)
      })
  }, [setValue])

  useEffect(() => {
    getUser().then((res) => {
      if (res.data.verified) {
        setDisplayUnverifiedBanner(!res.data.verified)
      } else {
        axios
          .post(`${BASE_URL}/api/verified/checkHasSigned`, {
            data: { ...res.data },
          })
          .then((res) => {
            setDisplayUnverifiedBanner(!res.data)
          })
      }
    })
    if (!state.userInfo?.verified) {
      setDisplayUnverifiedBanner(true)
    }
  }, [state.userInfo])

  useEffect(() => {
    const myDirections = allDirections.filter((direction) => {
      return (
        getValues().directions && getValues().directions.includes(direction.id)
      )
    })

    const options = myDirections.map((f) => {
      return {
        label: f.attributes.direction,
        value: f.id.toString(),
      }
    })

    setDirectionsDefault(options)
  }, [allDirections])

  useEffect(() => {
    const myTags = allTags.filter((tag) => {
      return (
        getValues().locationTags && getValues().locationTags.includes(tag.id)
      )
    })

    const options = myTags.map((f) => {
      return {
        label: f.attributes.tag,
        value: f.id.toString(),
      }
    })

    setTagsDefault(options)
  }, [allTags, getValues])

  useEffect(() => {
    if (previewState)
      sessionStorage.setItem('location', JSON.stringify(previewState))
  }, [previewState])

  const onSubmit: SubmitHandler<ILocationInfo> = async (data: FieldValues) => {
    setIsLoading(true)
    window.scroll(0, 0)
    setFileError('')
    const formData = new FormData()
    let totalUploadSize: number = 0

    if (imageFiles && (imageFiles?.length < 3 || imageFiles?.length > 8)) {
      setIsLoading(false)
      setFileError('Välj 3 - 8 bilder')
      throw Error('files need too be min 3 and max 8')
    }
    for (let i in imageFiles) {
      // Check file size of all files before we try to compress any
      if (imageFiles[i as any].size > fileSizeIntervalHigh) {
        setIsLoading(false)
        setFileError(
          `${imageFiles[i as any].name} är för stor (${
            Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
          } MB). Max tillåten filstorlek är ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
        throw Error(
          `File ${imageFiles[i as any].name} is to large, max: ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
      }
    }

    if (imageFiles) {
      for (let i = 0; i < imageFiles.length; i++) {
        // if file size is between 10MB and 20MB, compress in client and then add it to upload list
        if (
          imageFiles[i as any].size >= fileSizeIntervalLow &&
          imageFiles[i as any].size <= fileSizeIntervalHigh
        ) {
          const options = {
            maxSizeMB: targetFileSize, // TODO: tune this value
            useWebWorker: true,
          }
          try {
            const compressedBlob = await imageCompression(
              imageFiles[i as any],
              options
            )
            var compressedFile = new File(
              [compressedBlob],
              compressedBlob.name,
              {
                type: compressedBlob.type,
              }
            )
            console.log(
              `Successfully compressed ${imageFiles[i as any].name} from ${
                Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
              } MB -> ${
                Math.round((compressedFile.size / 1024 / 1024) * 10) / 10
              } MB`
            )
            if (compressedFile.size > 8 * 1024 * 1024) {
              throw Error('Failed to compress file enough')
            }

            totalUploadSize += compressedFile.size
            formData.append('files', compressedFile)
          } catch (error) {
            setIsLoading(false)
            setFileError('')
            throw Error('Failed to handle files')
          }
        } else {
          // if file size is below 10MB, add it to upload list and let strapi compress it
          totalUploadSize +=
            Math.round((imageFiles[i as any].size / 1024 / 1024) * 10) / 10
          formData.append('files', imageFiles[i as any])
        }
      }
      console.log(
        `totalUploadSize: ${
          Math.round((totalUploadSize / 1024 / 1024) * 10) / 10
        } MB`
      )
      if (totalUploadSize > maxFileSize) {
        setIsLoading(false)
        setFileError(
          `Max total storlek på filerna är ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
        throw new Error(
          `Max total filesize: ${
            Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10
          } MB`
        )
      }
    } else {
      setIsLoading(false)
      throw new Error('Could not read imageFiles')
    }

    // Upload to server
    await axios
      .post(`${BASE_URL}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const imageIdList = response.data.map((s: { id: number }) => s.id)
          await axios
            .post(
              `${BASE_URL}/api/locations?populate=%2a`,
              { data: { ...data, user: user?.id, images: imageIdList } },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                setIsLoading(false)
                setSavedSuccess(true)
                sessionStorage.removeItem('location')
                window.scroll(0, 0)
                history.push(AuthRoutes.customerLocations)
              }
            })
            .catch((err) => {
              setSavedSuccess(false)
              setIsLoading(false)
              // setFileError('Kunde inte ladda upp bilderna, pröva igen')
              throw Error('Could not upload location' + err)
            })
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setFileError('Kunde inte ladda upp bilderna, pröva igen')
        throw Error('Could not upload the images:' + err)
      })
  }

  const onChangePicture = (e: any) => {
    if (!e.target.files) return
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    )

    let ImagesF = Object.entries(e.target.files).map((e) =>
      e[1] ? e[1] : null
    )
    setImageFiles([...imageFiles, ...ImagesF])
    setImageUrls([...imageUrls, ...ImagesArray])
  }

  const deleteFile = (e: any) => {
    //list of preview images
    const s = imageUrls?.filter((item, index) => index !== e)
    setImageUrls(s)

    // list of File
    const newArr = imageFiles?.filter((item, index) => index !== e)
    setImageFiles(newArr)
  }

  useEffect(() => {
    if (previewModal) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = 'static'
    }
  }, [previewModal])

  return (
    <>
      <ScrollToTopOnMount />
      <PreviewButton
        disabled={!previewState || displayUnverifiedBanner ? true : false}
        className={!previewState || displayUnverifiedBanner ? 'disabled' : ''}
        onClick={() => {
          setPreviewModal(true)
        }}
      >
        Förhandsgranska
      </PreviewButton>

      <S.Wrapper>
        {isLoading ? (
          <S.FlexContainerColumnCenterCenter height="100%">
            <Spinner color={colors.gray} />
          </S.FlexContainerColumnCenterCenter>
        ) : (
          <S.ContentContainer maxWidth="700px">
            <S.PaddingContainer
              style={{ paddingTop: `${displayUnverifiedBanner ? '80px' : ''}` }}
            >
              <S.MediumSpacer />
              <S.H1>Create Location</S.H1>
              <S.MediumSpacer />

              <StyledForm
                onChange={() => {
                  setPreviewFacilities(getValues().facilities)
                  setPreviewState(getValues())
                }}
                onSubmit={handleSubmit(onSubmit)}
                className={displayUnverifiedBanner ? 'disabled' : ''}
              >
                <FormInput label="Titel på location*">
                  <S.InputField
                    disabled={displayUnverifiedBanner ? true : false}
                    placeholder={'TITEL'}
                    {...register('title', {
                      required: {
                        value: true,
                        message: 'Ange titel',
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max 100 tecken',
                      },
                    })}
                  />
                </FormInput>
                {errors.title?.message && (
                  <FormErrorMessage message={errors.title?.message} />
                )}

                <FormInput label="Adress*">
                  <S.InputField
                    disabled={displayUnverifiedBanner ? true : false}
                    placeholder={'ADRESS'}
                    {...register('address', {
                      required: {
                        value: true,
                        message: 'Ange adress',
                      },
                      maxLength: {
                        value: 100,
                        message: 'Max 100 tecken',
                      },
                    })}
                  />
                </FormInput>
                {errors.address?.message && (
                  <FormErrorMessage message={errors.address?.message} />
                )}

                <FormInput label="Postnummer*">
                  <S.InputField
                    disabled={displayUnverifiedBanner ? true : false}
                    placeholder={'NNNNN'}
                    type="number"
                    {...register('zipCode', {
                      required: {
                        value: true,
                        message: 'Ange postnummer',
                      },
                      pattern: {
                        value: zipCodePattern,
                        message: 'Ange giltig postnummer',
                      },
                    })}
                  />
                </FormInput>
                {errors.zipCode?.message && (
                  <FormErrorMessage message={errors.zipCode?.message} />
                )}

                <FormInput label="Län*">
                  <Select
                    isDisabled={displayUnverifiedBanner ? true : false}
                    options={counties}
                    value={{
                      value: getValues().county,
                      label: getValues().county,
                    }}
                    placeholder="LÄN"
                    {...register('county', {
                      required: {
                        value: true,
                        message: 'Välj län',
                      },
                    })}
                    getOptionValue={(o) => o.value}
                    getOptionLabel={(o) => o.label}
                    onChange={(c) => {
                      c && setValue('county', c?.label)
                      setPreviewState(getValues())
                    }}
                  />
                </FormInput>
                {errors.county?.message && (
                  <FormErrorMessage message={errors.county?.message} />
                )}

                <FormInput label="Bilder*">
                  <MediaFormInput>
                    <label
                      className={
                        !displayUnverifiedBanner
                          ? 'fileInput'
                          : 'disabled fileInput'
                      }
                      htmlFor="file"
                    >
                      <S.FlexContainerRowCenterCenter>
                        <Icon icon={IconType.PaperClip} />
                        <S.SmallSpacerRow />
                        <S.DetailsBig color={colors.white}>
                          LADDA UPP BILDER
                        </S.DetailsBig>
                      </S.FlexContainerRowCenterCenter>
                    </label>
                    <S.ExtraSmallSpacer />
                    <HiddenMediaInput
                      disabled={displayUnverifiedBanner ? true : false}
                      type="file"
                      multiple
                      id="file"
                      accept="image/*"
                      placeholder={'Bilder'.toUpperCase()}
                      {...register('images')}
                      onChange={onChangePicture}
                    />
                  </MediaFormInput>
                  <ImageContainer>
                    {imageUrls?.map((item, index) => {
                      if (index === 0) {
                        return (
                          <ImageContentFirst key={item}>
                            <Image src={item} alt="" />
                            <BtnContainer>
                              <S.InvisibleButton
                                onClick={(e) => deleteFile(index)}
                                padding={'10px'}
                              >
                                <Icon
                                  icon={IconType.Close}
                                  width={'12px'}
                                  color={colors.black}
                                />
                              </S.InvisibleButton>
                            </BtnContainer>
                            <StyledDetail className="detail">
                              UITVALD BILD
                            </StyledDetail>
                          </ImageContentFirst>
                        )
                      } else {
                        return (
                          <ImageContent key={item}>
                            <Image src={item} alt="" />
                            <BtnContainer>
                              <S.InvisibleButton
                                onClick={(e) => deleteFile(index)}
                                padding={'10px'}
                              >
                                <Icon
                                  icon={IconType.Close}
                                  width={'12px'}
                                  color={colors.black}
                                />
                              </S.InvisibleButton>
                            </BtnContainer>
                          </ImageContent>
                        )
                      }
                    })}
                  </ImageContainer>
                  <S.SmallSpacer />
                  <S.Paragraph align="center">
                    Ladda upp 3 - 8 bilder. Den utvalda bilden kan komma att
                    hamna på startsidan. Du kan också ladda upp en planritning
                    om du har det. Max filstorlek{' '}
                    {Math.round((fileSizeIntervalHigh / 1024 / 1024) * 10) / 10}
                    MB per bild och totalt{' '}
                    {Math.round((maxFileSize / 1024 / 1024) * 10) / 10}MB.
                  </S.Paragraph>
                </FormInput>

                {errors.images?.message && (
                  <FormErrorMessage message={errors.images?.message} />
                )}

                {fileError ? (
                  <FormErrorMessage message={fileError} />
                ) : imageUrls &&
                  imageUrls.length > 0 &&
                  (imageUrls.length < 3 || imageUrls.length > 8) ? (
                  <FormErrorMessage message={'Välj 3 - 8 bilder'} />
                ) : null}

                <S.MediumSpacer />
                <FormInput label="Beskrivning av din location*">
                  <TextArea
                    disabled={displayUnverifiedBanner ? true : false}
                    minHeight="280px"
                    placeholder={'Beskriv din location, do it with passion'.toUpperCase()}
                    {...register('description', {
                      minLength: {
                        value: 200,
                        message: 'Beskrivningen måste vara minst 200 tecken',
                      },
                      required: {
                        value: true,
                        message: 'Beskriv location',
                      },
                    })}
                  />
                </FormInput>
                {errors.description?.message && (
                  <FormErrorMessage message={errors.description?.message} />
                )}
                <FormInput label="Speciella önskemål till hyresgäster (om du har)">
                  <TextArea
                    disabled={displayUnverifiedBanner ? true : false}
                    value={getValues().request}
                    placeholder={'Speciella önskemål till hyresgäst'.toUpperCase()}
                    {...register('request')}
                  />
                </FormInput>
                <S.MediumSpacer />

                <S.FlexContainerRowAlignCeter>
                  <S.SmallSpacerRow />
                  <Checkbox
                    type="checkbox"
                    {...register('exclusive')}
                    disabled={displayUnverifiedBanner ? true : false}
                  />
                  <S.SmallSpacerRow />
                  <S.ParagraphSmall>
                    Det här är en exklusiv location
                  </S.ParagraphSmall>
                </S.FlexContainerRowAlignCeter>
                <S.SmallSpacer />
                <S.FlexContainerRow>
                  <S.Spacer width="45px" />
                  <S.ParagraphSmall>
                    Exklusiv location innebär att priset för uthyrning kan komma
                    att överstiga 30.000SEK / dag
                  </S.ParagraphSmall>
                </S.FlexContainerRow>
                <S.MediumSpacer />

                <FormInput label="Kvadratmeter*">
                  <S.InputField
                    disabled={displayUnverifiedBanner ? true : false}
                    placeholder={'KVM'}
                    type="number"
                    {...register('squareMeters', {
                      required: {
                        value: true,
                        message: 'Ange kvadratmeter',
                      },
                      max: {
                        value: 9999,
                        message: 'Ange giltig värde',
                      },
                      min: {
                        value: 1,
                        message: 'Ange giltig värde',
                      },
                    })}
                  />
                </FormInput>
                {errors.squareMeters?.message && (
                  <FormErrorMessage message={errors.squareMeters?.message} />
                )}
                <FormInput label="Antal rum*">
                  <S.InputField
                    disabled={displayUnverifiedBanner ? true : false}
                    placeholder={'Antal rum'.toUpperCase()}
                    type="number"
                    {...register('rooms', {
                      required: {
                        value: true,
                        message: 'Ange antal rum',
                      },
                      max: {
                        value: 99,
                        message: 'Ange giltig värde',
                      },
                      min: {
                        value: 1,
                        message: 'Ange giltig värde',
                      },
                    })}
                  />
                </FormInput>
                {errors.rooms?.message && (
                  <FormErrorMessage message={errors.rooms?.message} />
                )}

                <FormInput label="Väderstreck">
                  <Select
                    isDisabled={displayUnverifiedBanner ? true : false}
                    isMulti
                    options={directionsOptions}
                    placeholder="VÄDERSTRECK"
                    {...register('directions', { required: false, max: 4 })}
                    getOptionValue={(o) => o.value}
                    getOptionLabel={(o) => o.label}
                    defaultValue={directionsDefault}
                    onChange={(selectedDirections) => {
                      const matches = directions.filter((t) => {
                        return selectedDirections.find((s) => {
                          return s.label === t.attributes.direction
                        })
                      })
                      selectedDirections &&
                        setValue(
                          'directions',
                          matches.map((s: { id: number }) => {
                            return s.id
                          })
                        )
                      setPreviewState(getValues())
                    }}
                  />
                </FormInput>

                <S.MediumSpacer />

                <S.FlexContainerColumn>
                  <S.ParagraphSmall>
                    Markera vilka faciliteter som finns
                  </S.ParagraphSmall>
                  <S.SmallSpacer />
                  {facilities.map((f) => (
                    <div key={f.id}>
                      <S.FlexContainerRowAlignCeter>
                        <S.SmallSpacerRow />
                        <Checkbox
                          disabled={displayUnverifiedBanner ? true : false}
                          value={f.id}
                          type="checkbox"
                          {...register('facilities')}
                        />
                        <S.SmallSpacerRow />
                        <S.DetailsSmall>{f.attributes.facility}</S.DetailsSmall>
                      </S.FlexContainerRowAlignCeter>
                      <S.ExtraSmallSpacer />
                    </div>
                  ))}
                </S.FlexContainerColumn>
                <S.MediumSpacer />

                <FormInput label="Sökord*">
                  <Select
                    isDisabled={displayUnverifiedBanner ? true : false}
                    isMulti
                    isOptionDisabled={() => {
                      return (
                        getValues().locationTags &&
                        getValues().locationTags.length >= 15
                      )
                    }}
                    options={tagOptions}
                    placeholder="SÖKORD"
                    {...register('locationTags', {
                      required: {
                        value: true,
                        message: 'Välj 1 - 15 sökord',
                      },
                      minLength: 1,
                      maxLength: 15,
                    })}
                    getOptionValue={(o) => o.value}
                    getOptionLabel={(o) => o.label}
                    defaultValue={tagsDefault}
                    onChange={(selectedTags) => {
                      if (selectedTags.length <= 15) {
                        const matches = tags.filter((t) => {
                          return selectedTags.find((s) => {
                            return s.label === t.attributes.tag
                          })
                        })
                        selectedTags &&
                          setValue(
                            'locationTags',
                            matches.map((s: { id: number }) => s.id)
                          )
                        setPreviewState(getValues())
                      }
                    }}
                  />
                </FormInput>
                {errors.locationTags?.message && (
                  <FormErrorMessage message={errors.locationTags?.message} />
                )}

                <S.LargeSpacer />
                <S.FlexContainerRowJustifyEnd>
                  <S.ExtraLargeSpacerRow />
                  <S.TextButton
                    onClick={() => {
                      window.scroll(0, 0)
                      history.push(AuthRoutes.customerAccount)
                    }}
                    color={colors.black}
                  >
                    Avbryt
                  </S.TextButton>
                  <S.ExtraLargeSpacerRow />
                  <S.ButtonPrimary
                    width={'338px'}
                    type="submit"
                    className={!displayUnverifiedBanner ? '' : 'disabled'}
                  >
                    Skicka till granskning
                  </S.ButtonPrimary>
                </S.FlexContainerRowJustifyEnd>
                <S.VerticalMargin />
              </StyledForm>

              <S.MediumSpacer />
            </S.PaddingContainer>
          </S.ContentContainer>
        )}
      </S.Wrapper>

      {previewModal && previewState && (
        <PreviewLocationModal
          location={previewState}
          previewFacilities={previewFacilities}
          imageUrls={imageUrls}
          closeModal={() => {
            setPreviewModal(false)
          }}
        />
      )}

      {showContactModal && (
        <ContactModal closeModal={() => setShowContactModal(false)} />
      )}
    </>
  )
}
