import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as S from '../ui'
import styled from 'styled-components'
import { StyledMarkdownParser } from './StyledParsedMarkdown'
import { ContactModal } from '../components/modals/ContactModal'
import { BASE_URL } from '../utils/config'

const StyledParagraph = styled(S.Paragraph)`
  cursor: pointer;

  span {
    font-weight: bold;
    text-decoration: underline;
  }
`

interface IProps {
  title: string
  url: string
}

export const TermsPolicyFaq: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [content, setContent] = useState<string>('')
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api${props.url}`)
      .then((res) => setContent(res.data.data.attributes.text))
  }, [props.url])

  return (
    <S.Wrapper>
      <S.ContentContainer maxWidth="700px">
        <S.PaddingContainer>
          <S.MediumSpacer />
          <S.H1>{props.title}</S.H1>
          <S.LargeSpacer />
          <StyledMarkdownParser text={content} />
          {props.url === '/faq' && (
            <>
              <S.LargeSpacer />
              <StyledParagraph
                onClick={() => {
                  setShowModal(true)
                }}
              >
                <span>Kontakta oss gärna</span> om du har fler frågor!
              </StyledParagraph>
            </>
          )}
          <S.VerticalMargin />
        </S.PaddingContainer>
      </S.ContentContainer>
      {showModal && <ContactModal closeModal={() => setShowModal(false)} />}
    </S.Wrapper>
  )
}
