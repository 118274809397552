import React, { useEffect, useState } from 'react'
import * as S from '../../../ui'
import { Icon, IconType } from '../../../ui/assets/icons'
import { colors } from '../../../ui'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, FieldValues } from 'react-hook-form'
import { FormInput } from '../../../components/FormInput'
import axios from 'axios'
import { Modal } from '../../../components/modals/Modal'
import { ResetPassword } from '../../../components/modals/ResetPassword'
import { signOut } from '../../../utils/signOut'
import { ChangePassword } from '../../../components/modals/ChangePassword'
import { ILocation, NonAuthRoutes } from '../../../types'
import { CompanyAccountNavBar } from '../Account/MyFavorites/components/CompanyAccountNavBar'
import {
  emailPattern,
  swedishPhoneNumberPattern,
  swedishOrgNumberPattern,
  zipCodePattern,
} from '../../../utils/regex'
import { FormErrorMessage } from '../../../components/FormErrorMessage'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'
import { ScrollToTopOnMount } from '../../../utils/scrollToTop'

const PasswordDiv = styled(S.FlexContainerColumnCenterCenter)`
  border: 1px solid ${colors.black};
  padding: 24px 40px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

interface IProfileInfo {
  name: string
  company?: string
  orgNumber?: string
  email: string
  phoneNumber: string
  invoiceAddress: string
  type: string
  zipCode: string
  country: string
  postalArea: string
  id: string
}

export const AccountInfo: React.FC = () => {
  const history = useHistory()
  const [user, setUser] = useState<IProfileInfo>()
  const [deleteAccount, setDeleteAccount] = useState(false)
  const [deleteComplete, setDeleteComplete] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [confirmation, setConfirmation] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<IProfileInfo>()

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios
      .get(`${BASE_URL}/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data)

        setValue('name', res.data.name)
        setValue('company', res.data.company)
        setValue('orgNumber', res.data.orgNumber)
        setValue('email', res.data.email)
        setValue('phoneNumber', res.data.phoneNumber)
        setValue('invoiceAddress', res.data.invoiceAddress)
        setValue('type', res.data.type)
        setValue('zipCode', res.data.zipCode)
        setValue('country', res.data.country)
        setValue('postalArea', res.data.postalArea)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [setValue])

  useEffect(() => {
    if (user) {
      setValue('email', user.email)
    }
  }, [user, setValue])

  if (!user) {
    return <></>
  }

  const updateUser = (data: FieldValues) => {
    const token = localStorage.getItem('token')
    axios
      .put(`${BASE_URL}/api/users/${user.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setConfirmation(true)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  const deleteUser = () => {
    const token = localStorage.getItem('token')
    axios
      .get(
        `${BASE_URL}/api/users/me?populate=locations`, // get user locations
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const userLocations: ILocation[] = res.data.locations
        userLocations.map((location) => {
          axios.delete(
            `${BASE_URL}/api/locations/${location.id}`, //delete user locations
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        })
        axios.delete(
          `${BASE_URL}/api/users/${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          } //delete user
        )
      })
      .then(() => {
        setDeleteComplete(true)
        signOut()
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }

  return (
    <>
      <ScrollToTopOnMount />
      <CompanyAccountNavBar />
      <S.Wrapper>
        <S.ContentContainer maxWidth="700px">
          <S.PaddingContainer>
            <S.MediumSpacer />
            <S.H1>My account / Profile information</S.H1>
            <S.MediumSpacer />

            <form
              onSubmit={handleSubmit(updateUser)}
              onChange={() => {
                if (confirmation) return setConfirmation(false)
              }}
            >
              <FormInput label="Namn*">
                <S.InputField
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Ange för- och efternamn',
                    },
                  })}
                  placeholder="För- och efternamn"
                />
              </FormInput>
              {errors.name?.message && (
                <FormErrorMessage message={errors.name?.message} />
              )}
              {user.type === 'company' && (
                <>
                  <FormInput label="Företagsnamn*">
                    <S.InputField
                      {...register('company', {
                        required: {
                          value: true,
                          message: 'Ange företagsnamn',
                        },
                      })}
                      placeholder="Företagsnamn"
                    />
                  </FormInput>
                  {errors.company?.message && (
                    <FormErrorMessage message={errors.company?.message} />
                  )}
                  <FormInput label="Organisationsnummer*">
                    <S.InputField
                      {...register('orgNumber', {
                        required: {
                          value: true,
                          message: 'Ange organisationsnummer',
                        },
                        pattern: {
                          value: swedishOrgNumberPattern,
                          message: 'Ange giltig organisationsnummer',
                        },
                      })}
                      placeholder="XXXXXX-XXXX"
                    />
                  </FormInput>
                  {errors.orgNumber?.message && (
                    <FormErrorMessage message={errors.orgNumber?.message} />
                  )}
                  <S.MediumSpacer />
                </>
              )}

              <FormInput label="Mailadress*">
                <S.InputField
                  {...register('email', {
                    required: { value: true, message: 'Ange mailadress' },
                    pattern: {
                      value: emailPattern,
                      message: 'Ange giltig mailadress',
                    },
                  })}
                  type="email"
                  placeholder="Mailadress"
                />
              </FormInput>
              {errors.email?.message && (
                <FormErrorMessage message={errors.email?.message} />
              )}

              <FormInput label="Telefonnummer*">
                <S.InputField
                  {...register('phoneNumber', {
                    required: {
                      value: true,
                      message: 'Ange telefonnummer',
                    },
                    pattern: {
                      value: swedishPhoneNumberPattern,
                      message: 'Ange giltig telefonnummer',
                    },
                  })}
                  placeholder="Telefonnummer"
                />
              </FormInput>
              {errors.phoneNumber?.message && (
                <FormErrorMessage message={errors.phoneNumber?.message} />
              )}
              <S.LargeSpacer />

              <S.LargeSpacer />
              <FormInput label="Faktureringsadress*">
                <S.InputField
                  {...register('invoiceAddress', {
                    required: {
                      value: true,
                      message: 'Ange adress',
                    },
                  })}
                  placeholder="Adress"
                />
              </FormInput>
              {errors.invoiceAddress?.message && (
                <FormErrorMessage message={errors.invoiceAddress?.message} />
              )}
              <FormInput label="Postnummer*">
                <S.InputField
                  {...register('zipCode', {
                    required: {
                      value: true,
                      message: 'Ange postnummer',
                    },
                    pattern: {
                      value: zipCodePattern,
                      message: 'Ange giltig postnummer',
                    },
                  })}
                  placeholder="NNNNN"
                  type="number"
                />
              </FormInput>
              {errors.zipCode?.message && (
                <FormErrorMessage message={errors.zipCode?.message} />
              )}
              <FormInput label="Postort*">
                <S.InputField
                  {...register('postalArea', {
                    required: {
                      value: true,
                      message: 'Ange ort',
                    },
                  })}
                  placeholder="Ort"
                />
              </FormInput>
              {errors.postalArea?.message && (
                <FormErrorMessage message={errors.postalArea?.message} />
              )}
              <S.FlexContainerRowJustifyEnd>
                <S.TextButton
                  onClick={() => setDeleteAccount(true)}
                  color={colors.gray}
                >
                  Ta bort konto
                </S.TextButton>
                <S.ExtraLargeSpacerRow />
                <S.WhiteButton width={'200px'} type="submit">
                  Spara ändringar
                </S.WhiteButton>
              </S.FlexContainerRowJustifyEnd>
              <S.FlexContainerRowJustifyEnd>
                {confirmation && (
                  <>
                    <S.Paragraph color={colors.gray}>
                      Dina ändringar har sparats
                    </S.Paragraph>
                    <S.ExtraSmallSpacerRow />
                    <Icon icon={IconType.Checkmark} />
                  </>
                )}
              </S.FlexContainerRowJustifyEnd>
            </form>

            <S.MediumSpacer />

            <PasswordDiv>
              <S.FlexContainerColumn>
                <S.H5 color={colors.yellow}>Lösenord</S.H5>
                <S.ExtraSmallSpacer />
                <S.ParagraphSmall>
                  Det bästa är att använda ett lösenord som du inte använder
                  någon annanstans
                </S.ParagraphSmall>
              </S.FlexContainerColumn>
              <S.SmallSpacer />
              <S.MediumSpacerRow />
              <S.ButtonPrimary onClick={() => setResetPassword(true)}>
                Byt lösenord
              </S.ButtonPrimary>
            </PasswordDiv>

            <S.VerticalMargin />

            {resetPassword && (
              <Modal closeModal={() => setResetPassword(false)}>
                <ChangePassword sent={() => setResetPassword(false)} />
              </Modal>
            )}

            {deleteAccount && (
              <Modal
                closeModal={() => {
                  deleteComplete
                    ? history.push(NonAuthRoutes.home)
                    : setDeleteAccount(false)
                }}
              >
                {deleteComplete ? (
                  <>
                    <S.H3>Hej då</S.H3>
                    <S.ExtraSmallSpacer />
                    <S.Paragraph>
                      Vi är ledsna att se dig lämna, men hoppas att våra vägar
                      korsas igen i framtiden.
                    </S.Paragraph>
                    <S.ExtraLargeSpacer />

                    <S.ModalButtonPrimary
                      onClick={() => {
                        history.push(NonAuthRoutes.home)
                      }}
                    >
                      Okej
                    </S.ModalButtonPrimary>
                  </>
                ) : (
                  <>
                    <S.H3>Are you sure?</S.H3>
                    <S.ExtraSmallSpacer />
                    <S.Paragraph>
                      Den här åtgärden går inte att ångra.
                    </S.Paragraph>
                    <S.ExtraLargeSpacer />
                    <S.FlexContainerRowJustifyBetween>
                      <S.StyledLinkButton
                        onClick={() => setDeleteAccount(false)}
                      >
                        Tillbaka
                      </S.StyledLinkButton>
                      <S.ModalButtonPrimary
                        onClick={() => deleteUser()}
                        width="max-content"
                      >
                        Ta bort konto
                      </S.ModalButtonPrimary>
                    </S.FlexContainerRowJustifyBetween>
                  </>
                )}
              </Modal>
            )}
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}
