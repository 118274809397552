import React, { useState } from 'react'
import * as S from '../../ui'
import { colors } from '../../ui'
import styled from 'styled-components'
import { Modal } from './Modal'
import { Input } from '../Input'
import axios from 'axios'
import { FormInput } from '../FormInput'
import { Icon, IconType } from '../../ui/assets/icons'
import { FormErrorMessage } from '../FormErrorMessage'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { emailPattern } from '../../utils/regex'
import { BASE_URL } from '../../utils/config'
import { validateEmail } from '../../utils/validateEmail'
import { TextArea } from '../../pages/Client/CreateLocation'

interface IProps {
  subject: string
  closeModal: () => void
}

export const MediaFormInput = styled(S.FlexContainerColumnCenterCenter)`
  width: 100%;
  @media screen and (min-width: 1200px) {
    margin: auto;
  }
`
export const MediaInput = styled(S.InputField)`
  border: none;
  padding: 0;
  height: min-content;
  ::-webkit-file-upload-button {
    display: none;
  }
`

const HiddenMediaInput = styled(MediaInput)`
  display: none;
`

const StyledDetails = styled(S.DetailsSmall)`
  align-self: flex-start;
`

const StyledFormErrorMessage = styled(FormErrorMessage)`
  align-self: flex-start;
`

interface IStyleProps {
  minHeight?: string
}

export interface IRequest {
  userEmail: string
  message: string
  file: File
}

export const RequestModal: React.FC<IProps> = (props) => {
  const [userEmail, setUserEmail] = useState('')
  const [message, setMessage] = useState('')
  const [file, setFile] = useState<File | undefined>()
  const [fileError, setFileError] = useState<string>()
  const maxFileSize = 1048576 //1mb in Bytes in binary
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string>()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IRequest>()

  const onSubmit: SubmitHandler<IRequest> = async (data: FieldValues) => {
    setError(undefined)
    setSuccess(false)

    const formData = new FormData()
    formData.append('userEmail', data.userEmail)
    formData.append('message', data.message)
    if (file) {
      formData.append('file', file)
    }

    const requestUrl =
      props.subject === 'location'
        ? 'location/requestEmail'
        : 'creative-partner/request'

    axios
      .post(`${BASE_URL}/api/${requestUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setSuccess(true)
        }
      })
      .catch((err) => {
        setError('Ditt email kunde inte skickas. Testa igen senare.')
      })
  }

  const onChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    if (e.target.files[0].size > maxFileSize) {
      setFile(undefined)
      setFileError('Max filstorlek 1 MB')
    } else {
      setFile(e.target.files[0])
    }
  }

  return (
    <Modal closeModal={() => props.closeModal()} padding={'30px'}>
      <S.FlexContainerColumnJustifyCenter>
        {success ? (
          <>
            <S.FlexContainerRowAlignCeter>
              <S.H3>Thank you</S.H3>
              <S.SmallSpacerRow />
              <Icon icon={IconType.Heart} />
            </S.FlexContainerRowAlignCeter>
            <S.SmallSpacer />
            <S.Paragraph>
              Vi svarar så snart vi kan, håll ett öga på din inkorg!
            </S.Paragraph>
            <S.ExtraLargeSpacer />
            <S.ModalButtonPrimary
              onClick={() => {
                setSuccess(false)
                props.closeModal()
              }}
            >
              OKEJ
            </S.ModalButtonPrimary>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.H3>Find {props.subject}</S.H3>
            <S.SmallSpacer />

            <FormInput label="Email*">
              <S.InputField
                {...register('userEmail', {
                  required: { value: true, message: 'Enter email' },
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email',
                  },
                })}
                type="email"
                placeholder="ENTER YOUR EMAIL"
              />
            </FormInput>
            {errors.userEmail?.message && (
              <FormErrorMessage message={errors.userEmail?.message} />
            )}
            <FormInput label="Meddelande*">
              <TextArea
                minHeight="180px"
                placeholder={'WRITE YOUR MESSAGE HERE'}
                {...register('message', {
                  required: {
                    value: true,
                    message: 'Enter message',
                  },
                })}
              />
            </FormInput>
            {errors.message?.message && (
              <FormErrorMessage message={errors.message?.message} />
            )}
            <S.SmallSpacer />
            <S.Paragraph>Bild (valfritt)</S.Paragraph>
            <S.ParagraphSmall>
              {props.subject === 'location'
                ? 'Har du en tydlig bild av hur önskad location ser ut? Skicka med en bild eller moodboard.'
                : 'Har du en tydlig bild av vad du önskar av en creative partner? Skicka med bild eller moodboard.'}
            </S.ParagraphSmall>
            <S.SmallSpacer />
            <FormInput>
              <MediaFormInput>
                <label
                  className="fileInput"
                  htmlFor="file"
                  style={{
                    borderRadius: '1px',
                    border: '1px solid black',
                    width: '100%',
                    background: colors.white,
                    padding: '15px',
                    margin: '0',
                    cursor: 'pointer',
                  }}
                >
                  <S.FlexContainerRowCenterCenter>
                    <Icon icon={IconType.PaperClip} fill={colors.black} />
                    <S.SmallSpacerRow />
                    <S.DetailsBig color={colors.black}>
                      LADDA UPP BILD
                    </S.DetailsBig>
                  </S.FlexContainerRowCenterCenter>
                </label>
                <S.ExtraSmallSpacer />
                <HiddenMediaInput
                  type="file"
                  id="file"
                  accept="image/*"
                  placeholder={'Bilder och video'.toUpperCase()}
                  onChange={onChangePicture}
                />
                <StyledDetails>
                  {file ? (
                    file?.name
                  ) : fileError ? (
                    <FormErrorMessage message={fileError} />
                  ) : (
                    'Inga filer'
                  )}
                </StyledDetails>
              </MediaFormInput>
              <S.SmallSpacer />
            </FormInput>
            <S.ModalButtonSecondary type="submit">
              Skicka förfrågan
            </S.ModalButtonSecondary>
            <S.SmallSpacer />
            {error && <FormErrorMessage message={error} />}
          </form>
        )}
      </S.FlexContainerColumnJustifyCenter>
    </Modal>
  )
}
