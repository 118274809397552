import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as S from '../ui'
import { colors } from '../ui'
import { Icon, IconType } from '../ui/assets/icons'

const Container = styled(S.FlexContainerColumn)`
  position: relative;
  overflow-y: scroll;
  @media screen and (min-width: 1200px) {
    width: unset;
    overflow: visible;
  }
`

const SortButton = styled(S.Button)`
  border: 1px solid ${colors.gray};
  color: ${colors.gray};
  position: relative;
  z-index: 9;
  margin-bottom: 1px;
  svg {
    transition: all 0.2s linear;
  }

  &.selected {
    /* color: ${colors.yellow}; */
  }

  &.open {
    svg  {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }
  @media screen and (min-width: 1200px) {
    width: 190px;
    height: 54px;
  }
`

export const SortOption = styled(S.TextButton)`
  color: ${colors.gray};
  visibility: hidden;
  &.open {
    visibility: visible;
    margin: 8px 0;
    text-align: left;
    &.active {
      color: ${colors.yellow};
    }
  }
`
export const SortDropDown = styled(S.FlexContainerColumnCenterCenter)`
  color: ${colors.gray};
  z-index: 9;
  cursor: default;
  width: 100%;
  height: 0;
  transition: height 0.2s linear;
  &.open {
    padding: 23px;
    transition: height 0.2s linear;
    height: max-content;
    background-color: ${colors.white};
  }
  button {
    text-align: center;
  }
  @media screen and (min-width: 1200px) {
    position: absolute;
    top: 62px;
    left: 0;
    width: max-content;
    min-width: 100%;
    align-items: flex-start;
    &.open {
      border: 1px solid ${colors.gray};
      background-color: ${colors.white};
    }
  }
`

export interface IOption {
  label: string
  value: string
  type?: string
}

interface IProps {
  label: string
  sortOptions: IOption[]
  activeOption?: IOption
  handleClick: (value: IOption | undefined) => void
  width?: string
  noBorder?: boolean
  selected?: boolean
}

export const RelativeDropdown: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState<string | undefined>()

  const renderSortOption = (option: IOption) => {
    let isActive = option.label === props.activeOption?.label

    return (
      <SortOption
        key={option.value}
        className={`${isActive ? 'active' : ''}
        ${open === 'sort' ? 'open' : ''}`}
        onClick={() => {
          props.handleClick(option)
          setOpen(undefined)
        }}
      >
        {option.label}
      </SortOption>
    )
  }

  return (
    <Container>
      <SortButton
        width={props.width}
        onClick={() => setOpen(!open ? 'sort' : undefined)}
        className={`${open === 'sort' ? 'open' : ''}
          ${props.selected ? 'selected' : ''}
        `}
      >
        {props.label}
        <S.SmallSpacerRow />
        <Icon icon={IconType.ChevronDown} />
      </SortButton>
      <SortDropDown
        width={props.width}
        onClick={(e) => e.stopPropagation()}
        className={`dropDown ${props.noBorder ? 'noBorder' : ''} ${
          open === 'sort' ? 'open' : ''
        }`}
      >
        <SortOption
          key={'allValues'}
          className={`${!props.activeOption ? 'active' : ''}
        ${open === 'sort' ? 'open' : ''}`}
          onClick={() => {
            props.handleClick(undefined)
            setOpen(undefined)
          }}
        >
          ALLA
        </SortOption>
        {props.sortOptions.map((o) => {
          return renderSortOption(o)
        })}
      </SortDropDown>
    </Container>
  )
}
