import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { IContent, ImageSection } from '../../../components/ImageSection'
import * as S from '../../../ui'
import { useHistory } from 'react-router-dom'
import { NonAuthRoutes, IUpdate } from '../../../types'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

export const Updates: React.FC = () => {
  const history = useHistory()
  const [updates, setUpdates] = useState<IContent[]>([])

  useEffect(() => {
    const qs = require('qs')
    const query = qs.stringify({
      populate: '*',
      pagination: {
        pageSize: 4,
      },
      sort: 'publishedAt:desc',
      encodeValuesOnly: true, // prettify URL
    })
    axios
      .get(`${BASE_URL}/api/updates?${query}`)
      .then((res) => {
        let updatesList: IContent[] = []
        res.data.data.map((d: IUpdate) =>
          updatesList.push({
            image: d.attributes?.thumbnail?.data?.attributes?.url,
            header: d.attributes.title,
            category: d.attributes.updateCategory.data?.attributes.category,
            date: d.attributes.publishedAt,
            body: d.attributes.text,
            id: d.id.toString(),
          })
        )
        setUpdates(updatesList)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  const redirect = (id: string) => {
    history.push(`${NonAuthRoutes.updates}/${id}`)
  }

  return (
    <>
      <ImageSection
        updates
        title={'UPDATES'}
        link={NonAuthRoutes.updates}
        content={updates}
        handleClick={redirect}
      />
      <S.VerticalMargin />
    </>
  )
}
