export const Images = {
  Hero: require('../images/landing.jpg'),
  Location1: require('../images/location1.jpg'),
  Location2: require('../images/location2.jpg'),
  Location3: require('../images/location3.jpg'),
  Location4: require('../images/location4.jpg'),
  Location5: require('../images/location5.jpg'),
  Location6: require('../images/location6.jpg'),
  Location7: require('../images/location7.jpg'),
  About1: require('../images/about1.jpg'),
  About2: require('../images/about2.jpg'),
  LogoWhite: require('../images/logoWhite.png'),
}
