export function removeFavorite(itemId: number, data: any) {
  const filteredData = data?.filter((d: any) => d.id !== itemId)
  const favorites = filteredData?.map(({ id }: { id: number }) => id)
  return favorites
}

export function addFavorite(itemId: number, data: any) {
  const filteredData = data.map(({ id }: { id: number }) => id)
  const favorites = filteredData ? [...filteredData, itemId] : [itemId]
  return favorites
}
