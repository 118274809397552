import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { State } from '../store/userReducer'
import { userState } from '../store/userReducer/selectors'
import { NonAuthRoutes } from '../types'
import { Main } from '../ui'
import { useGetUserType } from '../utils/useGetUserType'
import { SmallHeader } from './SmallHeader'
import { Spinner } from './Spinner'

interface IProps {
  Component: any
  path: string
  exact?: boolean
  requiredRole: string
}

export const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRole,
}: IProps): JSX.Element => {
  const token = localStorage.getItem('token')
  const userType = useGetUserType()
  const hasRequiredRole = requiredRole === userType

  return (
    <>
      <SmallHeader />
      {userType ? (
        <Route
          exact={exact}
          path={path}
          render={(props: any) =>
            token && hasRequiredRole ? (
              <Main>
                <Component {...props} />
              </Main>
            ) : (
              <Redirect
                to={{
                  pathname: NonAuthRoutes.home,
                }}
              />
            )
          }
        />
      ) : (
        <Redirect
          to={{
            pathname: NonAuthRoutes.home,
          }}
        />
      )}
    </>
  )
}
