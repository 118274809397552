import React, { useEffect, useState } from 'react'
import * as S from '../../../ui'
import styled from 'styled-components'
import { Images } from '../../../ui/assets/images'
import { TextButtonCTA } from '../../../components/TextButtonCTA'
import axios from 'axios'
import { NonAuthRoutes } from '../../../types'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../utils/config'

const ImagesWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  height: min-content;
  gap: 16px;

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    overflow-x: unset;
    white-space: unset;
  }
`

const ImageContainer = styled(S.FlexContainerColumn)`
  width: 315px;
  white-space: normal;
  word-break: keep-all;
  padding-bottom: 12px;
  position: relative;

  // mobile only
  @media screen and (max-width: 1199px) {
    :first-of-type {
      margin-left: 16px;
    }

    :last-of-type {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: row;
  }
`

const Image = styled.div`
  height: 315px;
  width: 315px;
  object-fit: contain;
  object-position: center;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: 1200px) {
    height: 100%;
    width: calc(100% - 30px);
  }
`

const ImageText = styled(S.DetailsSmall)`
  @media screen and (min-width: 1200px) {
    position: absolute;
    right: 0;
    writing-mode: vertical-rl;
    text-orientation: sideways-left;
    width: 30px;

    /* position: absolute;
    right: 0;
    top: -20px;
    transform: rotate(270deg);
    transform-origin: 100% 100%; */
  }
`

const BodyContainer = styled(S.FlexContainerColumn)`
  padding: 0 16px;

  @media screen and (min-width: 1200px) {
    p {
      column-count: 2;
    }
    flex-direction: row;
    justify-content: space-between;

    div {
      width: calc(50% - 16px);
    }
  }
`

export interface IAbout {
  quote: string
  text: string
  images: {
    data: {
      id: number
      attributes: {
        url: string
        caption: string
        alternativeText: string
      }
    }[]
  }
}

export const About: React.FC = () => {
  const [about, setAbout] = useState<IAbout | undefined>()
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/about-home?populate=%2a`)
      .then((res) => {
        setAbout(res.data.data.attributes)
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
      })
  }, [])

  return (
    <S.Wrapper>
      <S.TitleTextContainer>
        <S.H4>ABOUT US</S.H4>
        <S.StyledLink to={NonAuthRoutes.about}>VIEW MORE</S.StyledLink>
      </S.TitleTextContainer>
      <S.ContentContainer>
        <ImagesWrapper>
          <ImageContainer>
            <Image>
              <img
                src={about?.images.data[0].attributes.url}
                alt={about?.images.data[0].attributes.alternativeText}
              />
            </Image>
            <S.ExtraSmallSpacer />
            <ImageText>{about?.images.data[0].attributes.caption}</ImageText>
          </ImageContainer>
          <ImageContainer>
            <Image>
              <img
                src={about?.images.data[1].attributes.url}
                alt={about?.images.data[1].attributes.alternativeText}
              />
            </Image>
            <S.ExtraSmallSpacer />
            <ImageText>{about?.images.data[1].attributes.caption}</ImageText>
          </ImageContainer>
        </ImagesWrapper>
        <S.MediumSpacer />

        <S.H3 align="center">{about?.quote}</S.H3>
        <S.SmallSpacer />
        <BodyContainer>
          <S.Paragraph>{about?.text}</S.Paragraph>
        </BodyContainer>
        <S.NavLink to={'/#footer'} className="aboutButton" smooth>
          Subscribe to our newsletter
        </S.NavLink>

        <S.VerticalMargin />
        <TextButtonCTA
          text="Tell your friends about Bonanza"
          buttonText="SHARE THIS PAGE"
        />
        <S.VerticalMargin />
      </S.ContentContainer>
    </S.Wrapper>
  )
}
