import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SubscribeCTA } from '../../components/SubscribeCTA'
import { SubscribeWithBackground } from '../../components/SubscribeWithBackground'
import * as S from '../../ui'
import { Icon, IconType } from '../../ui/assets/icons'
import { Instagram } from '../../components/Instagram'
import { AllUpdates } from './components/AllUpdates'
import { HighlightedUpdate } from './components/HighlightedUpdate'
import { ScrollToTopOnMount } from '../../utils/scrollToTop'
import { ISeo } from '../../types'

export const Updates: React.FC = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <S.Wrapper>
        <S.ContentContainer>
          <S.PaddingContainer>
            <S.VerticalMargin />
            <S.H1>UPDATES</S.H1>
            <S.MediumSpacer />
            <HighlightedUpdate />
            <S.VerticalMargin />
            <AllUpdates />
            <S.VerticalMargin />
          </S.PaddingContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </>
  )
}
