import styled from 'styled-components'

interface IFlexProps {
  width?: string
  height?: string
  align?: string
  position?: string
}

export const FlexContainerRow = styled.div<IFlexProps>`
  display: flex;
  width: ${(props) => props.width ?? 'auto'};
  align-items: ${(props) => props.align ?? 'auto'};
`

export const FlexContainerRowAlignCeter = styled(FlexContainerRow)<IFlexProps>`
  align-items: center;
  width: ${(props) => props.width ?? 'auto'};
`

export const FlexContainerRowJustifyEnd = styled(FlexContainerRow)<IFlexProps>`
  justify-content: end;
  align-items: center;
  width: 100%;
`
export const FlexContainerRowJustifyBetween = styled(
  FlexContainerRow
)<IFlexProps>`
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.width ?? 'auto'};
`

export const FlexContainerRowCenterCenter = styled(
  FlexContainerRowJustifyBetween
)<IFlexProps>`
  justify-content: center;
`

export const FlexContainerRowJustifyCenter = styled(
  FlexContainerRow
)<IFlexProps>`
  justify-content: center;
  width: ${(props) => props.width ?? '100%'};
`
export const FlexContainerColumn = styled.div<IFlexProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width ?? 'auto'};
  position: ${(props) => props.position ?? 'static'};
`
export const FlexContainerColumnJustifyCenter = styled(
  FlexContainerColumn
)<IFlexProps>`
  justify-content: center;
`
export const FlexContainerColumnJustifyBetween = styled(
  FlexContainerColumn
)<IFlexProps>`
  justify-content: space-between;
  height: 100%;
`

export const FlexContainerColumnCenterCenter = styled(
  FlexContainerColumn
)<IFlexProps>`
  justify-content: center;
  align-items: center;
`
