import React, { FunctionComponent, SVGProps } from 'react'

import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Instagram } from './instagram.svg'
import { ReactComponent as Linkedin } from './linkedin.svg'
import { ReactComponent as Youtube } from './youtube.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as Message } from './message.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as Email } from './email.svg'
import { ReactComponent as ChevronLeft } from './chevron-left.svg'
import { ReactComponent as ChevronDown } from './chevron-down.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Bookmark } from './bookmark.svg'
import { ReactComponent as Tag } from './tag.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Toilet } from './toilet.svg'
import { ReactComponent as Kitchen } from './kitchen.svg'
import { ReactComponent as Fridge } from './fridge.svg'
import { ReactComponent as Water } from './water.svg'
import { ReactComponent as Electricity } from './electricity.svg'
import { ReactComponent as Alarm } from './alarm.svg'
import { ReactComponent as CleaningSupplies } from './cleaningSupplies.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as PageNotFound } from './pagenotfound.svg'
import { ReactComponent as Checkmark } from './checkmark.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as PaperClip } from './paperClip.svg'
import { ReactComponent as Heart } from './heart.svg'

export enum IconType {
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Twitter,
  Message,
  Close,
  Copy,
  Email,
  ChevronLeft,
  ChevronDown,
  Filter,
  Bookmark,
  Tag,
  Search,
  Toilet,
  Kitchen,
  Fridge,
  Water,
  Electricity,
  Alarm,
  CleaningSupplies,
  Play,
  PageNotFound,
  Checkmark,
  Home,
  Error,
  PaperClip,
  Heart,
}

const iconMap: { [I in IconType]: FunctionComponent<SVGProps<SVGSVGElement>> } =
  {
    [IconType.Facebook]: Facebook,
    [IconType.Instagram]: Instagram,
    [IconType.Linkedin]: Linkedin,
    [IconType.Youtube]: Youtube,
    [IconType.Twitter]: Twitter,
    [IconType.Message]: Message,
    [IconType.Close]: Close,
    [IconType.Copy]: Copy,
    [IconType.Email]: Email,
    [IconType.ChevronLeft]: ChevronLeft,
    [IconType.ChevronDown]: ChevronDown,
    [IconType.Filter]: Filter,
    [IconType.Bookmark]: Bookmark,
    [IconType.Tag]: Tag,
    [IconType.Search]: Search,
    [IconType.Toilet]: Toilet,
    [IconType.Kitchen]: Kitchen,
    [IconType.Fridge]: Fridge,
    [IconType.Water]: Water,
    [IconType.Electricity]: Electricity,
    [IconType.Alarm]: Alarm,
    [IconType.CleaningSupplies]: CleaningSupplies,
    [IconType.Play]: Play,
    [IconType.PageNotFound]: PageNotFound,
    [IconType.Checkmark]: Checkmark,
    [IconType.Home]: Home,
    [IconType.Error]: Error,
    [IconType.PaperClip]: PaperClip,
    [IconType.Heart]: Heart,
  }

interface Props extends SVGProps<SVGSVGElement> {
  icon: IconType
}

export const Icon: React.FC<Props> = (props) => {
  const icon = iconMap[props.icon]

  return React.createElement(icon, props)
}
